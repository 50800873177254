import 'typeface-inter';

import { DefaultTheme as StyledDefaultTheme } from 'styled-components';

import { DefaultTheme, theme } from '@chakra-ui/core';

import customIcons from './customIcons';

const appTheme: DefaultTheme = {
  ...theme,
  fonts: {
    ...theme.fonts,
    body: '"Inter", sans-serif',
    heading: '"Inter", sans-serif',
  },
  icons: {
    ...theme.icons,
    ...customIcons,
  },
};

export const styledAppTheme: StyledDefaultTheme = {
  ...appTheme,
};

export default appTheme;
