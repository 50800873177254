import React from 'react';

import AppBarButton from '../AppBarButton';

interface IProps {
  onClick: () => void;
  disabled?: boolean;
}

const AppBarUser: React.FC<IProps> = React.memo((props) => {
  const { children, disabled, onClick } = props;

  return (
    <AppBarButton
      onClick={!disabled ? onClick : undefined}
      disabled={disabled}
      data-testid="button"
    >
      {children}
    </AppBarButton>
  );
});

export default AppBarUser;
