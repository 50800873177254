import { gql } from 'apollo-boost';

import client from '../graphql';

const query = gql`
  query SEARCH_LOCATIONS($q: String!) {
    locationSearch(search: $q) {
      placeId
      locationString
    }
  }
`;

export interface ILocationResult {
  placeId: string;
  locationString: string;
}

export default async (q: string) => {
  const res = await client.query<{ locationSearch: ILocationResult[] }>({
    query,
    variables: { q },
  });

  return res.data.locationSearch;
};
