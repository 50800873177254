import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  ModalBody,
  ModalFooter,
  useToast,
} from '@chakra-ui/core';

import { IUser } from '../../../../../global';
import UserListItem from '../../../../../shared/components/UserListItem';
import client from '../../../../../shared/graphql';
import segment from '../../../../../shared/utils/segment';
import ContactPickerModal from '../../../../Modals/ContactPickerModal';
import createChatMutation from './graphql/createChat.mutation';
import { CreateChatModalContext } from '.';

interface IFormValue {
  title: string;
  participants: IUser[];
}

const validationSchema = object().shape({
  title: string().required(),
});

const UserListPreviewItem: React.FC<{
  user: IUser;
  onRemove: (id: string) => void;
}> = ({ user, onRemove }) => {
  const handleRemove = React.useCallback(() => {
    onRemove(user.id);
  }, [onRemove, user]);

  const suffixContent = (
    <IconButton
      icon="minus"
      onClick={handleRemove}
      size="sm"
      variantColor="red"
      variant="ghost"
      aria-label="Remove participant"
    />
  );
  return (
    <UserListItem
      suffixContent={suffixContent}
      verticalSpacing
      divider
      user={user}
    />
  );
};

const CreateChatForm: React.FC = () => {
  const { t } = useTranslation();
  const { setOpen } = React.useContext(CreateChatModalContext);
  const [contactPickerOpen, setContactPickerOpen] = React.useState(false);
  const toast = useToast();

  const openContactPicker = React.useCallback(() => {
    setContactPickerOpen(true);
    segment.chat.createChatOpenParticipants();
  }, [setContactPickerOpen]);

  const closeContactPicker = React.useCallback(() => {
    setContactPickerOpen(false);
    segment.chat.createChatCloseParticipants();
  }, [setContactPickerOpen]);

  const createChat = React.useCallback(
    async (values: IFormValue) => {
      try {
        await client.mutate<{ startGroupChat: string }>({
          mutation: createChatMutation,
          variables: {
            title: values.title,
            userIds: values.participants.map((participant) => participant.id),
          },
        });
        segment.chat.createChat();
        toast({
          isClosable: true,
          status: 'success',
          title: t('chatCreated'),
        });
      } catch (e) {
        console.error(e);
        toast({
          isClosable: true,
          status: 'success',
          title: t('generalUnknownError'),
        });
      }
      setOpen(false);
    },
    [setOpen, t, toast]
  );

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    setFieldValue,
  } = useFormik<IFormValue>({
    initialValues: { title: '', participants: [] },
    onSubmit: createChat,
    validateOnMount: true,
    validationSchema,
  });

  const handleParticipantsChange = React.useCallback(
    (newUsers: IUser[]) => {
      setFieldValue('participants', newUsers);
    },
    [setFieldValue]
  );

  const handleRemove = React.useCallback(
    (id: string) => {
      setFieldValue(
        'participants',
        [...values.participants].filter((p) => p.id !== id)
      );
    },
    [setFieldValue, values.participants]
  );

  return (
    <Box as="form" flex="1 1 auto" onSubmit={handleSubmit}>
      <ModalBody>
        <div>
          <FormControl isRequired>
            <FormLabel>{t('generalTitle')}</FormLabel>
            <Input
              isRequired
              value={values.title}
              onChange={handleChange}
              onBlur={handleBlur}
              name="title"
              placeholder={t('generalTitle')}
            />
          </FormControl>
        </div>
        <Box my="6">
          <ContactPickerModal
            open={contactPickerOpen}
            onClose={closeContactPicker}
            onChange={handleParticipantsChange}
            value={values.participants}
            title={t('chatAddParticipants')}
            multiple
          />
          {values.participants.map((participant) => (
            <UserListPreviewItem
              user={participant}
              onRemove={handleRemove}
              key={participant.id}
            />
          ))}
          <Box mt="12px">
            <Button
              isFullWidth
              onClick={openContactPicker}
              size="sm"
              type="button"
              variant="ghost"
              variantColor="teal"
            >
              {t('chatAddParticipants')}
            </Button>
          </Box>
        </Box>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" isDisabled={!isValid} variantColor="teal">
          {t('generalSave')}
        </Button>
      </ModalFooter>
    </Box>
  );
};

export default CreateChatForm;
