import React from 'react';
import styled from 'styled-components';

import { Box } from '@chakra-ui/core';

import { formatChatDate } from '../../utils/chats/formatChatDate';

const Label = styled.span`
  color: ${(p) => p.theme.colors.gray['400']};
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
`;

const Trim: React.FC = () => (
  <Box h="1px" flex="1 1 auto" backgroundColor="gray.200" />
);

interface IProps {
  date: string;
}

const ChatDateLabel: React.FC<IProps> = React.memo(({ date }) => {
  return (
    <Box d="flex" alignItems="center" justifyContent="center">
      <Trim />
      <Box mx="3">
        <Label>{formatChatDate(date, { forceDayFormat: true }).string}</Label>
      </Box>
      <Trim />
    </Box>
  );
});

export default ChatDateLabel;
