import { gql } from 'apollo-boost';

import client from '../../graphql';

const mutation = gql`
  mutation CHANGE_CHAT_AVATAR($chatId: String!, $file: Upload!) {
    uploadAvatar(chatId: $chatId, avatar: $file)
  }
`;

export default (chatId: string, file: File) => {
  return client.mutate<{ uploadAvatar: boolean }>({
    mutation,
    variables: {
      chatId,
      file,
    },
  });
};
