import React from 'react';

import { Avatar } from '@chakra-ui/core';

import getAvatarImageUrl from '../../../shared/utils/getAvatarImageUrl';
import AppBarUser from './AppBarUser';

export const UserButton: React.FC<{
  avatarUrl?: string;
  name: string;
}> = (props) => {
  const { avatarUrl, name } = props;

  return (
    <AppBarUser onClick={() => null}>
      <Avatar
        backgroundColor="gray.300"
        color="gray.500"
        name={name}
        size="sm"
        src={avatarUrl && getAvatarImageUrl(avatarUrl, true)}
      />
    </AppBarUser>
  );
};
