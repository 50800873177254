import { gql } from 'apollo-boost';

import attachmentFragment from '../../../../../../shared/graphql/fragments/attachment.fragment';
import eventFragment from '../../../../../../shared/graphql/fragments/event.fragment';
import messageFragment from '../../../../../../shared/graphql/fragments/message.fragment';

export default gql`
  ${messageFragment}
  ${attachmentFragment}
  ${eventFragment}

  subscription SUBSCRIBE_TO_MESSAGES($chatId: String!) {
    privateMessages(chatId: $chatId) {
      action
      message {
        __typename

        ... on Message {
          ...message
          attachments {
            ...attachment
          }
          forwardedMessage {
            ...message
            attachments {
              ...attachment
            }
          }
          replyMessage {
            ...message
            attachments {
              ...attachment
            }
          }
        }

        ... on DateLabel {
          value
        }

        ... on Event {
          ...event
        }
      }
    }
  }
`;
