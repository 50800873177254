import React, { useContext } from 'react';

import FullscreenLoader from '../../shared/components/FullscreenLoader';
import AuthContext from '../../shared/context/AuthContext';
import connectAccount from '../../shared/utils/connectAccount';
import LoginApplication from '../LoginApplication';
import MainApplication from '../MainApplication';

const App = () => {
  const {
    state: { loading, loggedIn },
  } = useContext(AuthContext);

  React.useEffect(() => {
    let params: { [key: string]: string } = {};
    const search = window.location.search.substr(1);
    search.split('&').forEach((item) => {
      const parts = item.split('=');
      params[parts[0]] = parts[1] || '';
    });

    if (params?.code) {
      connectAccount(params.code);
    }
  }, []);

  if (loading) return <FullscreenLoader />;

  if (loggedIn) return <MainApplication />;
  if (!loggedIn) return <LoginApplication />;

  return null;
};

export default App;
