import client from '../../graphql';
import LOGIN_WITH_CODE_MUTATION from '../../graphql/mutations/loginWithCode.mutation';

const sendLoginCode = async (token: string) => {
  const res = await client.mutate<{ loginWithCode: string }>({
    mutation: LOGIN_WITH_CODE_MUTATION,
    variables: {
      token,
    },
  });

  return res.data?.loginWithCode;
};

export default sendLoginCode;
