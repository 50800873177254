import { IUser } from '../../../global';

/**
 * This function checks if the user is a developer.
 * Right now this functionality is hardcoded with zip-software.com email addresses (via Towio)
 * TODO: Replace hardcoded developer check with a dev flag from the server
 * @param user The user which gets tested
 */
const isDeveloper = (user: IUser | undefined) => {
  if (user?.email?.includes('zip-software.com')) {
    return true;
  }

  return false;
};

export default isDeveloper;
