import { gql } from 'apollo-boost';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { MenuItem } from '@chakra-ui/core';

import { ChatContext } from '../../../../../../shared/context/ChatContext';
import client from '../../../../../../shared/graphql';
import segment from '../../../../../../shared/utils/segment';

const leaveMutation = gql`
  mutation LEAVE_CHAT($chatId: String!) {
    leaveGroupChat(chatId: $chatId)
  }
`;

const LeaveButton: React.FC<{ disabled?: boolean }> = ({ disabled }) => {
  const { chat } = React.useContext(ChatContext);
  const { replace } = useHistory();
  const { t } = useTranslation();

  const handleClick = React.useCallback(async () => {
    if (chat) {
      client.mutate({
        mutation: leaveMutation,
        variables: {
          chatId: chat?.id,
        },
      });

      segment.chat.leaveChat(chat.id);

      replace('/me');
    }
  }, [replace, chat]);

  return (
    <MenuItem onClick={handleClick} isDisabled={disabled}>
      {t('chatLeaveChat')}
    </MenuItem>
  );
};

export default LeaveButton;
