import { gql } from 'apollo-boost';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';
import { v1 as uuid } from 'uuid';

import { useMutation, useQuery } from '@apollo/react-hooks';
import { Box, Modal, ModalOverlay, useToast } from '@chakra-ui/core';
import mergeFileLists from '@d2k/merge-file-lists';

import { IChat } from '../../../../global';
import ChatAttachmentUploadModal from '../../../../shared/components/ChatAttachmentUploadModal';
import ChatInputBar from '../../../../shared/components/ChatInputBar';
import sendMessageMutation from '../../../../shared/components/ChatInputBar/graphql/sendMessage.mutation';
import FullscreenLoader from '../../../../shared/components/FullscreenLoader';
import SidebarView from '../../../../shared/components/SidebarView';
import { ChatContext } from '../../../../shared/context/ChatContext';
import client from '../../../../shared/graphql';
import useMessageDraft from '../../../../shared/hooks/useMessageDraft';
import getErrorMessage from '../../../../shared/utils/getErrorMessage';
import ChatDetailsSidebar from './ChatDetailsSidebar';
import HeaderContainer from './HeaderContainer';
import MessagesContainer from './MessagesContainer';
import getChatQuery from './graphql/getChat.query';

const activeChatMutation = gql`
  mutation SET_ACTIVE_CHAT($chatId: String!) {
    setActiveChat(chatId: $chatId)
  }
`;

const MemorizedMessagesContainer = React.memo(MessagesContainer);

const PrivateChatView: React.FC = () => {
  const [setActiveChat] = useMutation(activeChatMutation);
  const { chatId } = useParams<{ chatId: string }>();
  const { draft, updateDraft, clearDraft } = useMessageDraft(chatId);
  const [files, setFiles] = React.useState<FileList | undefined>();
  const toast = useToast();
  const { t } = useTranslation();

  const { data, loading, refetch } = useQuery<{ chat: IChat }>(getChatQuery, {
    variables: { chatId },
  });

  React.useEffect(() => {
    window.analytics.page('Views', 'Private Chat', { chatId });
    if (chatId) {
      setActiveChat({ variables: { chatId } });
    }

    return () => {
      window.analytics.track('Leave chat', { chatId });
      setActiveChat({ variables: { chatId: 'overview' } });
    };
  }, [chatId, setActiveChat]);

  const submitMessage = React.useCallback(
    async (e?: React.FormEvent<HTMLFormElement>) => {
      if (e?.preventDefault) {
        e.preventDefault();
      }

      try {
        const id = uuid();
        clearDraft();
        setFiles(undefined);
        await client.mutate({
          mutation: sendMessageMutation,
          variables: {
            attachments: files,
            chatId,
            input: sanitizeHtml(draft, { allowedTags: [] }),
            uuid: id,
          },
        });
      } catch (e) {
        console.error(e);
        const error = getErrorMessage(e);
        if (error) {
          toast({
            title: t('generalError'),
            description: t(error.translationString),
            status: 'error',
          });
        }
      }
    },
    [chatId, draft, clearDraft, files, toast, t]
  );

  const handleFileChange = React.useCallback(
    (newFiles: FileList) => {
      if (files) {
        const newFileList = mergeFileLists(files, newFiles);
        setFiles(newFileList);
      } else {
        setFiles(newFiles);
      }
    },
    [files, setFiles]
  );

  const handleFileUpdate = React.useCallback((newFiles: FileList) => {
    setFiles(newFiles);
  }, []);

  const resetFiles = React.useCallback(() => {
    setFiles(undefined);
  }, [setFiles]);

  if (loading) {
    return <FullscreenLoader />;
  }

  return (
    <ChatContext.Provider
      value={{
        chat: data?.chat,
        refetchChat: refetch,
      }}
    >
      <SidebarView sidebar={<ChatDetailsSidebar />}>
        <Box flex="1 1 auto" d="flex" flexDir="column" height="100%">
          <Box
            height="80px"
            width="100%"
            bg="white"
            borderBottom="1px solid"
            borderBottomColor="gray.100"
            p="4"
          >
            <HeaderContainer />
          </Box>
          <Box
            d="flex"
            flexDir="column"
            justifyContent="flex-end"
            flex="1 1 auto"
            overflow="auto"
          >
            <MemorizedMessagesContainer />
          </Box>
          <Box flex="0 0 auto" px="3" py="4">
            <ChatInputBar
              value={draft}
              onChange={updateDraft}
              onSubmit={submitMessage}
              onFileChange={handleFileChange}
            />
          </Box>
          <Modal isOpen={files && files.length > 0} onClose={resetFiles}>
            <ModalOverlay>
              {files && !!files.length && (
                <ChatAttachmentUploadModal
                  value={draft}
                  files={files}
                  onChange={updateDraft}
                  onFileChange={handleFileChange}
                  onFileUpdate={handleFileUpdate}
                  onSubmit={submitMessage}
                />
              )}
            </ModalOverlay>
          </Modal>
        </Box>
      </SidebarView>
    </ChatContext.Provider>
  );
};

export default React.memo(PrivateChatView);
