import { gql } from 'apollo-boost';

import { IChat } from '../../../global';
import client from '../../graphql';
import chatFragment from '../../graphql/fragments/chat.fragment';
import locationFragment from '../../graphql/fragments/location.fragment';

const mutation = gql`
  ${chatFragment}
  ${locationFragment}

  mutation REMOVE_LOCATION($chatId: String!) {
    changeGroupChatLocation(chatId: $chatId) {
      ...chat
      location {
        ...location
      }
    }
  }
`;

export default async (chatId: string) => {
  const res = await client.mutate<{ changeGroupChatLocation: IChat }>({
    mutation,
    variables: {
      chatId,
    },
  });

  return res.data?.changeGroupChatLocation;
};
