import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/core';

import { IUser } from '../../../global';
import ContactPicker from '../../../shared/components/ContactPicker';

interface IProps {
  filter?: (user: IUser) => boolean;
  multiple?: boolean;
  onChange: (newValue: IUser[]) => void;
  onClose: () => void;
  open: boolean;
  title?: string;
  value: IUser[];
}

const ContactPickerModal: React.FC<IProps> = ({
  open,
  title,
  onClose,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalOverlay>
        <ModalContent borderRadius="4px">
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ContactPicker {...rest} />
          </ModalBody>
          <ModalFooter>
            <Button variantColor="teal" onClick={onClose}>
              {t('generalDone')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default ContactPickerModal;
