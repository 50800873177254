import { IUser } from '../../../global';
import getUserName from './getUserName';

export default (users: IUser[]) => {
  return users.sort((userA, userB) => {
    const nameA = getUserName(userA).toLowerCase();
    const nameB = getUserName(userB).toLowerCase();

    if (nameA > nameB) {
      return 1;
    }

    if (nameA < nameB) {
      return -1;
    }

    return 0;
  });
};
