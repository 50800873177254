import { gql } from 'apollo-boost';

import chatFragment from './chat.fragment';

export default gql`
  ${chatFragment}

  query GET_PRIVATE_CHAT($chatId: String!) {
    chat(id: $chatId) {
      ...chat
    }
  }
`;
