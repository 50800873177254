import { gql } from 'apollo-boost';

import { useQuery } from '@apollo/react-hooks';

import { IFriend } from '../../global';

const fragment = gql`
  fragment friend on Friend {
    user {
      id
      firstName
      lastName
      avatarUrl
      phone
      email
    }
    accepted
    blocked
    isRequest
    chat {
      id
    }
    online
    lastOnline
  }
`;

const query = gql`
  ${fragment}

  query useFriends($type: FriendStatusType) {
    friends(type: $type) {
      ...friend
    }
  }
`;

const useFriends = (status?: 'accepted' | 'requested' | 'blocked') => {
  const { data, loading, refetch } = useQuery<{ friends: IFriend[] }>(query, {
    variables: { type: status },
  });

  return {
    data,
    loading,
    refetch,
  };
};

export default useFriends;
