import { IAttachment } from '../../../global';

export const isImageMimeType = (mimeType: string) =>
  ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/bmp'].includes(
    mimeType
  );

export default (attachment: IAttachment) => {
  if (attachment.mimeType) {
    return isImageMimeType(attachment.mimeType);
  }

  return false;
};
