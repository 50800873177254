import React from 'react';
import styled from 'styled-components';

import { Checkbox } from '@chakra-ui/core';

import { IUser } from '../../../global';
import UserListItem from '../UserListItem';

const Label = styled.label`
  cursor: pointer;
  display: block;
`;

const CheckboxWrapper = styled.div`
  line-height: 1;
  margin-right: 12px;
`;

interface IProps {
  user: IUser;
  checked?: boolean;
  onChange: (user: IUser, checked: boolean) => void;
}

const ContactPickerItem: React.FC<IProps> = React.memo(
  ({ user, checked, onChange }) => {
    const handleChange = React.useCallback(() => {
      onChange(user, !checked);
    }, [user, checked, onChange]);

    const prefix = (
      <CheckboxWrapper>
        <Checkbox
          variantColor="teal"
          onChange={handleChange}
          isChecked={checked}
        />
      </CheckboxWrapper>
    );

    return (
      <Label>
        <UserListItem
          divider
          verticalSpacing
          user={user}
          prefixContent={prefix}
        />
      </Label>
    );
  }
);

export default ContactPickerItem;
