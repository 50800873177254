import { gql } from 'apollo-boost';

import client from '../graphql';

const mutation = gql`
  mutation CONNECT_ACCOUNT($token: String!) {
    connectAccount(name: edooli, code: $token, redirectUri: "${process.env.REACT_APP_URL}")
  }
`;

export default async (token: string) => {
  return await client.mutate({
    mutation,
    variables: {
      token,
    },
  });
};
