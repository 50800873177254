import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/core';

import segment from '../../../../../shared/utils/segment';
import CreateChatForm from './CreateChatForm';

export const CreateChatModalContext = React.createContext<{
  open: boolean;
  setOpen: (newState: boolean) => void;
}>({
  open: false,
  setOpen: () => null,
});

const CreateChat: React.FC = () => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const handleOpen = React.useCallback(() => {
    segment.chat.openCreateChat();
    setOpen(true);
  }, [setOpen]);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <CreateChatModalContext.Provider value={{ open, setOpen }}>
      <Button
        color="gray.500"
        isFullWidth
        leftIcon="add"
        onClick={handleOpen}
        size="sm"
        variant="ghost"
      >
        {t('generalCreateChat')}
      </Button>
      <Modal isOpen={open} onClose={handleClose}>
        <ModalOverlay>
          <ModalContent borderRadius="4px">
            <ModalHeader>{t('generalCreateChat')}</ModalHeader>
            <ModalCloseButton />
            <CreateChatForm />
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </CreateChatModalContext.Provider>
  );
};

export default CreateChat;
