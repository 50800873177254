import React from 'react';
import sanitizeHtml from 'sanitize-html';
import styled from 'styled-components';

import { Button, Icon } from '@chakra-ui/core';

import ChatSlateInputField from '../ChatSlateInputField';
import { useTranslation } from 'react-i18next';

interface IProps {
  onChange: (newString: string) => void;
  onSubmit: (e?: React.FormEvent<HTMLFormElement>) => any;
  onFileChange: (newFiles: FileList) => void;
  value: string;
  hasAttachment?: boolean;
}

const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 28px;
  justify-content: center;
  min-width: 0;
  width: 28px;
`;

const ChatInputBar: React.FC<IProps> = ({
  onSubmit,
  onChange,
  onFileChange,
  value,
  hasAttachment,
}) => {
  const uploadInputRef = React.useRef<HTMLInputElement | null>(null);
  const form = React.useRef<HTMLFormElement | null>(null);
  const { t } = useTranslation();

  React.useEffect(() => {
    const currentForm = form.current;
    const pasteHandler = (e: ClipboardEvent) => {
      const items = e.clipboardData?.files;
      if (items?.length) {
        onFileChange(items);
      }
    };

    const dragOverHandler = (e: DragEvent) => {
      e.preventDefault();
    };

    const dropHandler = (e: DragEvent) => {
      if (e.dataTransfer?.files?.length) {
        e.preventDefault();
        onFileChange(e.dataTransfer.files);
      }
    };

    currentForm?.addEventListener('paste', pasteHandler);
    currentForm?.addEventListener('drop', dropHandler);
    currentForm?.addEventListener('dragover', dragOverHandler);

    return () => {
      currentForm?.removeEventListener('paste', pasteHandler);
      currentForm?.removeEventListener('drop', dropHandler);
      currentForm?.removeEventListener('dragover', dragOverHandler);
    };
  }, [onFileChange]);

  const openFilePicker = React.useCallback(() => {
    if (!!uploadInputRef.current) {
      uploadInputRef.current.click();
    }
  }, []);

  const handleFileSelect = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.persist();
      const files = e.target.files;
      if (files) {
        onFileChange(files);
      }
    },
    [onFileChange]
  );

  const sanitizedValue = sanitizeHtml(value.trimLeft().trimRight(), {
    allowedTags: [],
  });

  const disabled = sanitizedValue.length === 0 && !hasAttachment;

  const handleInputSubmit = React.useCallback(() => {
    if (!disabled) {
      if (form.current) {
        onSubmit();
      }
    }
  }, [disabled, onSubmit]);

  const SubmitButton = (
    <ButtonWrapper>
      <Button
        aria-label="X"
        color="gray.500"
        variant="ghost"
        isDisabled={disabled}
        type="submit"
        w="28px"
        h="28px"
        minW="0"
      >
        <Icon size="5" name="arrowFeatherRight" />
      </Button>
    </ButtonWrapper>
  );

  const AttachmentButton = (
    <>
      <input
        onChange={handleFileSelect}
        ref={uploadInputRef}
        style={{ display: 'none' }}
        multiple
        type="file"
      />
      <ButtonWrapper>
        <Button
          aria-label="upload"
          color="gray.500"
          variant="ghost"
          type="button"
          onClick={openFilePicker}
          w="28px"
          h="28px"
          minW="0"
        >
          <Icon size="3" name="add" />
        </Button>
      </ButtonWrapper>
    </>
  );

  return (
    <form onSubmit={onSubmit} ref={form}>
      <ChatSlateInputField
        value={value}
        onChange={onChange}
        placeholder={t('generalMessagePlaceholder')}
        onSubmit={handleInputSubmit}
        prefixContent={AttachmentButton}
        suffixContent={SubmitButton}
      />
    </form>
  );
};

export default React.memo(ChatInputBar);
