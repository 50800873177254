import { useEffect, useState } from 'react';

const useDebouncedValue = <T = any>(delay: number, input: T) => {
  const [value, setValue] = useState<T>(input);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (input) {
      setLoading(true);
    }

    const handler = window.setTimeout(() => {
      setValue(input);
      setLoading(false);
    }, delay);

    return () => {
      window.clearTimeout(handler);
    };
  }, [delay, input, setValue]);

  return { value, loading };
};

export default useDebouncedValue;
