import { useEffect } from 'react';

import { useQuery } from '@apollo/react-hooks';

import { IFriend, SubscriptionAction } from '../../../global';
import { query, subscription } from './apollo';

interface QueryResult {
  friends: IFriend[];
}

interface SubscriptionResult {
  friends: {
    action: SubscriptionAction;
    friend: IFriend;
  };
}

const resolver = (prev: QueryResult, data: SubscriptionResult): QueryResult => {
  switch (data.friends.action) {
    case 'created':
      return { friends: [...prev.friends, data.friends.friend] };

    case 'updated':
      if (prev.friends) {
        prev.friends = prev.friends.map((friend) => {
          if (friend.user.id === data.friends.friend.user.id) {
            return data.friends.friend;
          }
          return friend;
        });
      }
      return { ...prev };

    default:
      return prev;
  }
};

export default () => {
  const { data, loading, refetch, subscribeToMore } = useQuery<QueryResult>(
    query
  );

  useEffect(() => {
    subscribeToMore<SubscriptionResult>({
      document: subscription,
      updateQuery: (prev, { subscriptionData }) => {
        return resolver(prev, subscriptionData.data);
      },
    });
  }, [subscribeToMore]);

  return { data, loading, refetch };
};
