import React from 'react';
import styled from 'styled-components';

import { Heading, Text } from '@chakra-ui/core';

import getResizedImageUrl from '../../utils/getResizedImageUrl';

interface IProps {
  name: string;
  avatarUrl?: string;
  subTitle?: string;
}

const Wrapper = styled.div`
  background: #000;
  height: 380px;
  overflow: hidden;
  position: relative;
  width: 100%;

  &:before {
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.6) 33%,
      rgba(0, 0, 0, 0)
    );
    bottom: 0;
    content: '';
    display: block;
    height: 200px;
    left: 0;
    z-index: 2;
    width: 100%;
    position: absolute;
  }
`;

const CoverImage = styled.img`
  height: 100%;
  left: 0;
  object-fit: cover;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
`;

const Content = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  left: 0;
  padding: 24px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
`;

const SubTitleWrapper = styled.div`
  color: rgba(255, 255, 255, 0.75);
`;

const ChatCover: React.FC<IProps> = React.memo(
  ({ name, avatarUrl, subTitle }) => {
    return (
      <Wrapper>
        {avatarUrl && (
          <CoverImage
            src={avatarUrl && getResizedImageUrl(avatarUrl, 400)}
            alt={name}
          />
        )}
        <Content>
          <Heading as="h4" fontSize="2xl">
            {name}
          </Heading>
          {subTitle && (
            <SubTitleWrapper>
              <Text fontSize="lg">{subTitle}</Text>
            </SubTitleWrapper>
          )}
        </Content>
      </Wrapper>
    );
  }
);

export default ChatCover;
