const openAdd = () => {
  window.analytics.track('ContactOpenAdd');
};

const closeAdd = () => {
  window.analytics.track('ContactOpenAdd');
};

export default {
  openAdd,
  closeAdd,
};
