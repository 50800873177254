export default {
  data: {
    __schema: {
      types: [
        {
          kind: 'OBJECT',
          name: 'Query',
          possibleTypes: null,
        },
        {
          kind: 'SCALAR',
          name: 'String',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'User',
          possibleTypes: null,
        },
        {
          kind: 'SCALAR',
          name: 'UUID',
          possibleTypes: null,
        },
        {
          kind: 'SCALAR',
          name: 'Boolean',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'Message',
          possibleTypes: null,
        },
        {
          kind: 'SCALAR',
          name: 'DateTime',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'Chat',
          possibleTypes: null,
        },
        {
          kind: 'ENUM',
          name: 'ChatType',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'ReadBy',
          possibleTypes: null,
        },
        {
          kind: 'SCALAR',
          name: 'Int',
          possibleTypes: null,
        },
        {
          kind: 'UNION',
          name: 'MessageUnion',
          possibleTypes: [
            {
              name: 'Message',
            },
            {
              name: 'DateLabel',
            },
          ],
        },
        {
          kind: 'OBJECT',
          name: 'DateLabel',
          possibleTypes: null,
        },
        {
          kind: 'ENUM',
          name: 'FriendStatusType',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'Friend',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'Notification',
          possibleTypes: null,
        },
        {
          kind: 'UNION',
          name: 'NotificationData',
          possibleTypes: [
            {
              name: 'Friend',
            },
            {
              name: 'Message',
            },
          ],
        },
        {
          kind: 'OBJECT',
          name: 'Mutation',
          possibleTypes: null,
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'SendMessageInput',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'IFriendRequestResult',
          possibleTypes: null,
        },
        {
          kind: 'ENUM',
          name: 'TokenType',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'Subscription',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'PrivateMessageSubscriptionResult',
          possibleTypes: null,
        },
        {
          kind: 'ENUM',
          name: 'Action',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'PrivateChatSubscriptionResult',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'FriendResult',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'NotificationResult',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: '__Schema',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: '__Type',
          possibleTypes: null,
        },
        {
          kind: 'ENUM',
          name: '__TypeKind',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: '__Field',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: '__InputValue',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: '__EnumValue',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: '__Directive',
          possibleTypes: null,
        },
        {
          kind: 'ENUM',
          name: '__DirectiveLocation',
          possibleTypes: null,
        },
        {
          kind: 'ENUM',
          name: 'FriendResultAction',
          possibleTypes: null,
        },
        {
          kind: 'OBJECT',
          name: 'UserSubscriptionResult',
          possibleTypes: null,
        },
      ],
    },
  },
};
