import { gql } from 'apollo-boost';

import { IChat } from '../../../global';
import client from '../../graphql';
import chatFragment from '../../graphql/fragments/chat.fragment';
import locationFragment from '../../graphql/fragments/location.fragment';

const mutation = gql`
  ${chatFragment}
  ${locationFragment}

  mutation SET_LOCATION($chatId: String!, $placeId: String!) {
    changeGroupChatLocation(chatId: $chatId, placeId: $placeId) {
      ...chat
      location {
        ...location
      }
    }
  }
`;

export default async (chatId: string, placeId: string) => {
  const res = await client.mutate<{ changeGroupChatLocation: IChat }>({
    mutation,
    variables: {
      chatId,
      placeId,
    },
  });

  return res.data?.changeGroupChatLocation;
};
