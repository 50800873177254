import { useContext } from 'react';

import AuthContext from '../context/AuthContext';

const useCurrentUser = () => {
  const {
    state: { user },
  } = useContext(AuthContext);
  return user;
};

export default useCurrentUser;
