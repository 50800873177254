import React from 'react';
import styled from 'styled-components';

import { Box, Heading, Text } from '@chakra-ui/core';

import logo from '../../../shared/images/zip-logo.svg';

interface IProps {
  title: string;
  subtitle?: string;
}

const LogoImage = styled.img`
  display: block;
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
`;

const LoginScreenHeader = React.memo(({ title, subtitle }: IProps) => {
  return (
    <Box textAlign="center">
      <LogoImage src={logo} />
      <Heading color="gray.900">{title}</Heading>
      {subtitle && (
        <Text mt="2" color="gray.500">
          {subtitle}
        </Text>
      )}
    </Box>
  );
});

export default LoginScreenHeader;
