import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Badge,
  Box,
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuList,
} from '@chakra-ui/core';

import { IUser } from '../../../../../../global';
import ParticipantsList from '../../../../../../shared/components/ParticipantsList';
import AuthContext from '../../../../../../shared/context/AuthContext';
import { ChatContext } from '../../../../../../shared/context/ChatContext';
import sortUsers from '../../../../../../shared/utils/user/sortUsers';
import AddParticipantsButton from './AddParticipantsButton';
import LeaveButton from './LeaveButton';
import PromoteToOwnerButton from './PromoteToOwnerButton';
import RemoveUserButton from './RemoveUserButton';

const ParticipantsSuffixContent: React.FC<{ ownerId: string; user: IUser }> = ({
  user,
  ownerId,
}) => {
  const {
    state: { user: currentUser },
  } = React.useContext(AuthContext);
  const { t } = useTranslation();

  const isCurrentUser = user.id === currentUser?.id;
  const isOwner = currentUser?.id === ownerId;

  if ((isCurrentUser && isOwner) || user.id === ownerId) {
    return (
      <div>
        <Box ml="4px">
          <Badge
            variantColor="teal"
            p="1"
            fontSize="xs"
            minW="20px"
            textAlign="center"
            fontWeight="500"
          >
            {t('generalOwner')}
          </Badge>
        </Box>
      </div>
    );
  } else if (!isOwner && !isCurrentUser) {
    return null;
  } else {
    return (
      <div>
        <Menu>
          <MenuButton
            as={Button}
            {...{ size: 'sm', variant: 'ghost', color: 'gray.500' }}
          >
            <Icon name="more" size="4" />
          </MenuButton>
          <MenuList placement="right-start">
            {!isCurrentUser && isOwner && (
              <PromoteToOwnerButton userId={user.id} />
            )}
            {!isCurrentUser && isOwner && <RemoveUserButton userId={user.id} />}
            {isCurrentUser && <LeaveButton disabled={isOwner} />}
          </MenuList>
        </Menu>
      </div>
    );
  }
};

const GroupChatParticipants: React.FC<{ data: IUser[] }> = ({ data }) => {
  const { chat } = React.useContext(ChatContext);
  const {
    state: { user: currentUser },
  } = React.useContext(AuthContext);

  const sortedUsers = React.useMemo(() => {
    return sortUsers(data)
      .sort((userA) => {
        if (userA.id === currentUser?.id) {
          return -1;
        }

        return 0;
      })
      .sort((userA) => {
        if (userA.id === chat?.owner?.id) {
          return -1;
        }

        return 0;
      });
  }, [data, currentUser, chat]);

  const suffixContent = React.useCallback(
    (user: IUser) => (
      <ParticipantsSuffixContent ownerId={chat?.owner?.id || ''} user={user} />
    ),
    [chat]
  );

  return (
    <>
      <ParticipantsList
        participants={sortedUsers}
        suffixContent={suffixContent}
      />
      <AddParticipantsButton />
    </>
  );
};

export default React.memo(GroupChatParticipants);
