import { IChat } from '../../../global';
import getChatName from './getChatName';

const searchChat = (query: string, chats: IChat[]) => {
  return chats.filter((chat) => {
    const name = getChatName(chat).toLowerCase();
    const withUser = chat?.withUser;
    return (
      name.includes(query.toLowerCase()) ||
      withUser?.phone?.includes(query.toLowerCase()) ||
      withUser?.email?.includes(query.toLowerCase())
    );
  });
};

export default searchChat;
