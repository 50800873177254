import { IFriend } from '../../graphql/fragments/friend.fragment';
import getUserName from './getUserName';

export default (contacts: IFriend[], query: string) => {
  const lowerQuery = query.toLowerCase();

  return contacts.filter((contact) => {
    const name = getUserName(contact.user).toLowerCase();
    return name.includes(lowerQuery);
  });
};
