import dayjs from 'dayjs';

const formatTime = (date: string, locale: string = 'de') => {
  let format = 'hh:mm A';

  if (locale.indexOf('de') > -1) {
    format = 'HH:mm';
  }

  return dayjs(date).format(format);
};

export default formatTime;
