import { INotification } from '../../../global';

const createNotification = (
  notification: INotification,
  handler?: (...args: any) => any
) => {
  const not = new Notification(notification.content.title, {
    body: notification.content.body,
    image: notification.content.image,
    icon: notification.content.image,
    data: notification.content.data,
    timestamp: parseInt(notification.createdAt),
  });

  not.onclick = (e) => {
    if (handler) {
      handler();
    }
    window.focus();
  };
};

export default (
  notification: INotification,
  handler?: (...args: any) => any
) => {
  if (Notification.permission !== 'granted') {
    Notification.requestPermission((permission) => {
      if (permission === 'granted') {
        createNotification(notification, handler);
      }
    });
  } else {
    createNotification(notification, handler);
  }
};
