const open = () => {
  window.analytics.track('SettingsOpen');
};

const openProfileSettings = () => {
  window.analytics.track('SettingsOpenProfileSettings');
};

const updateProfile = () => {
  window.analytics.track('SettingsUpdateProfile');
};

const updateAvatar = () => {
  window.analytics.track('SettingsUpdateAvatar');
};

const openConnectionSettings = () => {
  window.analytics.track('SettingsOpenConnectionSettings');
};

const connectEdooli = () => {
  window.analytics.track('SettingsConnectEdooli');
};

const disconnectEdooli = () => {
  window.analytics.track('SettingsDisconnectEdooli');
};

export default {
  connectEdooli,
  disconnectEdooli,
  open,
  openConnectionSettings,
  openProfileSettings,
  updateAvatar,
  updateProfile,
};
