import React from 'react';
import { Redirect } from 'react-router-dom';

import LoginScreenHeader from '../../../../../../shared/components/LoginScreenHeader';
import AuthContext from '../../../../../../shared/context/AuthContext';

const AvatarSetupView: React.FC = () => {
  const {
    state: { user },
  } = React.useContext(AuthContext);

  // Return the user to the setup screen when profile is not completed yet
  if (!user?.lastName) {
    return <Redirect to="/setup" />;
  }

  const title = `Hello ${user.firstName}!`;

  return (
    <>
      <LoginScreenHeader
        title={title}
        subtitle="Let other people know who you are"
      />
    </>
  );
};

export default AvatarSetupView;
