export interface ILanguage {
  label: string;
  translateToLabel: string;
  key: string;
}

const languages: ILanguage[] = [
  {
    label: 'langGerman',
    translateToLabel: 'langToGerman',
    key: 'de',
  },
  {
    label: 'langEnglish',
    translateToLabel: 'langToEnglish',
    key: 'en',
  },
  {
    label: 'langSpanish',
    translateToLabel: 'langToSpanish',
    key: 'es',
  },
  {
    label: 'langFrench',
    translateToLabel: 'langToFrench',
    key: 'fr',
  },
  {
    label: 'langPolish',
    translateToLabel: 'langToPolish',
    key: 'pl',
  },
  {
    label: 'langItalian',
    translateToLabel: 'langToItalian',
    key: 'it',
  },
  {
    label: 'langPortuguese',
    translateToLabel: 'langToPortuguese',
    key: 'pt',
  },
  {
    label: 'langDutch',
    translateToLabel: 'langToDutch',
    key: 'nl',
  },
  {
    label: 'langAlbanian',
    translateToLabel: 'langToAlbanian',
    key: 'sq',
  },
  {
    label: 'langRussian',
    translateToLabel: 'langToRussian',
    key: 'ru',
  },
  {
    label: 'langRomanian',
    translateToLabel: 'langToRomanian',
    key: 'ro',
  },
];

export default languages;
