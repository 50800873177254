import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/core';

interface IProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onReset: () => void;
  placeholder?: string;
  value: string;
}

const SearchField: React.FC<IProps> = React.memo(
  ({ onChange, onReset, value, placeholder }) => {
    const { t } = useTranslation();
    const resetButton = value.length > 0 && (
      <IconButton
        onClick={onReset}
        color="gray.500"
        d="block"
        size="xs"
        variant="ghost"
        icon="close"
        aria-label="Reset search"
      />
    );

    return (
      <label>
        <InputGroup size="md">
          <InputLeftElement>
            <Icon color="gray.500" name="search" />
          </InputLeftElement>
          <Input
            onChange={onChange}
            value={value}
            placeholder={placeholder || t('generalSearch')}
            pr="10"
          />
          <InputRightElement>{resetButton}</InputRightElement>
        </InputGroup>
      </label>
    );
  }
);

export default SearchField;
