import React from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/react-hooks';
import { Spinner } from '@chakra-ui/core';

import SecondarySidebar from '../../../../../shared/components/Layouts/AppLayout/SecondarySidebar';
import { ChatContext } from '../../../../../shared/context/ChatContext';
import { ChatDetailsProvider } from '../../../../../shared/context/ChatDetailsContext';
import segment from '../../../../../shared/utils/segment';
import DirectChatSidebar from './DirectChatSidebar';
import GroupChatSidebar from './GroupChatSidebar';
import chatDetailsQuery, { IQueryData } from './graphql/chatDetails.query';

const ChatDetailsSidebar: React.FC = () => {
  const { chatId } = useParams<{ chatId: string }>();

  React.useEffect(() => {
    segment.chat.openDetails(chatId);
  }, [chatId]);

  const { loading, data, refetch } = useQuery<{
    chat: IQueryData;
  }>(chatDetailsQuery, {
    variables: { id: chatId },
    fetchPolicy: 'cache-and-network',
  });

  const Loader = (
    <div>
      <Spinner size="md" />
    </div>
  );

  if (loading && !data) {
    return <SecondarySidebar>{Loader}</SecondarySidebar>;
  }

  if (!data?.chat) {
    return null;
  }

  return (
    <ChatContext.Provider value={{ chat: data.chat, refetchChat: refetch }}>
      <ChatDetailsProvider chat={data.chat}>
        <SecondarySidebar>
          {data.chat?.type === 'direct' && <DirectChatSidebar />}
          {data.chat?.type === 'group' && <GroupChatSidebar />}
        </SecondarySidebar>
      </ChatDetailsProvider>
    </ChatContext.Provider>
  );
};

export default ChatDetailsSidebar;
