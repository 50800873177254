import React from 'react';
import { useTranslation } from 'react-i18next';
import sanitizeHtml from 'sanitize-html';
import styled, { css, keyframes } from 'styled-components';

import { IMessage } from '../../../../../../../global';
import MessageEmbededdedContent from '../../../../../../../shared/components/MessageEmbeddedContent';
import translateMessage from '../../../../../../../shared/utils/messages/translateMessage';
import getUserName from '../../../../../../../shared/utils/user/getUserName';
import MessageContent from '../MessageContent';
import { MessageContext } from '..';

const Notice = styled.span<{ italic?: boolean }>`
  display: block;
  font-size: 10px;
  margin-left: 4px;
  opacity: 0.75;
  font-style: ${(p) => (p.italic ? 'italic' : 'none')};
  user-select: none;
`;

const loadingKeyFrames = keyframes`
  0% {
    opacity: .5;
  }
  50% {
    opacity: .25;
  }
  100%: {
    opacity: .5;
  }
`;

const loadingAnimation = css`
  animation: ${loadingKeyFrames} 2s linear infinite;
  opacity: 0.5;
`;

const Wrapper = styled.div<{ loading?: boolean }>`
  ${(p) => p.loading && loadingAnimation}
`;

interface IProps {
  message: IMessage;
}

const sanitizeText = (text?: string) => {
  if (!text) {
    return;
  }

  return sanitizeHtml(text.replace(/\n/g, '<br />'), {
    allowedTags: ['br'],
  });
};

const MessageContentContainer: React.FC<IProps> = React.memo(({ message }) => {
  const { t } = useTranslation();
  const { translation } = React.useContext(MessageContext);
  const [translationLoading, setTranslationLoading] = React.useState(false);
  const [translatedText, setTranslatedText] = React.useState<
    string | undefined
  >();

  React.useEffect(() => {
    (async () => {
      setTranslatedText(undefined);
      setTranslationLoading(true);
      if (translation) {
        try {
          await translateMessage(message.id, translation).then((res) => {
            if (res.data?.translateMessage) {
              setTranslatedText(res.data.translateMessage.translation.text);
            }
          });
        } catch (e) {
          console.error(e);
        }
      }
      setTranslationLoading(false);
    })();
  }, [translation, message, setTranslatedText, setTranslationLoading]);

  if (message.deleted) {
    return <Notice italic>{t('chatMessageDeleted')}</Notice>;
  }

  const forwardedName = message.forwardedMessage?.fromUser
    ? getUserName(message.forwardedMessage?.fromUser)
    : '';
  const repliedName = message.replyMessage?.fromUser
    ? getUserName(message.replyMessage?.fromUser)
    : '';

  return (
    <Wrapper loading={translationLoading}>
      {message.forwardedMessage && (
        <MessageEmbededdedContent
          title={t('chatForwardedFrom', { name: forwardedName })}
        >
          <MessageContentContainer message={message.forwardedMessage} />
        </MessageEmbededdedContent>
      )}
      {message.replyMessage && (
        <MessageEmbededdedContent
          title={t('chatReplyToMessage', { name: repliedName })}
        >
          <MessageContentContainer message={message.replyMessage} />
        </MessageEmbededdedContent>
      )}
      <MessageContent
        text={sanitizeText(message.text)}
        translationText={translatedText && sanitizeText(translatedText)}
        attachments={message.attachments || []}
      />
      <div>
        {!!translation && translateMessage && (
          <Notice>{t('generalTranslated')}</Notice>
        )}
        {message.edited && <Notice>{t('generalEdited')}</Notice>}
      </div>
    </Wrapper>
  );
});

export default MessageContentContainer;
