import { gql } from 'apollo-boost';

import { ILocation } from '../../../../../../global';
import USER_FRAGMENT, {
  IUserFragment,
} from '../../../../../../shared/graphql/fragments/USER.fragment';
import chatFragment, {
  IChatFragment,
} from '../../../../../../shared/graphql/fragments/chat.fragment';
import locationFragment from '../../../../../../shared/graphql/fragments/location.fragment';

export interface IQueryData extends IChatFragment {
  withUser?: IUserFragment;
  participants?: IUserFragment[];
  owner?: IUserFragment;
  location?: ILocation;
}

export default gql`
  ${chatFragment}
  ${USER_FRAGMENT}
  ${locationFragment}

  query chatDetails($id: String!) {
    chat(id: $id) {
      ...chat
      location {
        ...location
      }
      withUser {
        ...user
      }
      participants {
        ...user
      }
      owner {
        ...user
      }
    }
  }
`;
