import React from 'react';
import styled from 'styled-components';

import { Avatar, Box } from '@chakra-ui/core';

import getResizedImageUrl from '../../utils/getResizedImageUrl';

const BackgroundImage = styled.img`
  filter: blur(32px);
  height: calc(100% + 128px);
  left: calc(0px - 64px);
  object-fit: cover;
  opacity: 0.25;
  pointer-events: none;
  position: absolute;
  top: calc(0px - 64px);
  user-select: none;
  width: calc(100% + 128px);
  z-index: 1;
`;

interface IProps {
  avatarUrl?: string;
  name: string;
  subText: string;
}

const ContactHeader: React.FC<IProps> = React.memo(
  ({ name, subText, avatarUrl }) => {
    return (
      <Box
        bg="black"
        overflow="hidden"
        position="relative"
        w="100%"
        borderRadius="4px"
      >
        {avatarUrl && (
          <BackgroundImage
            src={getResizedImageUrl(avatarUrl, 500)}
            alt={name}
          />
        )}
        <Box
          alignItems="flex-end"
          color="white"
          d="flex"
          h={['250px', '300px', '350px']}
          p="8"
          position="relative"
          zIndex={2}
        >
          <Box display="flex" alignItems="center">
            <Box mr="24px">
              <Avatar src={avatarUrl} name={name} />
            </Box>
            <Box>
              <Box
                fontSize={['24px', '40px']}
                lineHeight="1.3"
                fontWeight="600"
              >
                {name}
              </Box>
              <Box
                fontSize={['14px', '20px']}
                lineHeight="1.3"
                color="white"
                opacity={0.65}
              >
                {subText}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
);

export default ContactHeader;
