import React from 'react';

const customIcons: Record<string, any> = {
  translate: {
    path: (
      <path
        stroke="currentColor"
        fill="transparent"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 5h12M9 3v2m1.048 9.5A18.022 18.022 0 016.412 9m6.088 9h7M11 21l5-10 5 10M12.751 5C11.783 10.77 8.07 15.61 3 18.129"
      />
    ),
    viewBox: '0 0 24 24',
  },
  user: {
    path: (
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
        fill="transparent"
        stroke="currentColor"
      />
    ),
    viewBox: '0 0 24 24',
  },
  chat: {
    path: (
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
        fill="transparent"
        stroke="currentColor"
      />
    ),
    viewBox: '0 0 24 24',
  },
  more: {
    path: (
      <g
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        fill="transparent"
        stroke="currentColor"
      >
        <circle cx="12" cy="12" r="1" />
        <circle cx="12" cy="5" r="1" />
        <circle cx="12" cy="19" r="1" />
      </g>
    ),
    viewBox: '0 0 24 24',
  },
  file: {
    path: (
      <g
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        fill="transparent"
        stroke="currentColor"
      >
        <path d="M13 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V9z" />
        <path d="M13 2v7h7" />
      </g>
    ),
    viewBox: '0 0 24 24',
  },
  downloadCloud: {
    path: (
      <g
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        fill="transparent"
        stroke="currentColor"
      >
        <path d="M8 17l4 4 4-4M12 12v9" />
        <path d="M20.88 18.09A5 5 0 0018 9h-1.26A8 8 0 103 16.29" />
      </g>
    ),
    viewBox: '0 0 24 24',
  },
  arrowFeatherRight: {
    path: (
      <g
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        fill="transparent"
        stroke="currentColor"
      >
        <path d="M5 12h14M12 5l7 7-7 7" />
      </g>
    ),
    viewBox: '0 0 24 24',
  },
};

export default customIcons;
