import React from 'react';
import { Trans } from 'react-i18next';

import { IEvent } from '../../../../../../global';
import UserWithChatEvent from '../../../../../../shared/components/ChatEvents/UserWithChatEvent';
import UserWithUserEvent from '../../../../../../shared/components/ChatEvents/UserWithUserEvent';
import getUserName from '../../../../../../shared/utils/user/getUserName';

const renderEvent = (message: IEvent) => {
  switch (message.event) {
    case 'ADD_USER_TO_GROUP_CHAT':
      if (!message.values?.executor || !message.values?.target) {
        return null;
      }
      return (
        <UserWithUserEvent
          executor={message.values.executor}
          target={message.values.target}
        >
          <Trans
            key="chatEventsUserAdded"
            values={{
              nameOne:
                message.values?.executor &&
                getUserName(message.values?.executor),
              nameTwo:
                message.values?.target && getUserName(message.values?.target),
            }}
          >
            chatEventsUserAdded
          </Trans>
        </UserWithUserEvent>
      );

    case 'REMOVE_USER_FROM_GROUP_CHAT':
      if (!message.values?.executor || !message.values?.target) {
        return null;
      }
      return (
        <UserWithUserEvent
          executor={message.values.executor}
          target={message.values.target}
        >
          <Trans
            key="chatEventsUserRemoved"
            values={{
              nameOne:
                message.values?.executor &&
                getUserName(message.values?.executor),
              nameTwo:
                message.values?.target && getUserName(message.values?.target),
            }}
          >
            chatEventsUserRemoved
          </Trans>
        </UserWithUserEvent>
      );

    case 'USER_LEFT_GROUP_CHAT':
      if (!message.values?.executor || !message.values?.value) {
        return null;
      }
      return (
        <UserWithChatEvent executor={message.values.executor}>
          <Trans
            key="chatEventsUserLeft"
            values={{
              name:
                message.values?.executor &&
                getUserName(message.values?.executor),
            }}
          >
            chatEventsUserLeft
          </Trans>
        </UserWithChatEvent>
      );

    case 'RENAME_GROUP_CHAT':
      if (!message.values?.executor) {
        return null;
      }

      return (
        <UserWithChatEvent executor={message.values.executor}>
          <Trans
            key="chatEventsRenamed"
            values={{
              name:
                message.values?.executor &&
                getUserName(message.values?.executor),
            }}
          >
            chatEventsRenamed
          </Trans>
        </UserWithChatEvent>
      );

    case 'EDIT_LOCATION_IN_GROUP_CHAT':
      if (!message.values?.executor) {
        return null;
      }

      return (
        <UserWithChatEvent executor={message.values.executor}>
          <Trans
            key="chatEventsChangeLocation"
            values={{
              name:
                message.values?.executor &&
                getUserName(message.values?.executor),
              value: message.values?.value,
            }}
          >
            chatEventsChangeLocation
          </Trans>
        </UserWithChatEvent>
      );

    case 'DELETE_LOCATION_IN_GROUP_CHAT':
      if (!message.values?.executor) {
        return null;
      }

      return (
        <UserWithChatEvent executor={message.values.executor}>
          <Trans
            key="chatEventsRemoveLocation"
            values={{
              name:
                message.values?.executor &&
                getUserName(message.values?.executor),
            }}
          >
            chatEventsRemoveLocation
          </Trans>
        </UserWithChatEvent>
      );

    case 'USER_CREATED_GROUP_CHAT':
      if (!message.values?.executor) {
        return null;
      }

      return (
        <UserWithChatEvent executor={message.values.executor}>
          <Trans
            key="chatEventsCreated"
            values={{
              name:
                message.values?.executor &&
                getUserName(message.values?.executor),
              value: message.values?.value,
            }}
          >
            chatEventsCreated
          </Trans>
        </UserWithChatEvent>
      );

    case 'USER_CHANGED_AVATAR':
      if (!message.values?.executor) {
        return null;
      }

      return (
        <UserWithChatEvent executor={message.values.executor}>
          <Trans
            key="chatEventsChangeAvatar"
            values={{
              name:
                message.values?.executor &&
                getUserName(message.values?.executor),
            }}
          >
            chatEventsChangeAvatar
          </Trans>
        </UserWithChatEvent>
      );

    case 'OWNER CHANGED':
      if (!message.values?.executor || !message.values?.target) {
        return null;
      }

      return (
        <UserWithUserEvent
          executor={message.values.executor}
          target={message.values.target}
        >
          <Trans
            key="chatEventsPromoteUser"
            values={{
              name:
                message.values?.executor &&
                getUserName(message.values?.executor),
              target:
                message.values?.target && getUserName(message.values?.target),
            }}
          >
            chatEventsPromoteUser
          </Trans>
        </UserWithUserEvent>
      );

    default:
      return '';
  }
};

export default renderEvent;
