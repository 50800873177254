import React from 'react';
import { Node, createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { Slate, withReact } from 'slate-react';
import styled from 'styled-components';

import InputEditor from './InputEditor';

const Wrapper = styled.div`
  align-items: flex-end;
  border-radius: 4px;
  border: 1px solid ${(p) => p.theme.colors.gray['300']};
  display: flex;
  width: 100%;
`;

const BarContent = styled.div`
  align-self: center;
  flex: 1 1 auto;
  max-height: 60vh;
  min-height: 40px;
  overflow: hidden;
  white-space: pre-wrap;
`;

const LeftContent = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  height: 40px;
  padding-left: 8px;
`;

const RightContent = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  min-height: 40px;
  padding-right: 8px;
`;

interface IProps {
  onChange: (newValue: string) => void;
  onSubmit?: (value: string) => void;
  placeholder?: string;
  prefixContent?: JSX.Element;
  suffixContent?: JSX.Element;
  value: string;
}

const serialize = (value: Node[]) => {
  const newValue = value.map((n) => Node.string(n)).join('\n');
  return newValue;
};

const deserialize = (value: string) => {
  return value.split('\n').map((line) => {
    return {
      children: [{ text: line }],
    };
  });
};

const ChatSlateInputField: React.FC<IProps> = React.memo(
  ({
    value,
    placeholder,
    onChange,
    onSubmit,
    prefixContent,
    suffixContent,
  }) => {
    const editor = React.useMemo(
      () => withHistory(withReact(createEditor())),
      []
    );

    const handleTextChange = React.useCallback(
      (currentValue: Node[]) => {
        onChange(serialize(currentValue));
      },
      [onChange]
    );

    const handleSubmit = React.useCallback(() => {
      if (onSubmit) {
        onSubmit(value);
      }
    }, [value, onSubmit]);

    return (
      <Wrapper>
        {prefixContent && <LeftContent>{prefixContent}</LeftContent>}
        <BarContent>
          <Slate
            editor={editor}
            value={deserialize(value)}
            onChange={handleTextChange}
          >
            <InputEditor
              editor={editor}
              placeholder={placeholder || ''}
              onSubmit={handleSubmit}
            />
          </Slate>
        </BarContent>
        {suffixContent && <RightContent>{suffixContent}</RightContent>}
      </Wrapper>
    );
  }
);

export default ChatSlateInputField;
