import React from 'react';
import styled from 'styled-components';

interface IProps {
  title: string;
}

const Wrapper = styled.div`
  margin: 24px 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const Title = styled.div`
  color: ${(p) => p.theme.colors.gray['800']};
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 8px;
`;

const DetailsSection: React.FC<IProps> = React.memo(({ title, children }) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      {children}
    </Wrapper>
  );
});

export default DetailsSection;
