import { gql } from 'apollo-boost';

export default gql`
  fragment attachment on Attachment {
    mimeType
    originalFileName
    path
    id
    createdAt
    info {
      width
      height
    }
  }
`;
