import React from 'react';

import { Avatar, Box, Stack, Text } from '@chakra-ui/core';

import { IUser } from '../../../global';
import getAvatarImageUrl from '../../utils/getAvatarImageUrl';
import getUserName from '../../utils/user/getUserName';
import ListItem, { IListItemProps } from '../ListItem';
import UserPopover from '../UserPopover';

interface IProps extends IListItemProps {
  user: IUser;
  small?: boolean;
  usePopover?: boolean;
}

const UserListItem: React.FC<IProps> = React.memo(
  ({ user, small, usePopover, ...listItemProps }) => {
    const name = getUserName(user);

    const content = (
      <Stack alignItems="center" direction="row" spacing="4">
        <Avatar
          src={user.avatarUrl && getAvatarImageUrl(user.avatarUrl, true)}
          size={small ? 'xs' : 'sm'}
          name={name}
        />
        <Box>
          <Text
            color="gray.900"
            fontWeight="500"
            lineHeight="1.4"
            fontSize="sm"
            whiteSpace="nowrap"
            overflow="hidden"
            style={{ textOverflow: 'ellipsis' }}
          >
            {name}
          </Text>
          {(user.phone || user.email) && (
            <Text fontSize="sm" mt="1" color="gray.500">
              {user.phone || user.email}
            </Text>
          )}
        </Box>
      </Stack>
    );

    return (
      <ListItem small={small} {...listItemProps}>
        {usePopover ? (
          <UserPopover userId={user.id}>{content}</UserPopover>
        ) : (
          content
        )}
      </ListItem>
    );
  }
);

export default UserListItem;
