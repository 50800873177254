import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@chakra-ui/core';

import useModalState from '../../../../../../shared/hooks/useModalState';
import UpdateLocationModal from './UpdateLocationModal';

interface IProps {
  chatId: string;
}

const EmptyState: React.FC<IProps> = ({ chatId }) => {
  const modalState = useModalState();
  const { t } = useTranslation();

  return (
    <>
      <UpdateLocationModal
        chatId={chatId}
        onClose={modalState.close}
        open={modalState.isOpen}
      />
      <Button
        size="sm"
        variantColor="teal"
        onClick={modalState.open}
        isFullWidth
      >
        {t('generalAddLocation')}
      </Button>
    </>
  );
};

export default EmptyState;
