import dayjs from 'dayjs';

interface IOptions {
  noYear?: boolean;
}

const formatDate = (
  date: string,
  locale: string = 'de',
  options?: IOptions
) => {
  let format = options && options.noYear ? 'MM-DD' : 'YYYY-MM-DD';

  if (locale.indexOf('de') > -1) {
    format = options && options.noYear ? 'DD.MM' : 'DD.MM.YYYY';
  }

  return dayjs(date).format(format);
};

export default formatDate;
