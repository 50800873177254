import React from 'react';
import styled from 'styled-components';

const OuterWrapper = styled.div`
  background: rgba(0, 0, 0, 0.033);
  border-left: 4px solid rgba(0, 0, 0, 0.033);
  border-radius: 4px;
  margin: 8px 0;
  padding: 8px;
`;

const Label = styled.div`
  color: ${(p) => p.theme.colors.gray['500']};
  font-size: 12px;
  font-weight: 500;
  user-select: none;
`;

const Wrapper = styled.div`
  padding: 0 0 0 16px;
`;

const MessageEmbededdedContent: React.FC<{
  title: string;
}> = React.memo(({ title, children }) => {
  return (
    <OuterWrapper>
      <Label>{title}</Label>
      <Wrapper>{children}</Wrapper>
    </OuterWrapper>
  );
});

export default MessageEmbededdedContent;
