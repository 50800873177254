import React from 'react';
import styled from 'styled-components';

import { Box, Image, Stack } from '@chakra-ui/core';

import getFileSizeString from '../../../../utils/attachments/getFileSizeString';
import RemoveButton from '../RemoveButton';

const Wrapper = styled(Box)`
  text-overflow: ellipsis;
`;

const StackBox = styled(Box)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface IProps {
  file: File;
  imagePreview?: string;
  onRemove: (file: File) => void;
}

const FilePreview: React.FC<IProps> = ({ file, imagePreview, onRemove }) => {
  const fileSize = getFileSizeString(file.size);

  const handleRemove = React.useCallback(() => {
    onRemove(file);
  }, [file, onRemove]);

  return (
    <Wrapper
      fontSize="xs"
      p="2"
      my="1"
      borderRadius="4px"
      background="teal"
      color="white"
      overflow="hidden"
      whiteSpace="nowrap"
    >
      <Stack spacing="4" direction="row" alignItems="center">
        {imagePreview && (
          <StackBox flex="0 0 auto">
            <Image
              src={imagePreview}
              alt={file.name}
              w="64px"
              h="64px"
              objectFit="cover"
            />
          </StackBox>
        )}
        <StackBox flex="1 1 auto" overflow="hidden">
          <StackBox>{file.name}</StackBox>
          <StackBox opacity={0.75}>{fileSize}</StackBox>
        </StackBox>
        <Box flex="0 0 auto">
          <RemoveButton onRemove={handleRemove} />
        </Box>
      </Stack>
    </Wrapper>
  );
};

export default FilePreview;
