import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, PseudoBox, Tooltip } from '@chakra-ui/core';

interface IProps {
  onRemove: (...args: any) => void;
}

const RemoveButton: React.FC<IProps> = ({ onRemove }) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      label={t('generalRemoveFile')}
      aria-label={t('generalRemoveFile')}
      zIndex={1500}
    >
      <PseudoBox
        onClick={onRemove}
        color="white"
        w="24px"
        h="24px"
        d="flex"
        alignItems="center"
        justifyContent="center"
        as="button"
        transition="background-color .2s"
        aria-label={t('generalRemoveFile')}
        _hover={{
          backgroundColor: 'rgba(255, 255, 255, .1)',
        }}
        _focus={{
          backgroundColor: 'rgba(255, 255, 255, .2)',
          outline: '0',
        }}
        _active={{
          backgroundColor: 'rgba(255, 255, 255, .3)',
          outline: '0',
        }}
      >
        <Icon name="close" />
      </PseudoBox>
    </Tooltip>
  );
};

export default RemoveButton;
