import React from 'react';
import { useParams } from 'react-router-dom';

import { Box } from '@chakra-ui/core';

import FullscreenLoader from '../../../../../shared/components/FullscreenLoader';
import getMessageIdentifier from '../../../../../shared/utils/messages/getMessageIdentifier';
import MessageContainer from './MessageContainer';
import useMessages from './hooks/useMessages';

const MemorizedMessageContainer = React.memo(MessageContainer);
MemorizedMessageContainer.displayName = 'MemorizedMessageContainer';

const MessagesContainer: React.FC = () => {
  const { chatId } = useParams<{ chatId: string }>();
  const messageData = useMessages(chatId);

  if (messageData.loading && messageData.data?.messages.length === 0) {
    return <FullscreenLoader />;
  }

  return (
    <Box h="100%" display="flex" flexDir="column-reverse" overflow="auto" p="4">
      {messageData.data?.messages.map((message, i) => {
        const id = getMessageIdentifier(message);
        return <MemorizedMessageContainer key={id || i} message={message} />;
      })}
    </Box>
  );
};

export default React.memo(MessagesContainer);
