import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@chakra-ui/core';

import { ILocation } from '../../../../../../global';
import useModalState from '../../../../../../shared/hooks/useModalState';
import removeLocation from '../../../../../../shared/utils/chats/removeLocation';
import segment from '../../../../../../shared/utils/segment';
import UpdateLocationModal from './UpdateLocationModal';

const Wrapper = styled.div`
  border-radius: 8px;
  border: 1px solid #f3f3f3;
  display: block;
  overflow: hidden;
`;

const StyledLink = styled.a`
  color: inherit;
  display: block;
  text-decoration: none;
`;

const Thumbnail = styled.img`
  border-radius: 8px;
  display: block;
  height: auto;
  width: 100%;
`;

const Label = styled.div`
  border-radius: 8px;
  font-weight: 500;
  margin-top: 4px;
  padding: 8px 12px;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-top: 12px;
`;

const LocationPreview: React.FC<{
  chatId: string;
  location: ILocation;
}> = React.memo(({ chatId, location }) => {
  const { t } = useTranslation();
  const modalState = useModalState();
  const urlLocation = `https://www.google.com/maps/search/?api=1&query=%20&query_place_id=${location.placeId}`;

  const handleRemoveClick = React.useCallback(async () => {
    await removeLocation(chatId);
    segment.chat.removeLocation(chatId);
  }, [chatId]);

  const openModal = React.useCallback(() => {
    segment.chat.openLocationModal(chatId);
    modalState.open();
  }, [modalState, chatId]);

  return (
    <>
      <UpdateLocationModal
        open={modalState.isOpen}
        onClose={modalState.close}
        location={location}
        chatId={chatId}
      />
      <Wrapper>
        <StyledLink href={urlLocation} target="_blank">
          <Thumbnail
            src={location.attachment.path}
            alt={location.locationString}
          />
        </StyledLink>
        <Label>
          <StyledLink href={urlLocation} target="_blank">
            {location.locationString}
          </StyledLink>
          <ButtonWrapper>
            <Button
              size="sm"
              variantColor="teal"
              isFullWidth
              type="button"
              variant="ghost"
              onClick={openModal}
            >
              {t('generalUpdate')}
            </Button>
            <Button
              size="sm"
              variantColor="red"
              isFullWidth
              type="button"
              variant="ghost"
              onClick={handleRemoveClick}
            >
              {t('generalRemove')}
            </Button>
          </ButtonWrapper>
        </Label>
      </Wrapper>
    </>
  );
});

export default LocationPreview;
