import { gql } from 'apollo-boost';

import client from '../../graphql';

const editMessageMutation = gql`
  mutation EDIT_MESSAGE($messageId: String!, $text: String!) {
    editMessage(editMessageId: $messageId, text: $text) {
      id
    }
  }
`;

export default async (id: string, text: string) => {
  return await client.mutate({
    mutation: editMessageMutation,
    variables: {
      messageId: id,
      text,
    },
  });
};
