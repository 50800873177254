import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
} from '@chakra-ui/core';

import TowioConnectionButton from '../../../../../../../shared/components/TowioConnectionButton';
import AuthContext from '../../../../../../../shared/context/AuthContext';
import client from '../../../../../../../shared/graphql';
import CHANGE_NAME_MUTATION from '../../../../../../../shared/graphql/mutations/changeName.mutation';

const nameRegex = /^[A-Za-z ]{1,32}$/;

const validationSchema = object().shape({
  firstName: string()
    .required('is required')
    .matches(nameRegex, 'must be a valid name'),
  lastName: string()
    .required('is required')
    .matches(nameRegex, 'must be a valid name'),
});

interface IFormValues {
  firstName: string;
  lastName: string;
}

const ProfileSetupForm: React.FC<{
  onLogout: () => void;
}> = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const {
    actions: { refetchUser },
  } = React.useContext(AuthContext);
  const { onLogout } = props;

  const onSubmit = React.useCallback(
    async (values: IFormValues) => {
      setLoading(true);

      try {
        const res = await client.mutate<{ changeName: boolean }>({
          mutation: CHANGE_NAME_MUTATION,
          variables: {
            firstName: values.firstName,
            lastName: values.lastName,
          },
        });

        if (!!res.data?.changeName) {
          refetchUser();
        }
      } catch {
        setLoading(false);
      }
    },
    [setLoading, refetchUser]
  );

  const {
    values,
    touched,
    errors,
    isValid,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik<IFormValues>({
    initialValues: { firstName: '', lastName: '' },
    onSubmit,
    validateOnMount: true,
    validationSchema,
  });

  return (
    <Box as="form" onSubmit={handleSubmit} mt="10" maxW="600px" mx="auto">
      <FormControl
        isInvalid={!!touched.firstName && !!errors.firstName}
        isRequired
        my="8"
      >
        <FormLabel htmlFor="firstName">{t('generalFirstName')}</FormLabel>
        <Input
          value={values.firstName}
          isDisabled={loading}
          isFullWidth
          isRequired
          name="firstName"
          id="firstName"
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder="John"
        />
        {!!touched.firstName && !!errors.firstName && (
          <FormHelperText color="red.500">{errors.firstName}</FormHelperText>
        )}
      </FormControl>
      <FormControl
        isInvalid={!!touched.firstName && !!errors.firstName}
        isRequired
        my="8"
      >
        <FormLabel htmlFor="lastName">{t('generalLastName')}</FormLabel>
        <Input
          value={values.lastName}
          isDisabled={loading}
          isFullWidth
          isRequired
          name="lastName"
          id="lastName"
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder="Doe"
        />
        {!!touched.lastName && !!errors.lastName && (
          <FormHelperText color="red.500">{errors.lastName}</FormHelperText>
        )}
      </FormControl>
      <Box
        mt="6"
        d={['block', 'flex']}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box order={[0, 1]}>
          <Button
            variantColor="teal"
            isFullWidth
            isDisabled={!isValid || loading}
            isLoading={loading}
            type="submit"
          >
            {t('generalContinue')}
          </Button>
        </Box>
        <Box mr={['0', '24px']} mt={['8px', '0']}>
          <Button
            variantColor="red"
            variant="ghost"
            isFullWidth
            onClick={onLogout}
          >
            {t('generalSignOut')}
          </Button>
        </Box>
      </Box>
      <Box mt="8">
        <TowioConnectionButton />
      </Box>
    </Box>
  );
};

export default ProfileSetupForm;
