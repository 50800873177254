import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/core';

import ChatMessageItem from '../../../../../../../shared/components/ChatMessageItem';
import DetailsSection from '../../../../../../../shared/components/DetailsSection';
import segment from '../../../../../../../shared/utils/segment';
import ReadBy from './ReadBy';
import SendAt from './SendAt';
import { MessageContext } from '..';

const MessageDetails: React.FC = () => {
  const { t } = useTranslation();
  const { showDetails, setShowDetails, message } = React.useContext(
    MessageContext
  );
  const messageId = message?.id;

  React.useEffect(() => {
    if (messageId) {
      segment.message.openDetails(messageId);
    }
  }, [messageId]);

  const handleClose = React.useCallback(() => {
    setShowDetails(false);
  }, [setShowDetails]);

  if (!message) {
    return null;
  }

  return (
    <Modal isOpen={showDetails} onClose={handleClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>{t('chatMessageDetails')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {showDetails && message.fromUser && (
              <>
                <Box mb="4">
                  <ChatMessageItem
                    attachments={message.attachments}
                    firstName={message.fromUser?.firstName || ''}
                    time={message.createdAt || ''}
                    avatarUrl={message.fromUser?.avatarUrl}
                    lastName={message.fromUser?.lastName}
                    text={message.text}
                    userId={message.fromUser.id}
                    forwardedMessage={message.forwardedMessage}
                    replyTo={message.replyMessage}
                  />
                </Box>
                <Divider />
                <Box my="4">
                  <DetailsSection title={t('generalDate')}>
                    <SendAt date={message.createdAt || ''} />
                  </DetailsSection>
                </Box>
                <Divider />
                <Box my="4">
                  <DetailsSection title={t('generalReadBy')}>
                    <ReadBy />
                  </DetailsSection>
                </Box>
                <Divider />
              </>
            )}
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default MessageDetails;
