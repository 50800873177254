import { gql } from 'apollo-boost';

export interface IChatFragment {
  __typename: 'Chat';
  id: string;
  name?: string;
  avatarUrl?: string;
  unreadCount?: number;
  lastMessage?: {
    text?: string;
    fromUser: {
      firstName: string;
      lastName: string;
    };
    createdAt: string;
  };
  withUser?: {
    id: string;
    firstName: string;
    lastName: string;
    avatarUrl?: string;
  };
}

export default gql`
  fragment chat on Chat {
    id
    name
    avatarUrl
    unreadCount
    lastMessage {
      text
      fromUser {
        firstName
        lastName
      }
      createdAt
    }
    withUser {
      id
      firstName
      lastName
      avatarUrl
    }
  }
`;
