import React from 'react';

import { Avatar, Box, Text } from '@chakra-ui/core';

import { IUser } from '../../../../global';
import getAvatarImageUrl from '../../../utils/getAvatarImageUrl';
import getUserName from '../../../utils/user/getUserName';

interface IProps {
  executor: IUser;
  target: IUser;
  icon?: JSX.Element;
}

const UserWithUserEvent: React.FC<IProps> = React.memo(
  ({ executor, target, icon, children }) => {
    const executorName = getUserName(executor);
    const targetName = getUserName(target);

    return (
      <Box
        d="flex"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box d="flex" alignItems="center">
          <Box flex="0 0 auto">
            <Avatar
              src={executor.avatarUrl && getAvatarImageUrl(executor.avatarUrl)}
              size="sm"
              name={executorName}
            />
          </Box>
          <Box
            alignItems="center"
            backgroundColor="white"
            borderRadius="50%"
            d="flex"
            h="24px"
            justifyContent="center"
            mx="2"
            position="relative"
            w="24px"
            zIndex={10}
          >
            {icon}
          </Box>
          <Box flex="0 0 auto">
            <Avatar
              src={target.avatarUrl && getAvatarImageUrl(target.avatarUrl)}
              size="sm"
              name={targetName}
            />
          </Box>
        </Box>
        <Box mt="2">
          <Text fontSize="sm" color="gray.500">
            {children}
          </Text>
        </Box>
      </Box>
    );
  }
);

export default UserWithUserEvent;
