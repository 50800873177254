import styled from 'styled-components';

const SecondarySidebar = styled.div`
  background: ${(p) => p.theme.colors.white};
  border-left: 1px solid ${(p) => p.theme.colors.gray['100']};
  display: flex;
  flex-direction: column;
  width: 480px;

  @media screen and (max-width: 1200px) {
    width: 320px;
  }
`;

export default SecondarySidebar;
