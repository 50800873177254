import { gql } from 'apollo-boost';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ModalBody, ModalFooter, useToast } from '@chakra-ui/core';

import { IUser } from '../../../../../../global';
import ContactPicker from '../../../../../../shared/components/ContactPicker';
import { ChatContext } from '../../../../../../shared/context/ChatContext';
import client from '../../../../../../shared/graphql';
import segment from '../../../../../../shared/utils/segment';

const addParticipantsMutation = gql`
  mutation ADD_PARTICIPANTS($userIds: [String]!, $chatId: String!) {
    addUserToGroupChat(userIds: $userIds, chatId: $chatId)
  }
`;

const AddParticipantsContent: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  const { t } = useTranslation();
  const { chat } = React.useContext(ChatContext);
  const [participants, setParticipants] = React.useState<IUser[]>([]);
  const toast = useToast();

  const handleUpdate = React.useCallback(
    (users: IUser[]) => {
      setParticipants(users);
    },
    [setParticipants]
  );

  const filterFunction = React.useCallback(
    (user: IUser) => {
      return !chat?.participants?.find(
        (currentUser) => currentUser.id === user.id
      );
    },
    [chat]
  );

  const addParticipants = React.useCallback(async () => {
    onClose();
    try {
      await client.mutate({
        mutation: addParticipantsMutation,
        variables: {
          userIds: participants.map((u) => u.id),
          chatId: chat?.id,
        },
      });
      if (chat) {
        participants.forEach((u) => segment.chat.inviteUser(chat.id, u.id));
      }
      toast({
        title: t('chatParticipantsAddedTitle'),
        description: t('chatParticipantsAddedDescription', {
          count: participants.length,
        }),
        duration: 2000,
        status: 'success',
      });
    } catch (e) {
      console.error(e);
      toast({
        title: t('errorsGeneral'),
        duration: 2000,
        status: 'error',
      });
    }
  }, [chat, t, participants, onClose, toast]);

  return (
    <>
      <ModalBody>
        <ContactPicker
          multiple
          filter={filterFunction}
          onChange={handleUpdate}
          value={participants}
        />
      </ModalBody>
      <ModalFooter>
        <Button size="sm" variantColor="teal" onClick={addParticipants}>
          {t('chatAddParticipants')}
        </Button>
      </ModalFooter>
    </>
  );
};

export default AddParticipantsContent;
