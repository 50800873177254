import React from 'react';
import { useTranslation } from 'react-i18next';

import { useLazyQuery } from '@apollo/react-hooks';
import { Box, Spinner } from '@chakra-ui/core';

import getPrivateChatsQuery from '../../../components/MainApplication/Sidebar/PrivateChatList/getPrivateChats.query';
import { IChat } from '../../../global';
import searchChat from '../../utils/chats/searchChat';
import sortChatsAlphabetically from '../../utils/chats/sortChatsAlphabetically';
import SearchField from '../SearchField';
import ChatPickerItem from './ChatPickerItem';

interface IProps {
  multiple?: boolean;
  onChange: (newValue: IChat[]) => void;
  value: IChat[];
  filter?: (chat: IChat) => boolean;
}

const MemorizedItem = React.memo(ChatPickerItem);

const ChatPicker: React.FC<IProps> = ({
  filter,
  onChange,
  multiple,
  value,
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = React.useState('');
  const [fetchChats, { data, loading }] = useLazyQuery<{
    chats: IChat[];
  }>(getPrivateChatsQuery, {
    variables: { type: 'accepted' },
    fetchPolicy: 'cache-and-network',
  });

  React.useEffect(() => {
    fetchChats();
  }, [fetchChats]);

  const updateSearch = React.useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setSearch(e.currentTarget.value);
    },
    [setSearch]
  );

  const resetSearch = React.useCallback(() => {
    setSearch('');
  }, [setSearch]);

  const handleChatUpdate = React.useCallback(
    (chat: IChat, checked?: boolean) => {
      if (!multiple) {
        onChange([chat]);
      } else {
        if (checked) {
          onChange([...value, chat]);
        } else {
          onChange([...value].filter((c) => c.id !== chat.id));
        }
      }
    },
    [value, multiple, onChange]
  );

  let filteredChats = searchChat(search, data?.chats || []).sort(
    sortChatsAlphabetically
  );

  if (filter) {
    filteredChats = filteredChats.filter(filter);
  }

  return (
    <div>
      <div>
        <SearchField
          value={search}
          onChange={updateSearch}
          onReset={resetSearch}
          placeholder={t('generalSearch')}
        />
      </div>
      <Box py="4">
        <Box maxH="400px" overflow="auto">
          {loading && !data?.chats ? (
            <>
              <Spinner />
            </>
          ) : (
            <div>
              {filteredChats?.map((chat) => (
                <MemorizedItem
                  onChange={handleChatUpdate}
                  chat={chat}
                  key={chat.id}
                  checked={value.findIndex((c) => c.id === chat.id) !== -1}
                  multiple={multiple}
                />
              ))}
            </div>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default ChatPicker;
