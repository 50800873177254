import React from 'react';

import { Box, Spinner } from '@chakra-ui/core';

import { IFriend } from '../../../../global';
import ContactItem from '../../../../shared/components/ContactItem';
import ListLink, {
  ListLinkContent,
} from '../../../../shared/components/ListLink';
import SearchField from '../../../../shared/components/SearchField';
import useContacts from '../../../../shared/hooks/useContacts';
import useSearch from '../../../../shared/hooks/useSearch';
import routes from '../../../../shared/utils/routes';
import getUserName from '../../../../shared/utils/user/getUserName';
import searchContact from '../../../../shared/utils/user/searchContact';
import { ModalContainer } from './Modal';

const ContactList: React.FC<{ contacts: IFriend[] }> = React.memo((props) => {
  const { contacts } = props;
  return (
    <>
      {contacts.map((contact) => (
        <ListLink
          to={routes.user.PRIVATE_CONTACT.replace(
            ':contactId',
            contact.user.id
          )}
        >
          <ListLinkContent>
            <ContactItem user={contact.user} key={contact.user.id} />
          </ListLinkContent>
        </ListLink>
      ))}
    </>
  );
});

export const ContactsSidebarContext = React.createContext<{
  refetchContacts: () => void;
}>({
  refetchContacts: () => null,
});

const ContactSidebar: React.FC = () => {
  const search = useSearch();
  const contacts = useContacts();

  if (contacts.loading && !contacts.data) {
    return (
      <Box
        alignContent="center"
        d="flex"
        justifyContent="center"
        minH="220px"
        p="4"
      >
        <Spinner size="sm" />
      </Box>
    );
  }

  if (!contacts.data) {
    return null;
  }

  const filteredContacts = searchContact(contacts.data.friends, search.q);
  const sortedContacts = filteredContacts.sort((contactA, contactB) => {
    const nameA = getUserName(contactA.user);
    const nameB = getUserName(contactB.user);
    if (!nameA || !nameB) {
      return -1;
    }
    return nameA.localeCompare(nameB);
  });

  return (
    <ContactsSidebarContext.Provider
      value={{ refetchContacts: contacts.refetch }}
    >
      <Box d="flex" flexDirection="column" h="100%">
        <Box px="4" py="2" flex="0 0 auto">
          <SearchField
            value={search.q}
            onChange={search.handleChange}
            onReset={search.handleClear}
          />
        </Box>
        <Box flex="1 1 auto" overflow="auto">
          <ContactList contacts={sortedContacts} />
        </Box>
        <Box px="4" py="4" flex="0 0 auto">
          <ModalContainer />
        </Box>
      </Box>
    </ContactsSidebarContext.Provider>
  );
};

export default React.memo(ContactSidebar);
