import { gql } from 'apollo-boost';

import attachmentFragment from './attachment.fragment';

export default gql`
  ${attachmentFragment}

  fragment location on Location {
    attachment {
      ...attachment
    }
    lat
    lng
    locationString
    placeId
    plusCode
  }
`;
