import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { PseudoBox } from '@chakra-ui/core';

const InnerWrapper = styled(PseudoBox)`
  align-items: center;
  border-radius: 8px;
  display: flex;
  height: 48px;
  justify-content: center;
  position: relative;
  width: 48px;
`;

const ButtonWrapper = styled(NavLink)`
  align-items: center;
  color: ${(p) => p.theme.colors.gray['500']};
  display: flex;
  height: 80px;
  justify-content: center;
  width: 80px;

  &:hover,
  &.active {
    color: ${(p) => p.theme.colors.gray['600']};

    ${InnerWrapper} {
      background: ${(p) => p.theme.colors.gray['100']};
      color: ${(p) => p.theme.colors.gray['600']};
    }
  }
`;

interface IProps {
  className?: string;
  messageCount?: number;
  to: string;
}

const AppBarTeam: React.FC<IProps> = React.memo((props) => {
  const { children, to } = props;

  return (
    <ButtonWrapper to={to}>
      <InnerWrapper>{children}</InnerWrapper>
    </ButtonWrapper>
  );
});

export default AppBarTeam;
