import React from 'react';

const getDrafts = () => {
  const drafts = localStorage.getItem('messageDrafts');
  if (drafts) {
    return JSON.parse(drafts);
  }
  return undefined;
};

const getDraft = (chatId: string) => {
  const drafts = getDrafts();
  if (drafts && drafts[chatId]) {
    return drafts[chatId];
  } else {
    return '';
  }
};

const updateDraftData = (chatId: string, value: string) => {
  let drafts = getDrafts();
  if (drafts) {
    drafts[chatId] = value;
  } else {
    drafts = {};
    drafts[chatId] = value;
  }
  const serialized = JSON.stringify(drafts);
  localStorage.setItem('messageDrafts', serialized);
};

const removeDraftData = (chatId: string) => {
  let drafts = getDrafts();
  if (drafts) {
    delete drafts[chatId];
  } else {
    drafts = {};
  }
  const serialized = JSON.stringify(drafts);
  localStorage.setItem('messageDrafts', serialized);
};

const useMessageDraft = (
  chatId: string | undefined,
  defaultValue: string = ''
) => {
  const [draft, setDraft] = React.useState(defaultValue);

  React.useEffect(() => {
    const handler = (e: StorageEvent) => {
      console.log('STORAGE EVENT');
      console.log(e.key, e.newValue);
    };

    window.addEventListener('storage', handler);

    return () => {
      window.removeEventListener('storage', handler);
    };
  }, [chatId]);

  React.useEffect(() => {
    setDraft('');
    if (chatId) {
      setDraft(getDraft(chatId));
    }
  }, [chatId]);

  const updateDraft = React.useCallback(
    (newValue: string) => {
      setDraft(newValue);
      if (chatId) {
        updateDraftData(chatId, newValue);
      }
    },
    [setDraft, chatId]
  );

  const clearDraft = React.useCallback(() => {
    setDraft('');
    if (chatId) {
      removeDraftData(chatId);
    }
  }, [setDraft, chatId]);

  return { draft, updateDraft, clearDraft };
};

export default useMessageDraft;
