import { useEffect } from 'react';

import { useQuery } from '@apollo/react-hooks';

import { IChat, SubscriptionAction } from '../../../../global';
import getPrivateChatsQuery from './getPrivateChats.query';
import updatePrivateChatsSubscription from './updatePrivateChats.subscription';

interface SubscriptionResult {
  privateChats: {
    chat: IChat;
    action: SubscriptionAction;
  };
}

const useChats = () => {
  const { data, loading, subscribeToMore } = useQuery<{
    chats: IChat[];
  }>(getPrivateChatsQuery);

  useEffect(() => {
    const endSubscription = subscribeToMore<SubscriptionResult>({
      document: updatePrivateChatsSubscription,
      updateQuery: (prev, { subscriptionData }) => {
        const newChat = subscriptionData.data.privateChats.chat;
        switch (subscriptionData.data.privateChats.action) {
          case 'created':
            return Object.assign({
              chats: [...prev.chats, newChat],
            });

          case 'updated':
            if (prev.chats) {
              const index = prev.chats.findIndex(
                (chat) => chat.id === newChat.id
              );
              if (index === -1) {
                return { ...prev, ...newChat };
              }

              prev.chats = prev.chats.map((chat) => {
                if (chat.id === subscriptionData.data.privateChats.chat.id) {
                  return subscriptionData.data.privateChats.chat;
                }
                return chat;
              });
            }
            return { ...prev };

          case 'deleted':
            if (prev.chats) {
              return {
                chats: prev.chats.filter(
                  (currentChat) => currentChat.id !== newChat.id
                ),
              };
            }
            return { ...prev };

          default:
            return prev;
        }
      },
    });

    return () => {
      endSubscription();
    };
  }, [subscribeToMore]);

  return { chats: data?.chats, loading };
};

export default useChats;
