import React from 'react';
import { useHistory } from 'react-router-dom';

import { gql } from '@apollo/client';
import { useQuery } from '@apollo/react-hooks';
import { Box, Button, Image, Spinner, Stack, Text } from '@chakra-ui/core';

import { IUser } from '../../../global';
import USER_FRAGMENT from '../../graphql/fragments/USER.fragment';
import chatFragment from '../../graphql/fragments/chat.fragment';
import routes from '../../utils/routes';
import getUserName from '../../utils/user/getUserName';

interface IProps {
  userId: string;
}

const query = gql`
  ${USER_FRAGMENT}
  ${chatFragment}

  query GET_USER($id: String!) {
    user(id: $id) {
      ...user
      chat {
        ...chat
      }
    }
  }
`;

const UserProfile: React.FC<IProps> = ({ userId }) => {
  const history = useHistory();
  const { data, loading } = useQuery<{ user: IUser }>(query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: userId,
    },
  });

  const handleChatClick = React.useCallback(() => {
    if (!!data?.user && !!data?.user.chat?.id) {
      history.push(
        routes.user.PRIVATE_CHAT.replace(':chatId', data?.user.chat?.id)
      );
    }
  }, [data, history]);

  const handleProfileClick = React.useCallback(() => {
    if (!!data?.user && !!data?.user.id) {
      history.push(
        routes.user.PRIVATE_CONTACT.replace(':contactId', data?.user.id)
      );
    }
  }, [data, history]);

  return (
    <Box p="4">
      {loading && (
        <Box d="flex" alignItems="center" justifyContent="center" h="350px">
          <Spinner />
        </Box>
      )}
      {!loading && data && (
        <>
          <Stack spacing="3">
            {data.user.avatarUrl && (
              <Box>
                <Image
                  src={data.user.avatarUrl}
                  width="100%"
                  height="200px"
                  objectFit="cover"
                />
              </Box>
            )}
            <Stack spacing="3">
              <Box>
                <Text color="gray.900" fontWeight="500" fontSize="md">
                  {getUserName(data.user)}
                </Text>
                <Text color="gray.600" fontSize="sm">
                  {data.user.phone || data.user.email}
                </Text>
              </Box>
              <Box>
                <Stack spacing="2">
                  {data.user.chat && (
                    <Button
                      onClick={handleChatClick}
                      size="sm"
                      variantColor="teal"
                    >
                      Open chat
                    </Button>
                  )}
                  <Button onClick={handleProfileClick} size="sm">
                    Open profile
                  </Button>
                </Stack>
              </Box>
            </Stack>
          </Stack>
        </>
      )}
    </Box>
  );
};

export default UserProfile;
