import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@chakra-ui/core';

import DetailsSection from '../../../../../shared/components/DetailsSection';
import { ChatContext } from '../../../../../shared/context/ChatContext';
import ChatDetailsHeader from './ChatDetailsHeader';
import { MemorizedChatSidebarAttachments } from './ChatSidebarAttachments';

const DirectChatSidebar: React.FC = () => {
  const { t } = useTranslation();
  const { chat } = React.useContext(ChatContext);

  if (!chat || !chat.withUser) return null;

  return (
    <>
      <ChatDetailsHeader />
      <Box overflow="auto" flex="1 1 auto">
        {chat.withUser.phone && (
          <Box m="32px 0" p="0 24px">
            <DetailsSection title={t('generalPhone')}>
              {chat.withUser.phone}
            </DetailsSection>
          </Box>
        )}
        {chat.withUser.email && (
          <Box mx="8" py="6">
            <DetailsSection title={t('generalEmail')}>
              {chat.withUser.email}
            </DetailsSection>
          </Box>
        )}
        <Box mx="8" py="6">
          <DetailsSection title={t('generalAttachments')}>
            <MemorizedChatSidebarAttachments />
          </DetailsSection>
        </Box>
      </Box>
    </>
  );
};

export default DirectChatSidebar;
