import React from 'react';
import { useTranslation } from 'react-i18next';

import { IAttachment, IMessage } from '../../../global';
import formatTime from '../../utils/formatTime';
import getUserName from '../../utils/user/getUserName';
import ChatAttachments from '../ChatAttachments';
import ChatMessage from '../ChatMessage';
import ChatMessageHeader from '../ChatMessageHeader';
import MessageEmbededdedContent from '../MessageEmbeddedContent';

interface IProps {
  attachments?: IAttachment[];
  avatarUrl?: string;
  firstName: string;
  lastName?: string;
  text?: string;
  time: string;
  forwardedMessage?: IMessage;
  userId: string;
  replyTo?: IMessage;
}

const renderMessage = (message: IMessage) =>
  message.fromUser && (
    <ChatMessageItem
      firstName={message.fromUser?.firstName || ''}
      time={message.createdAt || ''}
      avatarUrl={message.fromUser?.avatarUrl}
      lastName={message.fromUser?.lastName}
      userId={message.fromUser.id}
      replyTo={message.replyMessage}
      text={message.text}
    />
  );

const ChatMessageItem: React.FC<IProps> = React.memo(
  ({
    attachments,
    avatarUrl,
    firstName,
    lastName,
    userId,
    text,
    time,
    forwardedMessage,
    replyTo,
  }) => {
    const { t } = useTranslation();
    const timeString = time ? formatTime(time) : '';

    return (
      <>
        <ChatMessageHeader
          firstName={firstName}
          lastName={lastName}
          avatarUrl={avatarUrl}
          messageTime={timeString}
          userId={userId}
        />
        {forwardedMessage && (
          <MessageEmbededdedContent
            title={t('chatForwardedFrom', {
              name: forwardedMessage.fromUser
                ? getUserName(forwardedMessage.fromUser)
                : undefined,
            })}
          >
            {renderMessage(forwardedMessage)}
          </MessageEmbededdedContent>
        )}
        {replyTo && (
          <MessageEmbededdedContent
            title={t('chatReplyToMessage', {
              name: replyTo.fromUser
                ? getUserName(replyTo.fromUser)
                : undefined,
            })}
          >
            {renderMessage(replyTo)}
          </MessageEmbededdedContent>
        )}
        {attachments && attachments.length > 0 && (
          <ChatAttachments attachments={attachments} />
        )}
        <ChatMessage>{text}</ChatMessage>
      </>
    );
  }
);

export default ChatMessageItem;
