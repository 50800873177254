export default (url: string | undefined, size: number) => {
  if (!url) {
    return '';
  }

  if (url.indexOf && url.indexOf('file://') !== -1) {
    return url;
  }

  const splittedUrl = url.split('/');
  splittedUrl.splice(3, 0, `resize/${size}`);

  return splittedUrl.join('/');
};
