import { ApolloQueryResult } from 'apollo-boost';
import React, { createContext } from 'react';

import { IFriend } from '../../../global';
import useFriends from './useFriends';

export const FriendsContext = createContext<{
  friends: IFriend[] | undefined;
  loading: boolean;
  refetch?: () => Promise<ApolloQueryResult<{ friends: IFriend[] }>>;
}>({
  friends: [],
  loading: false,
  refetch: undefined,
});

const FriendsProvider: React.FC = ({ children }) => {
  const { data, loading, refetch } = useFriends();

  return (
    <FriendsContext.Provider
      value={{ friends: data?.friends, loading, refetch }}
    >
      {children}
    </FriendsContext.Provider>
  );
};

export default FriendsProvider;
