import React from 'react';

import { Box, Spinner } from '@chakra-ui/core';

import { IChat } from '../../../../global';
import ChatListItem from '../../../../shared/components/ChatListItem';
import ListLink, {
  ListLinkContent,
} from '../../../../shared/components/ListLink';
import SearchField from '../../../../shared/components/SearchField';
import getChatName from '../../../../shared/utils/chats/getChatName';
import searchChat from '../../../../shared/utils/chats/searchChat';
import sortChats from '../../../../shared/utils/chats/sortChats';
import routes from '../../../../shared/utils/routes';
import CreateChat from './CreateChat';
import useChats from './useChats';

const SingleChatItem: React.FC<{ chat: IChat }> = React.memo((props) => {
  const { chat } = props;
  const name = getChatName(chat as IChat);
  const avatar =
    (chat.withUser ? chat.withUser.avatarUrl : chat.avatarUrl) || '';

  const lastAuthor =
    chat.lastMessage?.fromUser && chat.lastMessage?.fromUser?.firstName;

  return (
    <ListLink to={routes.user.PRIVATE_CHAT.replace(':chatId', chat.id)}>
      <ListLinkContent>
        <ChatListItem
          chatName={name}
          lastMessage={chat.lastMessage?.text}
          messageDate={chat.lastMessage?.createdAt}
          lastAuthor={lastAuthor}
          avatarUrl={avatar}
          unreadCount={chat.unreadCount}
        />
      </ListLinkContent>
    </ListLink>
  );
});

SingleChatItem.displayName = 'SingleChatItem';

const PrivateChatList: React.FC = () => {
  const [search, setSearch] = React.useState('');
  const chatData = useChats();
  const sortedChats = chatData.chats?.sort(sortChats) || [];
  const filteredChats = searchChat(search, sortedChats);

  const handleChange = React.useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setSearch(e.currentTarget.value);
    },
    []
  );

  const handleReset = React.useCallback(() => {
    setSearch('');
  }, []);

  return (
    <Box h="100%" display="flex" overflow="hidden" flexDirection="column">
      {!chatData.loading && (
        <>
          <Box flex="0 0 auto" py="2" px="4">
            <SearchField
              value={search}
              onChange={handleChange}
              onReset={handleReset}
            />
          </Box>
          <Box flex="1 1 auto" overflow="auto">
            {filteredChats.map((chat) => (
              <SingleChatItem key={chat.id} chat={chat} />
            ))}
          </Box>
          <Box
            borderTop="1px"
            borderTopColor="gray.200"
            flex="0 0 auto"
            py="4"
            px="4"
          >
            <CreateChat />
          </Box>
        </>
      )}

      {chatData.loading && (
        <Box
          alignContent="center"
          display="flex"
          justifyContent="center"
          minHeight="220px"
          p="16px"
        >
          <Spinner size="sm" />
        </Box>
      )}
    </Box>
  );
};

export default PrivateChatList;
