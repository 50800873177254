import React from 'react';
import { useTranslation } from 'react-i18next';
import sanitizeHtml from 'sanitize-html';
import { v1 as uuid } from 'uuid';

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/core';
import mergeFileLists from '@d2k/merge-file-lists';

import ChatAttachmentUploadModal from '../../../../../../../shared/components/ChatAttachmentUploadModal';
import ChatInputBar from '../../../../../../../shared/components/ChatInputBar';
import sendMessageMutation from '../../../../../../../shared/components/ChatInputBar/graphql/sendMessage.mutation';
import ChatMessageItem from '../../../../../../../shared/components/ChatMessageItem';
import { ChatContext } from '../../../../../../../shared/context/ChatContext';
import client from '../../../../../../../shared/graphql';
import useMessageDraft from '../../../../../../../shared/hooks/useMessageDraft';
import segment from '../../../../../../../shared/utils/segment';
import { MessageContext } from '..';

const ReplyModal: React.FC = () => {
  const { t } = useTranslation();
  const { chat } = React.useContext(ChatContext);
  const { message, replyTo, setReplyTo } = React.useContext(MessageContext);
  const { draft, updateDraft, clearDraft } = useMessageDraft(chat?.id || '');
  const [files, setFiles] = React.useState<FileList | undefined>();
  const messageId = message?.id;

  React.useEffect(() => {
    if (messageId) {
      segment.message.openReplyModal(messageId);
    }
  }, [messageId]);

  const handleClose = React.useCallback(() => {
    setReplyTo(undefined);
  }, [setReplyTo]);

  const handleSubmit = React.useCallback(async () => {
    if (chat && replyTo) {
      try {
        const id = uuid();
        setReplyTo(undefined);
        await client.mutate({
          mutation: sendMessageMutation,
          variables: {
            uuid: id,
            input: sanitizeHtml(draft, { allowedTags: [] }),
            chatId: chat.id,
            attachments: files,
            replyMessageId: replyTo?.id,
          },
        });
        if (messageId) {
          segment.message.reply(messageId);
        }
        clearDraft();
        setFiles(undefined);
      } catch (e) {
        console.error(e);
      }
    }
  }, [
    chat,
    setReplyTo,
    replyTo,
    files,
    draft,
    clearDraft,
    setFiles,
    messageId,
  ]);

  const handleFileUpdate = React.useCallback(
    (newFiles: FileList) => {
      if (files && files.length > 0) {
        setFiles(mergeFileLists(files, newFiles));
      } else {
        setFiles(newFiles);
      }
    },
    [files]
  );

  const resetFiles = React.useCallback(() => {
    setFiles(undefined);
  }, [setFiles]);

  return (
    <Modal isOpen={!!replyTo} onClose={handleClose}>
      <ModalOverlay>
        <ModalContent borderRadius="4px">
          <ModalHeader>{t('chatReplyTo')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {replyTo && replyTo.fromUser && (
              <ChatMessageItem
                attachments={replyTo.attachments}
                firstName={replyTo.fromUser?.firstName || ''}
                time={replyTo.createdAt || ''}
                avatarUrl={replyTo.fromUser?.avatarUrl}
                lastName={replyTo.fromUser?.lastName}
                userId={replyTo.fromUser.id}
                text={replyTo.text}
                forwardedMessage={message?.forwardedMessage}
                replyTo={message?.replyMessage}
              />
            )}
          </ModalBody>
          <ModalFooter d="block">
            <ChatInputBar
              value={draft}
              onChange={updateDraft}
              onFileChange={handleFileUpdate}
              onSubmit={handleSubmit}
            />
            <Modal isOpen={files && files.length > 0} onClose={resetFiles}>
              <ModalOverlay>
                {files && !!files.length && (
                  <ChatAttachmentUploadModal
                    value={draft}
                    files={files}
                    onChange={updateDraft}
                    onFileChange={handleFileUpdate}
                    onFileUpdate={setFiles}
                    onSubmit={handleSubmit}
                  />
                )}
              </ModalOverlay>
            </Modal>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default ReplyModal;
