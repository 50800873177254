import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Divider } from '@chakra-ui/core';

import TowioConnectionButton from '../../../../shared/components/TowioConnectionButton';
import AuthContext from '../../../../shared/context/AuthContext';
import useCurrentUser from '../../../../shared/hooks/useCurrentUser';
import canSeeDevFeature from '../../../../shared/utils/canSeeDevFeature';

const SettingsView: React.FC = () => {
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const {
    actions: { logout },
  } = React.useContext(AuthContext);

  return (
    <Box h="100%" d="flex" overflow="hidden" flexDirection="column">
      <Box flex="1 1 auto" p="16px">
        {/**<ProfileSettings />*/}
        {canSeeDevFeature(currentUser) && (
          <Button color="gray.400" isDisabled variant="ghost" isFullWidth>
            {t('settingsNotification')}
          </Button>
        )}
      </Box>
      <Divider />
      <Box flex="0 0 auto" p="16px">
        <TowioConnectionButton />
      </Box>
      <Divider />
      <Box flex="1 1 auto" p="16px">
        <Box mb="2">
          <Button
            as="a"
            color="gray.400"
            variant="ghost"
            size="sm"
            isFullWidth
            {...{
              href: 'https://zip.app/terms',
              target: '_blank',
              rel: 'nofollow noreferrer',
            }}
          >
            {t('generalTerms')}
          </Button>
          <Button
            as="a"
            color="gray.400"
            variant="ghost"
            size="sm"
            isFullWidth
            {...{
              href: 'https://zip.app/privacy',
              target: '_blank',
              rel: 'nofollow noreferrer',
            }}
          >
            {t('generalDataSafety')}
          </Button>
          <Button
            as="a"
            color="gray.400"
            variant="ghost"
            size="sm"
            isFullWidth
            {...{
              href: 'https://zip.app/imprint',
              target: '_blank',
              rel: 'nofollow noreferrer',
            }}
          >
            {t('generalImprint')}
          </Button>
        </Box>
        <Button onClick={logout} variantColor="red" variant="ghost" isFullWidth>
          {t('generalSignOut')}
        </Button>
      </Box>
    </Box>
  );
};

export default SettingsView;
