import React from 'react';
import { useTranslation } from 'react-i18next';

import LoginScreenHeader from '../../../../../../shared/components/LoginScreenHeader';
import AuthContext from '../../../../../../shared/context/AuthContext';
import ProfileSetupForm from './ProfileSetupForm';

const ProfileSetupView: React.FC = () => {
  const { t } = useTranslation();
  const {
    actions: { logout },
  } = React.useContext(AuthContext);

  return (
    <>
      <LoginScreenHeader
        title={t('registrationTitle')}
        subtitle={t('registrationSubtitle')}
      />
      <ProfileSetupForm onLogout={logout} />
    </>
  );
};

export default ProfileSetupView;
