import React from 'react';

import { Button } from '@chakra-ui/core';

import segment from '../../../../shared/utils/segment';
import AddContactModal from './AddContactModal';
import { ContactsSidebarContext } from '.';

export const ModalContainer: React.FC = () => {
  const { refetchContacts } = React.useContext(ContactsSidebarContext);
  const [modal, setModal] = React.useState(false);

  const openModal = React.useCallback(() => {
    segment.contact.openAdd();
    setModal(true);
  }, [setModal]);

  const closeModal = React.useCallback(() => {
    segment.contact.closeAdd();
    setModal(false);
    refetchContacts();
  }, [setModal, refetchContacts]);

  return (
    <>
      <AddContactModal close={closeModal} open={modal} />
      <Button
        size="sm"
        color="gray.500"
        leftIcon="add"
        variant="ghost"
        isFullWidth
        onClick={openModal}
      >
        Add contact
      </Button>
    </>
  );
};
