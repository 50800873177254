import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/core';

import ChatInputBar from '../ChatInputBar';
import FileList from './components/FileList';

interface IProps {
  files: FileList;
  onChange: (newString: string) => void;
  onFileChange: (newFiles: FileList) => void;
  onFileUpdate: (newFiles: FileList) => void;
  onSubmit: (e?: React.FormEvent<HTMLFormElement>) => any;
  value: string;
}

const ChatAttachmentUploadModal: React.FC<IProps> = React.memo(
  ({ files, value, onChange, onFileChange, onSubmit, onFileUpdate }) => {
    const { t } = useTranslation();
    const filesArray = React.useMemo(() => Array.from(files), [files]);

    const handleFileRemove = React.useCallback(
      (file: File) => {
        const newFilesArray = filesArray.filter(
          (currentFile) =>
            currentFile.name !== file.name && currentFile.size !== file.size
        );
        const dataTransfer = new DataTransfer();

        for (let i = 0; i < newFilesArray.length; i++) {
          dataTransfer.items.add(newFilesArray[i]);
        }

        const newFileList = dataTransfer.files;
        onFileUpdate(newFileList);
      },
      [filesArray, onFileUpdate]
    );

    return (
      <ModalContent>
        <ModalHeader>{t('Send attachments')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody maxH="500px" overflow="auto">
          <FileList onRemove={handleFileRemove} files={filesArray} />
        </ModalBody>
        <ModalFooter display="block">
          <ChatInputBar
            hasAttachment={!!filesArray?.length}
            onChange={onChange}
            onFileChange={onFileChange}
            onSubmit={onSubmit}
            value={value}
          />
        </ModalFooter>
      </ModalContent>
    );
  }
);

export default ChatAttachmentUploadModal;
