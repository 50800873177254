import { gql } from 'apollo-boost';

import { useQuery } from '@apollo/react-hooks';

import client from '../graphql';

const query = gql`
  query ACCOUNT_CONNECTIONS {
    accountConnections(redirectUri: "${process.env.REACT_APP_URL}") {
      name
      active
      url
    }
  }
`;

export interface AccountConnection {
  name: string;
  active: boolean;
  url: string;
}

export const useAccountConnections = () => {
  return useQuery<{ accountConnections: AccountConnection[] }>(query, {
    fetchPolicy: 'cache-and-network',
  });
};

export default async () => {
  try {
    const res = await client.query<{ accountConnections: AccountConnection[] }>(
      {
        query,
      }
    );
    return res.data.accountConnections;
  } catch (e) {
    throw new Error(e);
  }
};
