import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';

import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
} from '@chakra-ui/core';

import { ChatContext } from '../../../../../../shared/context/ChatContext';
import { useChatDetails } from '../../../../../../shared/context/ChatDetailsContext';
import renameChat from '../../../../../../shared/utils/chats/renameChat';
import segment from '../../../../../../shared/utils/segment';

interface IFormValues {
  name: string;
}

const validationSchema = object().shape({
  name: string().trim().required('Field is required'),
});

const RenameChatForm: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const { chat, refetchChat } = React.useContext(ChatContext);
  const {
    rename: { close },
  } = useChatDetails();

  const {
    isValid,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
  } = useFormik<IFormValues>({
    initialValues: { name: '' },
    onSubmit: async (values) => {
      if (!chat) {
        return;
      }

      setLoading(true);
      try {
        await renameChat(chat.id, values.name.trim());
        segment.chat.changeTitle(chat.id, values.name.trim());
        if (refetchChat) {
          refetchChat({ id: chat.id });
        }
      } catch (e) {
        console.error(e);
      }
      close();
    },
    validationSchema,
  });

  React.useEffect(() => {
    if (chat?.name) {
      setFieldValue('name', chat.name);
    }
  }, [chat, setFieldValue]);

  return (
    <form onSubmit={handleSubmit}>
      <FormControl isInvalid={!!errors.name} isRequired>
        <FormLabel htmlFor="name">{t('chatNewTitle')}</FormLabel>
        <Input
          type="text"
          name="name"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
          placeholder={chat?.name}
          autoFocus
        />
        {!!errors.name && (
          <FormHelperText color="red.500">{errors.name}</FormHelperText>
        )}
      </FormControl>
      <Box my="4">
        <ButtonGroup justifyContent="flex-end" alignItems="center">
          <Button variant="ghost" size="sm" type="button" onClick={close}>
            {t('generalCancel')}
          </Button>
          <Button
            isDisabled={!isValid || loading}
            isLoading={loading}
            variantColor="teal"
            size="sm"
            type="submit"
          >
            {t('generalConfirm')}
          </Button>
        </ButtonGroup>
      </Box>
    </form>
  );
};

export default RenameChatForm;
