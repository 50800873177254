import Linkify from 'linkifyjs/react';
import React from 'react';

import { Text } from '@chakra-ui/core';

import { IAttachment } from '../../../../../../../global';
import ChatAttachments from '../../../../../../../shared/components/ChatAttachments';

interface IProps {
  text?: string;
  translationText?: string;
  attachments: IAttachment[];
}

const MessageContent: React.FC<IProps> = ({
  text,
  translationText,
  attachments,
}) => {
  return (
    <div>
      {attachments.length > 0 && (
        <div>
          <ChatAttachments attachments={attachments} />
        </div>
      )}
      {text && text.length > 0 && (
        <Linkify
          options={{
            target: '_blank',
            attributes: { rel: 'nofollow noreferrer' },
          }}
        >
          <Text
            lineHeight="2"
            color={translationText ? 'gray.400' : 'gray.900'}
            fontSize="sm"
            dangerouslySetInnerHTML={{ __html: text }}
          ></Text>
        </Linkify>
      )}
      {translationText && translationText.length > 0 && (
        <Linkify
          options={{
            target: '_blank',
            attributes: { rel: 'nofollow noreferrer' },
          }}
        >
          <Text
            lineHeight="2"
            color="gray.900"
            fontSize="sm"
            dangerouslySetInnerHTML={{ __html: translationText }}
          ></Text>
        </Linkify>
      )}
    </div>
  );
};

export default MessageContent;
