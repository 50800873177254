import { ApolloQueryResult } from 'apollo-boost';
import React from 'react';

import { IChat } from '../../../global';

export const ChatContext = React.createContext<{
  chat?: IChat;
  refetchChat?: (
    variables?: Record<string, any> | undefined
  ) => Promise<ApolloQueryResult<{ chat: IChat }>>;
}>({});
