import { gql } from 'apollo-boost';

import { useQuery } from '@apollo/react-hooks';

import friendFragment, { IFriend } from '../graphql/fragments/friend.fragment';

const query = gql`
  ${friendFragment}

  query GET_CONTACTS($type: FriendStatusType) {
    friends(type: $type) {
      ...friend
    }
  }
`;

export default (
  type: 'accepted' | 'pending' | 'blocked' | 'request' = 'accepted'
) => {
  return useQuery<{ friends: IFriend[] }>(query, {
    variables: { type },
    fetchPolicy: 'cache-and-network',
  });
};
