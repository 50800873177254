import { gql } from 'apollo-boost';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { MenuItem, useToast } from '@chakra-ui/core';

import { ChatContext } from '../../../../../../shared/context/ChatContext';
import client from '../../../../../../shared/graphql';
import segment from '../../../../../../shared/utils/segment';

const removeMutation = gql`
  mutation REMOVE_USER($chatId: String!, $userId: String!) {
    removeUserFromGroupChat(chatId: $chatId, userId: $userId)
  }
`;

const RemoveUserButton: React.FC<{
  userId: string;
}> = ({ userId }) => {
  const { chat } = React.useContext(ChatContext);
  const { t } = useTranslation();
  const toast = useToast();

  const handleClick = React.useCallback(async () => {
    try {
      await client.mutate({
        mutation: removeMutation,
        variables: {
          chatId: chat?.id,
          userId,
        },
      });
      if (chat) {
        segment.chat.removeUser(chat.id, userId);
      }
      toast({
        duration: 2000,
        title: t('chatParticipantRemoved'),
        status: 'success',
      });
    } catch (e) {
      console.error(e);
      toast({
        duration: 2000,
        title: t('errorsGeneralError'),
        status: 'error',
      });
    }
  }, [chat, t, toast, userId]);

  return <MenuItem onClick={handleClick}>{t('chatRemoveUser')}</MenuItem>;
};

export default RemoveUserButton;
