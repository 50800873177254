import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Icon, Text } from '@chakra-ui/core';

import { useAccountConnections } from '../../utils/getAccountConnections';
import segment from '../../utils/segment';

const TowioConnectionButton: React.FC<{
  onClick?: (...args: any) => any;
}> = ({ onClick }) => {
  const { t } = useTranslation();
  const accountConnections = useAccountConnections();

  const edooliData = React.useMemo(() => {
    if (accountConnections.data) {
      const data = accountConnections.data.accountConnections.find(
        (connection) => {
          return connection.name === 'edooli';
        }
      );
      return data;
    }
  }, [accountConnections]);

  const handleClick = React.useCallback(() => {
    if (!edooliData) {
      return;
    }

    if (onClick) {
      segment.settings.connectEdooli();
      onClick();
    }

    window.location.href = edooliData.url;
  }, [onClick, edooliData]);

  if (edooliData?.active) {
    return (
      <Box
        backgroundColor="gray.50"
        borderRadius="4px"
        color="teal.700"
        display="flex"
        alignItems="center"
        justifyContent="center"
        p="4"
      >
        <Icon name="user" size="20px" mr="1" />
        <Text fontWeight={500} fontSize="sm" userSelect="none">
          {t('connectionsVerifiedTowioUser')}
        </Text>
      </Box>
    );
  }

  return (
    <Button
      isDisabled={!accountConnections.data || !edooliData}
      isFullWidth
      isLoading={accountConnections.loading}
      onClick={handleClick}
      variant="ghost"
      variantColor="teal"
    >
      {t('connectionConnectTowioAccount')}
    </Button>
  );
};

export default TowioConnectionButton;
