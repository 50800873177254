import React from 'react';
import styled from 'styled-components';

import { Box, Stack } from '@chakra-ui/core';

import { IMessage, IMessageUnion } from '../../../../../../global';
import ChatDateLabel from '../../../../../../shared/components/ChatDateLabel';
import ChatMessageHeader from '../../../../../../shared/components/ChatMessageHeader';
import formatTime from '../../../../../../shared/utils/formatTime';
import getAvatarImageUrl from '../../../../../../shared/utils/getAvatarImageUrl';
import isDateLabel from '../../../../../../shared/utils/typeguards/isDateLabel';
import isEvent from '../../../../../../shared/utils/typeguards/isEvent';
import isMessage from '../../../../../../shared/utils/typeguards/isMessage';
import MessageContentContainer from './MessageContentContainer';
import MessageDetails from './MessageDetails';
import MessageDropdown from './MessageDropdown';
import MessageEditMask from './MessageEditMask';
import MessageForwardModal from './MessageForwardModal';
import ReplyModal from './ReplyModal';
import renderEvent from './renderEvent';

const Time = styled.div`
  display: none;
  font-size: 12px;
  line-height: 24px;
  opacity: 0.75;
  padding-left: 2px;
`;

const ButtonWrapper = styled.div`
  opacity: 0;
  position: absolute;
  right: 8px;
  top: 0;
  transform: translateY(-50%) scale(0.9);
  transition: transform 0.2s, opacity 0.2s;
  visibility: hidden;
`;

const SingleMessageWrapper = styled.div<{ first?: boolean; last?: boolean }>`
  background: transparent;
  margin-bottom: ${(p) => (p.last ? '8px' : 0)};
  margin-top: ${(p) => (p.first ? '8px' : 0)};
  position: relative;
  transition: background 0.16s;
  z-index: 0;

  &:hover {
    background: rgba(0, 0, 0, 0.033);
    z-index: 1;

    ${ButtonWrapper} {
      opacity: 1;
      transform: translateY(-50%) scale(1);
      visibility: visible;
    }

    ${Time} {
      display: block;
    }
  }
`;

export const MessageContext = React.createContext<{
  editing?: boolean;
  forwarding?: boolean;
  message?: IMessage;
  replyTo?: IMessage;
  setEditing: (newState: boolean) => void;
  setForwarding: (newState: boolean) => void;
  setReplyTo: (newState?: IMessage) => void;
  setShowDetails: (newState: boolean) => void;
  setTranslation: (newState?: string) => void;
  showDetails?: boolean;
  translation?: string;
}>({
  editing: false,
  forwarding: false,
  message: undefined,
  replyTo: undefined,
  setEditing: () => null,
  setForwarding: () => null,
  setReplyTo: () => null,
  setShowDetails: () => null,
  setTranslation: () => null,
  showDetails: false,
  translation: undefined,
});

const MemorizedChatMessageHeader = React.memo(ChatMessageHeader);

const MessageContainer: React.FC<{
  message: IMessageUnion;
}> = ({ message }) => {
  const [showDetails, setShowDetails] = React.useState(false);
  const [replyTo, setReplyTo] = React.useState<IMessage | undefined>(undefined);
  const [editing, setEditing] = React.useState(false);
  const [translation, setTranslation] = React.useState<string | undefined>();
  const [forwarding, setForwarding] = React.useState(false);

  if (isMessage(message)) {
    const time = message.createdAt ? formatTime(message.createdAt) : undefined;
    return (
      <MessageContext.Provider
        value={{
          editing,
          forwarding,
          message,
          replyTo,
          setEditing,
          setForwarding,
          setReplyTo,
          setShowDetails,
          setTranslation,
          showDetails,
          translation,
        }}
      >
        <ReplyModal />
        {forwarding && <MessageForwardModal />}
        <MessageDetails />
        <SingleMessageWrapper
          first={message.isFirstInBatch}
          last={message.isLastInBatch}
        >
          {message.isFirstInBatch && message.fromUser && (
            <MemorizedChatMessageHeader
              userId={message.fromUser.id}
              firstName={message.fromUser?.firstName || ''}
              lastName={message.fromUser?.lastName}
              avatarUrl={
                message.fromUser?.avatarUrl &&
                getAvatarImageUrl(message.fromUser?.avatarUrl)
              }
              messageTime={time}
            />
          )}
          <Stack spacing="3" direction="row">
            <Box flex=" 0 0 auto" w="32px">
              {!message.isFirstInBatch && !!message.createdAt && (
                <Time>{formatTime(message.createdAt)}</Time>
              )}
            </Box>
            <Box flex="1 1 auto" overflow="hidden">
              {editing ? (
                <MessageEditMask text={message.text || ''} />
              ) : (
                <MessageContentContainer message={message} />
              )}
            </Box>
          </Stack>
          {!message.deleted && !editing && (
            <ButtonWrapper>
              <MessageDropdown />
            </ButtonWrapper>
          )}
        </SingleMessageWrapper>
      </MessageContext.Provider>
    );
  }

  if (isDateLabel(message) && message.value) {
    return (
      <Box my="8px">
        <ChatDateLabel date={message.value || ''} />
      </Box>
    );
  }

  if (isEvent(message)) {
    return (
      <Box my="16px" ml="44px">
        {renderEvent(message)}
      </Box>
    );
  }

  return null;
};

export default MessageContainer;
