import { gql } from 'apollo-boost';

import { IChat, IUser } from '../../../global';
import USER_FRAGMENT from './USER.fragment';
import chatFragment from './chat.fragment';

export interface IFriend {
  user: IUser;
  accepted: boolean;
  blocked: boolean;
  isRequest: boolean;
  chat: IChat;
  online: boolean;
  lastOnline: string;
}

export default gql`
  ${USER_FRAGMENT}
  ${chatFragment}

  fragment friend on Friend {
    user {
      ...user
    }
    accepted
    blocked
    isRequest
    chat {
      ...chat
    }
    online
    lastOnline
  }
`;
