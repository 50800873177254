import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import {
  Box,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/core';

import { version } from '../../../../package.json';
import UserMenuButton from '../../../shared/components/UserMenuButton';
import useCurrentUser from '../../../shared/hooks/useCurrentUser';
import routes from '../../../shared/utils/routes';
import segment from '../../../shared/utils/segment';
import getUserName from '../../../shared/utils/user/getUserName';
import ContactSidebar from './ContactSidebar';
import PrivateChatList from './PrivateChatList';
import SettingsView from './Settings';

const VersionNumber = styled.div`
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  font-weight: 600;
  user-select: none;
`;

const RedirectComponent = () => <Redirect to={routes.user.PRIVATE_CHATS} />;

const Sidebar: React.FC = () => {
  const { t } = useTranslation();
  const [settingsOpen, setSettingsModal] = React.useState(false);
  const user = useCurrentUser();

  const openSettings = React.useCallback(() => {
    setSettingsModal(true);
    segment.settings.open();
  }, [setSettingsModal]);

  const closeSettings = React.useCallback(() => {
    setSettingsModal(false);
  }, [setSettingsModal]);

  if (!user) {
    return null;
  }

  return (
    <Box h="100%" d="flex" flexDirection="column" overflow="hidden">
      <Box flex="0 0 auto">
        <Box
          d="flex"
          alignItems="center"
          justifyContent="stretch"
          px="4"
          py="2"
          h="80px"
        >
          <Box flex="1 1 auto">
            <UserMenuButton
              name={getUserName(user)}
              label={user.phone || ''}
              onClick={openSettings}
            />
            <Modal isOpen={settingsOpen} onClose={closeSettings}>
              <ModalOverlay>
                <ModalContent borderRadius="4px">
                  <ModalHeader>{t('settingsModalTitle')}</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <SettingsView />
                  </ModalBody>
                  <ModalFooter justifyContent="center" textAlign="center">
                    <VersionNumber>{version}</VersionNumber>
                  </ModalFooter>
                </ModalContent>
              </ModalOverlay>
            </Modal>
          </Box>
        </Box>
        <Divider m="0" color="gray.100" />
      </Box>
      <Box flex="1 1 auto" overflow="hidden">
        <Switch>
          <Route path={routes.user.PRIVATE_CHATS} component={PrivateChatList} />
          <Route
            path={routes.user.PRIVATE_CONTACTS}
            component={ContactSidebar}
          />
          <Route
            path={routes.user.COMPANY}
            component={() => <div>Company Sidebar Container</div>}
          />
          <Route exact path="*" component={RedirectComponent} />
        </Switch>
      </Box>
    </Box>
  );
};

export default Sidebar;
