import React from 'react';

import { Avatar, Badge, Box, Stack, Text } from '@chakra-ui/core';

import { formatChatDate } from '../../utils/chats/formatChatDate';
import getAvatarImageUrl from '../../utils/getAvatarImageUrl';

interface IProps {
  avatarUrl?: string;
  chatName: string;
  lastMessage?: string;
  lastAuthor?: string;
  unreadCount?: number;
  messageDate?: string;
}

const ChatName: React.FC = React.memo(({ children }) => (
  <Text
    color="gray.900"
    fontSize="sm"
    fontWeight="500"
    lineHeight="1.4"
    overflow="hidden"
    whiteSpace="nowrap"
    style={{ textOverflow: 'ellipsis' }}
  >
    {children}
  </Text>
));

const ChatMessage: React.FC = React.memo(({ children }) => (
  <Text
    color="gray.400"
    fontSize="sm"
    fontWeight="500"
    lineHeight="1.4"
    overflow="hidden"
    whiteSpace="nowrap"
    style={{ textOverflow: 'ellipsis' }}
  >
    {children}
  </Text>
));

const ChatDate: React.FC = React.memo(({ children }) => (
  <Text
    color="gray.400"
    fontSize="xs"
    fontWeight="500"
    lineHeight="1"
    overflow="hidden"
    whiteSpace="nowrap"
    style={{ textOverflow: 'ellipsis' }}
  >
    {children}
  </Text>
));

const ChatListItem: React.FC<IProps> = React.memo((props) => {
  const {
    avatarUrl,
    chatName,
    lastMessage,
    messageDate,
    lastAuthor,
    unreadCount,
  } = props;

  const lastMessageText = lastAuthor
    ? `${lastAuthor}: ${lastMessage}`
    : lastMessage;

  const dateString = messageDate
    ? formatChatDate(messageDate).string
    : undefined;

  return (
    <Stack
      direction="row"
      spacing="3"
      alignItems="center"
      overflow="hidden"
      width="100%"
    >
      <Avatar
        flex="0 0 auto"
        size="sm"
        src={avatarUrl && getAvatarImageUrl(avatarUrl)}
        name={chatName}
      />
      <Stack
        direction="row"
        flex="1 1 auto"
        spacing="2"
        alignItems="center"
        overflow="hidden"
      >
        <Box flex="1 1 auto" overflow="hidden">
          <ChatName>{chatName}</ChatName>
          <ChatMessage>{lastMessageText}</ChatMessage>
        </Box>
        <Box flex="0 0 auto" textAlign="right">
          <ChatDate>{dateString}</ChatDate>
          {!!unreadCount && unreadCount > 0 && (
            <Box mt="1">
              <Badge
                variantColor="teal"
                p="1"
                fontSize="xs"
                minW="20px"
                textAlign="center"
                fontWeight="500"
              >
                {unreadCount}
              </Badge>
            </Box>
          )}
        </Box>
      </Stack>
    </Stack>
  );
});

export default ChatListItem;
