import { gql } from 'apollo-boost';

import USER_FRAGMENT from '../fragments/USER.fragment';

const GET_CURRENT_USER_QUERY = gql`
  ${USER_FRAGMENT}

  query {
    user {
      ...user
    }
  }
`;

export default GET_CURRENT_USER_QUERY;
