import React from 'react';
import { Editor, Node, NodeEntry, Range, Transforms } from 'slate';
import { Editable } from 'slate-react';
import styled from 'styled-components';

import decorateSlateWithHashtags from '../../../utils/slate/decorateSlateWithHashtags';
import Leaf from './Leaf';

const InputBox = styled(Editable)`
  padding: 8px 12px;
  color: ${(p) => p.theme.colors.gray['700']};
  line-height: 24px;
`;

interface IProps {
  placeholder: string;
  onSubmit: () => void;
  editor: Editor;
}

const decorate = (entry: NodeEntry<Node>): Range[] => {
  const [node, path] = entry;
  const ranges: Range[] = [];

  decorateSlateWithHashtags(node, ranges, path);

  return ranges;
};

const InputEditor: React.FC<IProps> = ({ editor, placeholder, onSubmit }) => {
  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.keyCode === 13 && !e.shiftKey && onSubmit) {
        e.preventDefault();
        Transforms.select(editor, { anchor: [0], path: [0], offset: 0 });
        onSubmit();
      }
    },
    [editor, onSubmit]
  );

  return (
    <InputBox
      decorate={decorate}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      renderLeaf={(props) => <Leaf {...props} />}
      autoFocus
    />
  );
};

export default InputEditor;
