import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Icon,
  InternalState,
  MenuDivider,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Tooltip,
} from '@chakra-ui/core';

import MenuItem from '../../../../../../shared/components/MenuItem';
import getMinuteDifference from '../../../../../../shared/utils/getMinuteDifference';
import deleteMessage from '../../../../../../shared/utils/messages/deleteMessage';
import segment from '../../../../../../shared/utils/segment';
import TranslationsMenu from './TranslationsMenu';
import { MessageContext } from '.';

const DropdownContent: React.FC<{ popover: InternalState }> = ({ popover }) => {
  const { t } = useTranslation();
  const {
    message,
    setEditing,
    setForwarding,
    setShowDetails,
    setReplyTo,
  } = React.useContext(MessageContext);

  const messageId = message?.id;

  React.useEffect(() => {
    if (messageId) {
      segment.message.openDropdown(messageId);
    }
  }, [messageId]);

  const handleDelete = React.useCallback(() => {
    if (popover?.onClose) {
      popover?.onClose();
    }
    if (messageId) {
      deleteMessage(messageId);
      segment.message.delete(messageId);
    }
  }, [popover, messageId]);

  const handleEdit = React.useCallback(() => {
    if (popover?.onClose) {
      popover?.onClose();
    }
    if (messageId) {
      setEditing(true);
    }
  }, [popover, messageId, setEditing]);

  const handleForward = React.useCallback(() => {
    if (popover?.onClose) {
      popover?.onClose();
    }
    if (messageId) {
      setForwarding(true);
    }
  }, [popover, messageId, setForwarding]);

  const handleShowDetails = React.useCallback(() => {
    if (popover?.onClose) {
      popover?.onClose();
    }
    setShowDetails(true);
  }, [popover, setShowDetails]);

  const handleReply = React.useCallback(() => {
    if (popover?.onClose) {
      popover?.onClose();
    }
    if (message) {
      setReplyTo(message);
    }
  }, [popover, setReplyTo, message]);

  if (!message) {
    return null;
  }

  const isAuthor = message.isAuthor;
  const canEdit =
    message.createdAt && getMinuteDifference(message?.createdAt) <= 15;

  return (
    <>
      <MenuItem onClick={handleReply}>{t('chatReplyMessage')}</MenuItem>
      <MenuItem onClick={handleForward}>{t('chatForwardMessage')}</MenuItem>
      {isAuthor && (
        <>
          <MenuItem disabled={!canEdit} onClick={handleEdit}>
            {t('chatEditMessage')}
          </MenuItem>
          <MenuItem disabled={!canEdit} onClick={handleDelete}>
            {t('chatDeleteMessage')}
          </MenuItem>
        </>
      )}
      <MenuDivider />
      <MenuItem onClick={handleShowDetails}>
        {t('chatMessageInformation')}
      </MenuItem>
    </>
  );
};

const MessageDropdown: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Stack direction="row" spacing="1">
      <Box>
        <Popover usePortal>
          {(popover) => (
            <>
              <Tooltip
                label={t('generalTranslate')}
                aria-label={t('generalTranslate')}
                zIndex={100}
              >
                <Box>
                  <PopoverTrigger>
                    <Button size="sm" aria-label="x" color="gray.500">
                      <Icon name="translate" size="5" />
                    </Button>
                  </PopoverTrigger>
                </Box>
              </Tooltip>
              <PopoverContent
                border="1px solid"
                borderColor="gray.100"
                borderRadius="4px"
                maxW="230px"
                _focus={{ outline: '0' }}
                zIndex={100}
              >
                <PopoverBody px="0" py="3">
                  <TranslationsMenu popover={popover} />
                </PopoverBody>
              </PopoverContent>
            </>
          )}
        </Popover>
      </Box>
      <Box>
        <Popover usePortal>
          {(popover) => (
            <>
              <Tooltip
                label={t('chatMessageOptions')}
                aria-label={t('chatMessageOptions')}
                zIndex={100}
              >
                <Box>
                  <PopoverTrigger>
                    <Button size="sm" aria-label="x" color="gray.500">
                      <Icon name="more" size="5" />
                    </Button>
                  </PopoverTrigger>
                </Box>
              </Tooltip>
              <PopoverContent
                border="1px solid"
                borderColor="gray.100"
                borderRadius="4px"
                maxW="230px"
                _focus={{ outline: '0' }}
                zIndex={100}
              >
                <PopoverBody px="0" py="3">
                  <DropdownContent popover={popover} />
                </PopoverBody>
              </PopoverContent>
            </>
          )}
        </Popover>
      </Box>
    </Stack>
  );

  /*return (
    <>
      <Dropdown>
        <DropdownContext.Consumer>
          {({ open }) => (
            <Button
              buttonSize="xs"
              buttonTheme="light"
              iconButton
              border
              onClick={open}
            >
              <IconTranslate size={20} />
            </Button>
          )}
        </DropdownContext.Consumer>
        <DropdownMenu>
          <TranslationsMenu />
        </DropdownMenu>
      </Dropdown>
      <Dropdown>
        <DropdownContext.Consumer>
          {({ open }) => (
            <Button
              buttonSize="xs"
              buttonTheme="light"
              iconButton
              border
              onClick={open}
            >
              <IconMoreHorizontal size={20} />
            </Button>
          )}
        </DropdownContext.Consumer>
        <DropdownMenu>
          <DropdownContent />
        </DropdownMenu>
      </Dropdown>
    </>
  );*/
};

export default MessageDropdown;
