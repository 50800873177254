import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import FullscreenLayout from '../../shared/components/Layouts/FullscreenLayout';
import routes from '../../shared/utils/routes';
import LoginView from './Views/LoginView';
import VerificationView from './Views/VerificationView';

const RedirectRoute = () => <Redirect to="/" />;

const LoginApplication: React.FC = () => {
  return (
    <FullscreenLayout>
      <Switch>
        <Route exact path={routes.HOME} component={LoginView} />
        <Route
          exact
          path={routes.auth.TOKEN_VERIFICATION}
          component={VerificationView}
        />
        <Route exact path="*" component={RedirectRoute} />
      </Switch>
    </FullscreenLayout>
  );
};

export default LoginApplication;
