import { gql } from 'apollo-boost';

import client from '../../graphql';

const mutation = gql`
  mutation RENAME_CHAT($chatId: String!, $name: String!) {
    renameGroupChat(chatId: $chatId, name: $name)
  }
`;

export default (chatId: string, newName: string) => {
  if (!newName || newName.length === 0) {
    return;
  }

  return client.mutate<{ renameGroupChat: boolean }>({
    mutation,
    variables: {
      chatId,
      name: newName,
    },
  });
};
