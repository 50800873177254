import React from 'react';

import { isImageMimeType } from '../../../../utils/attachments/isImage';
import FilePreview from '../FilePreview';
import ImagePreview from '../ImagePreview';

interface IProps {
  files: File[];
  onRemove: (file: File) => void;
}

const FileList: React.FC<IProps> = ({ files, onRemove }) => {
  return (
    <div>
      {files.map((file, i) => {
        if (isImageMimeType(file.type)) {
          return (
            <ImagePreview
              onRemove={onRemove}
              file={file}
              key={`file-${i}-${file.name}`}
            />
          );
        }

        return (
          <FilePreview
            onRemove={onRemove}
            file={file}
            key={`file-${i}-${file.name}`}
          />
        );
      })}
    </div>
  );
};

export default FileList;
