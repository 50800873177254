import React from 'react';

import { Box, Spinner } from '@chakra-ui/core';

const FullscreenLoader = React.memo(() => {
  return (
    <Box
      alignItems="center"
      color="teal.500"
      display="flex"
      height="100%"
      justifyContent="center"
      width="100%"
    >
      <Spinner size="md" />
    </Box>
  );
});

export default FullscreenLoader;
