import React from 'react';
import styled from 'styled-components';

import getResizedImageUrl from '../../utils/getResizedImageUrl';

const Thumbnail = styled.a`
  border-radius: 16px;
  display: block;
  height: 200px;
  overflow: hidden;
  width: 280px;
`;

const Image = styled.img`
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

interface IProps {
  imageUrl?: string;
  fileName?: string;
}

const ImageAttachment: React.FC<IProps> = ({ imageUrl, fileName }) => {
  const thumbnailUrl = getResizedImageUrl(imageUrl, 400);
  const fullSizeUrl = getResizedImageUrl(imageUrl, 1200);

  return (
    <Thumbnail href={fullSizeUrl} target="_blank" rel="noopener noreferrer">
      <Image src={thumbnailUrl} alt={fileName} />
    </Thumbnail>
  );
};

export default ImageAttachment;
