import { gql } from 'apollo-boost';

import USER_FRAGMENT from './USER.fragment';

export interface IChatFragment {
  __typename: 'Chat';
  archived: boolean;
  avatarUrl?: string;
  hasParentChat: boolean;
  id: string;
  lastUpdatedAt: string;
  muted: boolean;
  name?: string;
  sortDate: string;
  type: 'direct' | 'group';
  unreadCount: number;
}

export default gql`
  ${USER_FRAGMENT}

  fragment chat on Chat {
    id
    name
    lastUpdatedAt
    type
    avatarUrl
    archived
    unreadCount
    hasParentChat
    sortDate
    muted
    readBy {
      user {
        ...user
      }
      time
    }
  }
`;
