import { gql } from 'apollo-boost';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useLazyQuery } from '@apollo/react-hooks';
import { Box, Button, Spinner } from '@chakra-ui/core';

import { IAttachment } from '../../../../../../global';
import GridGallery, {
  GridGalleryItem,
  GridGalleryThumbnail,
} from '../../../../../../shared/components/GridGallery';
import { ChatContext } from '../../../../../../shared/context/ChatContext';
import attachmentFragment from '../../../../../../shared/graphql/fragments/attachment.fragment';
import segment from '../../../../../../shared/utils/segment';
import AttachmentsModal from './AttachmentsModal';

const COUNT = 8;

const chatAttachmentsQuery = gql`
  ${attachmentFragment}

  query CHAT_ATTACHMENTS($chatId: String!) {
    attachments(chatId: $chatId) {
      ...attachment
    }
  }
`;

export const AttachmentsContext = React.createContext<{
  attachments: IAttachment[];
  modalOpen: boolean;
  setModalOpen: (newState: boolean) => void;
}>({
  attachments: [],
  modalOpen: false,
  setModalOpen: () => null,
});

const AttachmentItem: React.FC<{ data: IAttachment }> = ({ data }) => {
  return (
    <a
      title={data.originalFileName}
      href={data.path}
      target="_blank"
      rel="noopener noreferrer"
    >
      <GridGalleryThumbnail attachment={data} />
    </a>
  );
};

const ChatSidebarAttachments: React.FC = () => {
  const { t } = useTranslation();
  const { chat } = React.useContext(ChatContext);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [fetchAttachments, { data, loading }] = useLazyQuery<{
    attachments: IAttachment[];
  }>(chatAttachmentsQuery);

  React.useEffect(() => {
    if (chat?.id) {
      fetchAttachments({ variables: { chatId: chat.id } });
    }
  }, [chat, fetchAttachments]);

  const showAllAttachments = React.useCallback(() => {
    if (chat) {
      segment.chat.openAttachments(chat.id);
    }
    setModalOpen(true);
  }, [setModalOpen, chat]);

  return (
    <AttachmentsContext.Provider
      value={{
        attachments: data?.attachments || [],
        modalOpen,
        setModalOpen,
      }}
    >
      {loading && <Spinner />}
      {!loading && !data?.attachments.length && (
        <Box>{t('generalNoAttachments')}</Box>
      )}
      {!loading && data?.attachments && data.attachments.length > 0 && (
        <>
          <GridGallery count={4}>
            {data.attachments.slice(0, COUNT).map((attachment) => (
              <GridGalleryItem key={attachment.id}>
                <AttachmentItem data={attachment} />
              </GridGalleryItem>
            ))}
          </GridGallery>
          {data.attachments.length > COUNT && (
            <Box mt="12px">
              <Button
                isFullWidth
                size="sm"
                variantColor="teal"
                onClick={showAllAttachments}
              >
                {t('chatAllAttachments')}
              </Button>
            </Box>
          )}
          {modalOpen && <AttachmentsModal />}
        </>
      )}
    </AttachmentsContext.Provider>
  );
};

export default ChatSidebarAttachments;
export const MemorizedChatSidebarAttachments = React.memo(
  ChatSidebarAttachments
);
