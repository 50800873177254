import React from 'react';
import { useTranslation } from 'react-i18next';

import { InternalState } from '@chakra-ui/core';

import MenuItem from '../../../../../../shared/components/MenuItem';
import languages, { ILanguage } from '../../../../../../shared/utils/languages';
import segment from '../../../../../../shared/utils/segment';
import { MessageContext } from '.';

const TranslationsMenu: React.FC<{ popover: InternalState }> = React.memo(
  ({ popover }) => {
    const { t } = useTranslation();
    const { message, translation, setTranslation } = React.useContext(
      MessageContext
    );

    React.useEffect(() => {
      if (message) {
        segment.message.openTranslateMenu(message.id);
      }
    }, [message]);

    const renderItem = React.useCallback(
      (lang: ILanguage) => {
        const handleClick = () => {
          if (popover.onClose) {
            popover.onClose();
          }
          if (message) {
            setTranslation(lang.key);
          }
        };

        return (
          <MenuItem onClick={handleClick} key={lang.key}>
            {t(lang.translateToLabel)}
          </MenuItem>
        );
      },
      [popover, message, t, setTranslation]
    );

    const handleClear = React.useCallback(() => {
      setTranslation(undefined);
      if (popover.onClose) {
        popover.onClose();
      }
    }, [popover, setTranslation]);

    return (
      <>
        {translation && (
          <MenuItem onClick={handleClear}>{t('langClearTranslation')}</MenuItem>
        )}
        {languages.map(renderItem)}
      </>
    );
  }
);

export default TranslationsMenu;
