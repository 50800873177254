import { gql } from 'apollo-boost';

import client from '../../graphql';

const mutation = gql`
  mutation TRANSLATE_MESSAGE($messageId: String!, $targetLanguage: String!) {
    translateMessage(targetLanguage: $targetLanguage, messageId: $messageId) {
      translation {
        language
        text
      }
    }
  }
`;

const translateMessage = async (messageId: string, targetLanguage: string) => {
  const res = await client.mutate<{
    translateMessage: {
      translation: {
        language: string;
        text: string;
      };
    };
  }>({
    mutation,
    variables: {
      messageId,
      targetLanguage: targetLanguage.toUpperCase(),
    },
  });

  return res;
};

export default translateMessage;
