import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Divider, Stack } from '@chakra-ui/core';

import LoginScreenHeader from '../../../../shared/components/LoginScreenHeader';
import OAuthButton from '../../../../shared/components/OAuthButton';
import LoginForm from './LoginForm';

const LoginView: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box maxWidth="600px" mx="auto">
      <LoginScreenHeader
        title="ZIP Messenger"
        subtitle={t('loginScreenSubtitle')}
      />
      <LoginForm />
      {process.env.REACT_APP_SHOW_TOWIO_LOGIN === 'yes' && (
        <>
          <Divider />
          <Stack justifyContent="center" alignItems="center" mt={8}>
            <OAuthButton
              href={`${process.env.REACT_APP_API_URL}/auth/edooli`}
              title={t('loginSignInWithTowio')}
            />
          </Stack>
        </>
      )}
    </Box>
  );
};

export default LoginView;
