import client from '../../graphql';
import REQUEST_CODE_MUTATION from '../../graphql/mutations/requestCode.mutation';

interface IResult {
  requestCode: boolean;
}

const requestLoginCode = async (address: string) => {
  const res = await client.mutate<IResult>({
    mutation: REQUEST_CODE_MUTATION,
    variables: { address },
  });

  return res.data?.requestCode;
};

export default requestLoginCode;
