import { useCallback, useState } from 'react';

export default (query?: string) => {
  const [q, setQ] = useState(query || '');

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setQ(e.target.value);
    },
    [setQ]
  );

  const handleClear = useCallback(() => {
    setQ('');
  }, [setQ]);

  return { q, setValue: setQ, handleChange, handleClear };
};
