import { differenceInCalendarDays, differenceInCalendarYears } from 'date-fns';
import i18n from 'i18next';

import formatDate from '../formatDate';
import formatTime from '../formatTime';

export const formatChatDate = (
  date: string,
  options?: { forceDayFormat?: boolean }
) => {
  if (date) {
    const today = new Date();
    const chatDate = new Date(date);
    const daysDiff = differenceInCalendarDays(today, chatDate);
    const yearDiff = differenceInCalendarYears(today, chatDate);

    const days = [
      'dateSunday',
      'dateMonday',
      'dateTuesday',
      'dateWednesday',
      'dateThursday',
      'dateFriday',
      'dateSaturday',
    ];

    const localeString = i18n.language || 'en';

    const timeString = formatTime(date, localeString);
    const dateString = formatDate(date, localeString, { noYear: true });
    const fullDateString = formatDate(date, localeString);

    if (yearDiff > 1) {
      return {
        string: fullDateString,
        time: timeString,
        type: 'date',
      };
    }

    if (daysDiff > 7) {
      return {
        string: dateString,
        time: timeString,
        type: 'date',
      };
    }

    if (daysDiff >= 2) {
      return {
        string: i18n.t(days[chatDate.getDay()]),
        time: timeString,
        type: 'day',
      };
    }

    if (daysDiff > 0) {
      return {
        string: i18n.t('dateYesterday'),
        time: timeString,
        type: 'day',
      };
    }

    if (options?.forceDayFormat) {
      return {
        string: i18n.t('dateToday'),
        time: timeString,
        type: 'day',
      };
    }

    return {
      string: timeString,
      time: timeString,
      type: 'time',
    };
  }

  return {
    string: '',
    time: '',
    type: 'date',
  };
};
