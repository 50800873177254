import React from 'react';

import { IUser } from '../../../global';
import UserListItem from '../UserListItem';

const ParticipantsRow: React.FC<{
  prefixContent?: (user: IUser) => JSX.Element;
  suffixContent?: (user: IUser) => JSX.Element;
  user: IUser;
}> = ({ user, prefixContent, suffixContent }) => {
  return (
    <UserListItem
      verticalSpacing
      divider
      small
      user={user}
      suffixContent={suffixContent && suffixContent(user)}
      prefixContent={prefixContent && prefixContent(user)}
      usePopover
    />
  );
};

const ParticipantsList: React.FC<{
  participants: IUser[];
  prefixContent?: (user: IUser) => JSX.Element;
  suffixContent?: (user: IUser) => JSX.Element;
}> = ({ participants, prefixContent, suffixContent }) => {
  return (
    <div>
      {participants.map((participant) => (
        <ParticipantsRow
          prefixContent={prefixContent}
          suffixContent={suffixContent}
          user={participant}
          key={participant.id}
        />
      ))}
    </div>
  );
};

export default React.memo(ParticipantsList);
