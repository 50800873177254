import { gql } from 'apollo-boost';

export const fragment = gql`
  fragment friend on Friend {
    user {
      avatarUrl
      email
      firstName
      id
      lastName
      phone
    }
    accepted
    blocked
    isRequest
    chat {
      id
    }
    online
    lastOnline
  }
`;

export const query = gql`
  ${fragment}

  query {
    friends(type: accepted) {
      ...friend
    }
  }
`;

export const subscription = gql`
  ${fragment}

  subscription {
    friends {
      action
      friend {
        ...friend
      }
    }
  }
`;
