import { IUser } from '../../../global';

const getUserName = (user: IUser) => {
  if (!user.firstName) {
    return '';
  }

  if (!user.lastName) {
    return user.firstName;
  }
  return [user.firstName, user.lastName].join(' ');
};

export default getUserName;
