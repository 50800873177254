import React from 'react';
import styled from 'styled-components';

import { Avatar, Box } from '@chakra-ui/core';

import getAvatarImageUrl from '../../utils/getAvatarImageUrl';

const ChatName = styled.div`
  color: ${(p) => p.theme.colors.gray['900']};
  font-size: ${(p) => p.theme.fontSizes.md};
  font-weight: 500;
  line-height: 1;
`;

const Subtitle = styled.div`
  color: ${(p) => p.theme.colors.gray['400']};
  font-size: ${(p) => p.theme.fontSizes.sm};
  line-height: 1;
  margin-top: 4px;
`;

interface IProps {
  avatarUrl?: string;
  id: string;
  name: string;
  subtitle?: string;
}

const ChatHeader: React.FC<IProps> = React.memo((props) => {
  const { avatarUrl, name, subtitle } = props;

  return (
    <Box color="inherit" textDecoration="none" userSelect="none">
      <Box display="flex" alignItems="center">
        <Box size="s" mr="16px">
          <Avatar
            src={avatarUrl && getAvatarImageUrl(avatarUrl, true)}
            size="sm"
            name={name}
          />
        </Box>
        <Box>
          <ChatName>{name}</ChatName>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </Box>
      </Box>
    </Box>
  );
});

export default ChatHeader;
