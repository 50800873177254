import React from 'react';
import styled from 'styled-components';

import { Avatar, Box, Stack } from '@chakra-ui/core';

import { IUser } from '../../../global';
import getUserName from '../../utils/user/getUserName';

const Name = styled.div`
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  overflow: hidden;
  font-weight: 500;
  color: ${(p) => p.theme.colors.gray['900']};
`;

const ContactData = styled.div`
  color: ${(p) => p.theme.colors.gray['400']};
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

interface IProps {
  user: IUser;
}

const ContactItem: React.FC<IProps> = React.memo(({ user }) => {
  const fullName = getUserName(user);

  return (
    <>
      <Stack
        direction="row"
        spacing="3"
        alignItems="center"
        overflow="hidden"
        width="100%"
      >
        <Avatar
          flex="0 0 auto"
          size="sm"
          src={user.avatarUrl}
          name={fullName}
        />
        <Box flex="1 1 auto" overflow="hidden">
          <Name>{fullName}</Name>
          <ContactData>{user.phone || user.email}</ContactData>
        </Box>
      </Stack>
    </>
  );
});

export default ContactItem;
