import { IUser } from '../../../global';
import getUserName from './getUserName';

const searchUsers = (query: string, users: IUser[]) => {
  return users.filter((user) => {
    const lquery = query.toLowerCase();
    const name = getUserName(user).toLowerCase();

    if (name.includes(lquery)) {
      return true;
    }

    if (user.phone && user.phone.includes(lquery)) {
      return true;
    }

    if (user.email && user.email.includes(lquery)) {
      return true;
    }

    return false;
  });
};

export default searchUsers;
