import React from 'react';

import { Avatar, Box, Text } from '@chakra-ui/core';

import { IUser } from '../../../../global';
import getAvatarImageUrl from '../../../utils/getAvatarImageUrl';
import getUserName from '../../../utils/user/getUserName';

interface IProps {
  executor: IUser;
  icon?: JSX.Element;
}

const UserWithChatEvent: React.FC<IProps> = React.memo(
  ({ executor, children }) => {
    const userName = getUserName(executor);
    return (
      <Box
        d="flex"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
      >
        <div>
          <Avatar
            src={executor.avatarUrl && getAvatarImageUrl(executor.avatarUrl)}
            size="sm"
            name={userName}
          />
        </div>
        <Box mt="2">
          <Text fontSize="sm" color="gray.500">
            {children}
          </Text>
        </Box>
      </Box>
    );
  }
);

export default UserWithChatEvent;
