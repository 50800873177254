import { gql } from 'apollo-boost';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { MenuItem } from '@chakra-ui/core';

import { ChatContext } from '../../../../../../shared/context/ChatContext';
import client from '../../../../../../shared/graphql';
import segment from '../../../../../../shared/utils/segment';

const promoteMutation = gql`
  mutation PROMOTE_TO_OWNER($chatId: String!, $ownerId: String!) {
    transferOwnership(chatId: $chatId, newOwnerId: $ownerId)
  }
`;

const PromoteToOwnerButton: React.FC<{
  userId: string;
}> = ({ userId }) => {
  const { chat } = React.useContext(ChatContext);
  const { t } = useTranslation();

  const handleClick = React.useCallback(async () => {
    if (chat) {
      await client.mutate({
        mutation: promoteMutation,
        variables: {
          chatId: chat?.id,
          ownerId: userId,
        },
      });
      segment.chat.promoteUser(chat.id, userId);
    }
  }, [chat, userId]);

  return <MenuItem onClick={handleClick}>{t('chatPromoteToOwner')}</MenuItem>;
};

export default PromoteToOwnerButton;
