import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Box, Button, Stack } from '@chakra-ui/core';

import LoginScreenHeader from '../../../../shared/components/LoginScreenHeader';
import getErrorMessage from '../../../../shared/utils/getErrorMessage';
import requestLoginCode from '../../../../shared/utils/login/requestLoginCode';
import routes from '../../../../shared/utils/routes';
import VerificationForm from './VerificationForm';

const VerificationView = () => {
  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = React.useState<string | undefined>(
    undefined
  );

  React.useEffect(() => {
    const number = window.localStorage.getItem('requestPhoneNumber');
    if (number) {
      setPhoneNumber(number);
    }

    return () => {
      window.localStorage.removeItem('requestPhoneNumber');
    };
  }, []);

  const handleResendClick = React.useCallback(async () => {
    if (phoneNumber) {
      try {
        await requestLoginCode(phoneNumber);
      } catch (e) {
        alert(getErrorMessage(e)?.errorMessage);
      }
    }
  }, [phoneNumber]);

  return (
    <>
      <LoginScreenHeader
        title={t('verificationScreenTitle')}
        subtitle={t('verificationScreenSubtitle')}
      />
      <VerificationForm />
      <Box mt="4">
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Button
            as={Link}
            variant="ghost"
            variantColor="gray"
            color="gray.500"
            size="sm"
            {...{ to: routes.HOME }}
          >
            {t('generalCancel')}
          </Button>
          {phoneNumber && (
            <Button
              onClick={handleResendClick}
              variant="ghost"
              variantColor="gray"
              color="gray.500"
              size="sm"
            >
              {t('verificationScreenResendCode')}
            </Button>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default VerificationView;
