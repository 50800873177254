import React from 'react';

import FilePreview from '../FilePreview';

interface IProps {
  file: File;
  onRemove: (file: File) => void;
}

const ImagePreview: React.FC<IProps> = ({ file, onRemove }) => {
  const fileReader = React.useRef(new FileReader());
  const [imageUrl, setImageUrl] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    const currentFileReader = fileReader.current;

    const readerHandler = () => {
      setImageUrl(currentFileReader.result?.toString());
    };

    currentFileReader.addEventListener('load', readerHandler);
    return () => {
      currentFileReader.removeEventListener('load', readerHandler);
    };
  }, [file]);

  React.useEffect(() => {
    fileReader.current.readAsDataURL(file);
  }, [file]);

  if (!imageUrl) {
    return null;
  }

  return (
    <FilePreview onRemove={onRemove} file={file} imagePreview={imageUrl} />
  );
};

export default ImagePreview;
