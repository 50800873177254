import React from 'react';
import styled from 'styled-components';

const LayoutWrapper = styled.div`
  display: grid;
  grid-template-columns: 81px 320px 1fr;
  grid-template-rows: 100%;
  height: 100%;
  overflow: hidden;
`;

const Apps = styled.div`
  background: ${(p) => p.theme.colors.white};
  border-right: 1px solid ${(p) => p.theme.colors.gray['100']};
  overflow: hidden;
`;

const Sidebar = styled.div`
  background: ${(p) => p.theme.colors.white};
  border-right: 1px solid ${(p) => p.theme.colors.gray['100']};
  overflow: hidden;
`;

const Content = styled.div`
  background: ${(p) => p.theme.colors.white};
  overflow: hidden;
`;

const AppLayout: React.FC<{
  teams: JSX.Element;
  sidebar: JSX.Element;
}> = (props) => {
  const { sidebar, teams, children } = props;

  return (
    <LayoutWrapper>
      <Apps>{teams}</Apps>
      <Sidebar>{sidebar}</Sidebar>
      <Content>{children}</Content>
    </LayoutWrapper>
  );
};

export default AppLayout;
