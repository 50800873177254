import React from 'react';
import styled from 'styled-components';

import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/core';

import { IUser } from '../../../../../global';
import ContactItem from '../../../../../shared/components/ContactItem';
import ListItem from '../../../../../shared/components/ListItem';
import SearchField from '../../../../../shared/components/SearchField';
import useDebouncedValue from '../../../../../shared/hooks/useDebouncedValue';
import useSearch from '../../../../../shared/hooks/useSearch';
import addUserToFriends from '../../../../../shared/utils/user/addUserToFriends';
import searchForUser from '../../../../../shared/utils/user/searchForUser';

const ButtonWrapper = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;
  display: block;
  font-family: inherit;
  padding: 12px 0;
  width: 100%;
`;

interface IProps {
  close: (...args: any) => void;
  open?: boolean;
}

const ContactResult: React.FC<{ user: IUser }> = React.memo((props) => {
  const { closeModal } = React.useContext(ModalContext);
  const { user } = props;

  const addUser = React.useCallback(async () => {
    const res = await addUserToFriends(user.id);
    if (res) {
      closeModal();
    }
  }, [user, closeModal]);

  return (
    <ButtonWrapper onClick={addUser}>
      <ListItem>
        <ContactItem user={user} />
      </ListItem>
    </ButtonWrapper>
  );
});

const Content: React.FC = () => {
  const [result, setResult] = React.useState<IUser | undefined>(undefined);
  const search = useSearch();
  const debouncedSearch = useDebouncedValue(1000, search.q);

  React.useEffect(() => {
    if (debouncedSearch.value) {
      setResult(undefined);
      searchForUser(debouncedSearch.value).then((user) => setResult(user));
    } else {
      setResult(undefined);
    }
  }, [debouncedSearch.value, setResult]);

  return (
    <Box minH="100px">
      <SearchField
        onChange={search.handleChange}
        onReset={search.handleClear}
        value={search.q}
        placeholder="Search user by phone number"
      />
      {debouncedSearch.loading && (
        <Box d="flex" justifyContent="center" px="6" py="4">
          <Spinner size="sm" />
        </Box>
      )}
      {search.q && result && !debouncedSearch.loading && (
        <ContactResult user={result} />
      )}
    </Box>
  );
};

const ModalContext = React.createContext<{
  closeModal: () => void;
}>({
  closeModal: () => null,
});

const AddContactModal: React.FC<IProps> = ({ open, close }) => {
  return (
    <Modal isOpen={open} onClose={close}>
      <ModalOverlay>
        <ModalContent borderRadius="4px">
          <ModalHeader>Add contact</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ModalContext.Provider value={{ closeModal: close }}>
              <Content />
            </ModalContext.Provider>
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default AddContactModal;
