const loginWithPhone = (phoneNumber: string) => {
  window.analytics.track('LoginWithPhone', { phoneNumber });
};

const loginWithToken = (token: string) => {
  window.analytics.track('LoginWithToken', { token });
};

export default {
  loginWithPhone,
  loginWithToken,
};
