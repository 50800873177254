import React from 'react';

import { Box, Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/core';

import UserProfile from './UserProfile';

interface IProps {
  userId: string;
}

const UserPopover: React.FC<IProps> = ({ children, userId }) => {
  return (
    <Popover usePortal>
      <PopoverTrigger>
        <Box cursor="pointer">{children}</Box>
      </PopoverTrigger>
      <PopoverContent maxWidth="320px" zIndex={9000} _focus={{ outline: '0' }}>
        <UserProfile userId={userId} />
      </PopoverContent>
    </Popover>
  );
};

export default UserPopover;
