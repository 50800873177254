import React from 'react';
import styled from 'styled-components';

import { Box } from '@chakra-ui/core';

export const SidebarContext = React.createContext<{
  open: boolean;
  setOpen: (newState: boolean) => void;
  openSidebar: () => void;
  closeSidebar: () => void;
}>({
  open: false,
  setOpen: () => null,
  openSidebar: () => null,
  closeSidebar: () => null,
});

const SidebarWrapper = styled(Box)`
  overflow-x: hidden;
  overflow-y: auto;
`;

export const useSidebar = () => React.useContext(SidebarContext);

const SidebarView: React.FC<{
  sidebar: JSX.Element;
}> = React.memo(({ children, sidebar }) => {
  const [open, setOpen] = React.useState(false);
  const openSidebar = React.useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  const closeSidebar = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <SidebarContext.Provider
      value={{ open, setOpen, openSidebar, closeSidebar }}
    >
      <Box d="flex" h="100%">
        <Box h="100%" flex="1 1 auto" overflow="hidden">
          {children}
        </Box>
        {open && (
          <SidebarWrapper
            height="100%"
            flex="0 0 auto"
            width={['320px', '480px']}
          >
            {sidebar}
          </SidebarWrapper>
        )}
      </Box>
    </SidebarContext.Provider>
  );
});

export default SidebarView;
