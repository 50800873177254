import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Box, Button } from '@chakra-ui/core';

import { IUser } from '../../../../../global';
import ContactHeader from '../../../../../shared/components/ContactHeader';
import routes from '../../../../../shared/utils/routes';
import getUserName from '../../../../../shared/utils/user/getUserName';

interface IProps {
  user: IUser;
}

const UserProfile: React.FC<IProps> = ({ user }) => {
  const { t } = useTranslation();
  const fullName = getUserName(user);

  const route = routes.user.PRIVATE_CHAT.replace(
    ':chatId',
    user.chat?.id || ''
  );

  return (
    <>
      <ContactHeader
        name={fullName}
        subText={user.phone || user.email || ''}
        avatarUrl={user.avatarUrl}
      />
      {user.chat && (
        <Box p="6">
          <Button
            as={Link}
            variant="ghost"
            variantColor="teal"
            isFullWidth
            {...{ to: route }}
          >
            {t('chatOpenChat')}
          </Button>
        </Box>
      )}
    </>
  );
};

export default UserProfile;
