import React, { useEffect } from 'react';
import styled from 'styled-components';

import { IChat } from '../../../../../global';
import ChatHeader from '../../../../../shared/components/ChatHeader';
import { useSidebar } from '../../../../../shared/components/SidebarView';
import { ChatContext } from '../../../../../shared/context/ChatContext';
import useFriends from '../../../../../shared/hooks/useFriends';
import getChatName from '../../../../../shared/utils/chats/getChatName';
import getChatSubtitle from '../../../../../shared/utils/chats/getChatSubtitle';
import getLastOnlineString from '../../../../../shared/utils/user/getLastOnlineString';

const Wrapper = styled.button`
  align-items: center;
  background: none;
  border: 0;
  cursor: pointer;
  display: flex;
  font-family: inherit;
  height: 100%;
  outline: 0;
  padding: 0;
  text-align: inherit;
  width: 100%;
`;

const useSubtitle = (chat?: IChat) => {
  const { data } = useFriends();
  const [subTitle, setSubTitle] = React.useState('');

  useEffect(() => {
    if (chat) {
      if (chat.type === 'direct' && chat.withUser) {
        const friend = data?.friends.find(
          (friend) => friend.user.id === chat.withUser?.id
        );
        if (friend) {
          if (friend.online) {
            setSubTitle('Online');
          }
          if (!friend.lastOnline) {
            setSubTitle('');
          }
          if (friend.lastOnline) {
            const lastSeenString = getLastOnlineString(friend.lastOnline);
            setSubTitle(lastSeenString);
          }
        } else {
          setSubTitle('');
        }
      } else {
        setSubTitle(getChatSubtitle(chat));
      }
    }
  }, [chat, data]);

  return subTitle;
};

const HeaderContainer: React.FC = () => {
  const chatData = React.useContext(ChatContext);
  const subTitle = useSubtitle(chatData.chat as IChat);
  const sidebar = useSidebar();

  if (!chatData.chat) return null;

  const chatTitle = getChatName(chatData.chat as IChat);
  const avatar = chatData.chat.avatarUrl || chatData.chat.withUser?.avatarUrl;

  return (
    <Wrapper onClick={sidebar.openSidebar}>
      <ChatHeader
        id={chatData.chat.id}
        name={chatTitle}
        avatarUrl={avatar}
        subtitle={subTitle}
      />
    </Wrapper>
  );
};

export default HeaderContainer;
