import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/core';

import AddParticipantsContent from './AddParticipantsContent';

const AddParticipantsButton: React.FC = () => {
  const { t } = useTranslation();
  const [modalOpen, setModalState] = React.useState(false);

  const handleOpen = React.useCallback(() => {
    setModalState(true);
  }, [setModalState]);

  const handleClose = React.useCallback(() => {
    setModalState(false);
  }, [setModalState]);

  return (
    <Box mt="2">
      <Button size="sm" variantColor="teal" isFullWidth onClick={handleOpen}>
        {t('chatAddParticipants')}
      </Button>
      <Modal onClose={handleClose} isOpen={modalOpen}>
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>{t('chatAddParticipants')}</ModalHeader>
            <ModalCloseButton />
            <AddParticipantsContent onClose={handleClose} />
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </Box>
  );
};

export default React.memo(AddParticipantsButton);
