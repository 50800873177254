import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';

import { Box, Button, Input, Text } from '@chakra-ui/core';

import getErrorLabel from '../../../../../shared/utils/getErrorLabel';
import getErrorMessage from '../../../../../shared/utils/getErrorMessage';
import sendLoginCode from '../../../../../shared/utils/login/sendLoginCode';
import segment from '../../../../../shared/utils/segment';

const validationSchema = object().shape({
  token: string()
    .required()
    .matches(/^[0-9]{6}$/),
});

const ResponseError: React.FC = React.memo((props) => {
  const { children } = props;
  return (
    <Text color="red.500" my="1" textAlign="center" fontSize="sm">
      {children}
    </Text>
  );
});

const VerificationForm: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState<string[]>([]);
  const submitForm = React.useCallback(
    async (values: { token: string }) => {
      setLoading(true);
      setFormErrors([]);
      try {
        const token = await sendLoginCode(values.token);
        if (token) {
          localStorage.setItem('token', token);
          segment.auth.loginWithToken(values.token);
          window.location.reload();
        }
      } catch (e) {
        const error = getErrorMessage(e);
        if (error) {
          setFormErrors([error.translationString]);
        }
        setLoading(false);
      }
    },
    [setLoading]
  );

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    touched,
    values,
  } = useFormik<{ token: string }>({
    initialValues: { token: '' },
    onSubmit: submitForm,
    validateOnMount: true,
    validationSchema,
  });

  return (
    <Box as="form" onSubmit={handleSubmit} mx="auto" mt="10" maxWidth="480px">
      <Input
        name="token"
        value={values.token}
        onChange={handleChange}
        onBlur={handleBlur}
        maxLength={6}
        type="tel"
        placeholder="123456"
        isInvalid={!!touched.token && !!errors.token}
        isDisabled={!!loading}
        textAlign="center"
      />
      <Box mt="4">
        <Button
          variantColor="teal"
          type="submit"
          isDisabled={!isValid || loading}
          isLoading={loading}
          isFullWidth
        >
          {t('generalSignIn')}
        </Button>
      </Box>
      {formErrors.length > 0 && (
        <Box mt="4">
          {formErrors.map((error, i) => (
            <ResponseError key={i}>{getErrorLabel(error)}</ResponseError>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default VerificationForm;
