import { gql } from 'apollo-boost';

import attachmentFragment from '../../../graphql/fragments/attachment.fragment';
import messageFragment from '../../../graphql/fragments/message.fragment';

export default gql`
  ${messageFragment}
  ${attachmentFragment}

  mutation sendMessage(
    $uuid: String!
    $input: String!
    $chatId: String!
    $replyMessageId: String
    $attachments: [Upload]
  ) {
    sendMessage(
      clientGeneratedUUID: $uuid
      input: { text: $input, to: $chatId }
      attachments: $attachments
      replyMessageId: $replyMessageId
    ) {
      ...message
      replyMessage {
        ...message
        attachments {
          ...attachment
        }
      }
    }
  }
`;
