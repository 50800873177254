import React from 'react';

import { Box } from '@chakra-ui/core';

const FullscreenLayout: React.FC = ({ children }) => {
  return (
    <Box
      alignItems="center"
      bg="white"
      d="flex"
      height="100%"
      justifyContent="center"
      width="100%"
    >
      <Box minH="35vh" w="100%">
        <Box maxW="900px" px="6" mx="auto" w="100%">
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default FullscreenLayout;
