import React from 'react';
import styled from 'styled-components';

import { Box } from '@chakra-ui/core';

const Text = styled.div`
  color: ${(p) => p.theme.colors.gray['600']};
  font-size: 14px;
  line-height: 24px;
`;

const ChatMessage: React.FC<{
  time?: string;
}> = React.memo(({ children }) => (
  <Box d="flex">
    <Box flex="1 1 auto">
      <Text>{children}</Text>
    </Box>
  </Box>
));

export default ChatMessage;
