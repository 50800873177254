import { gql } from 'apollo-boost';

import { IUser } from '../../../global';
import client from '../../graphql';
import USER_FRAGMENT from '../../graphql/fragments/USER.fragment';

const query = gql`
  ${USER_FRAGMENT}

  query SEARCH_FOR_USER($address: String!) {
    user(phone: $address) {
      ...user
    }
  }
`;

export default async (address: string) => {
  const searchAddress = address.trim();
  try {
    const res = await client.query<{ user?: IUser }>({
      query,
      variables: {
        address: searchAddress,
      },
      fetchPolicy: 'network-only',
    });
    return res.data.user;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};
