import { Node, Path, Range, Text } from 'slate';

import { HASHTAG_REGEX } from '../regex';

const decorateSlateWithHashtags = (node: Node, ranges: Range[], path: Path) => {
  if (Text.isText(node)) {
    const { text } = node;

    const hashtags = text.match(HASHTAG_REGEX);

    if (hashtags?.length) {
      let currentIndex = 0;

      for (let i = 0; i < text.length; i++) {
        if (text[i] === '#') {
          if (hashtags[currentIndex]) {
            ranges.push({
              anchor: { path, offset: i + hashtags[currentIndex].length },
              focus: { path, offset: i },
              hashtag: true,
            });
            currentIndex++;
          }
        }
      }
    }
  }
};

export default decorateSlateWithHashtags;
