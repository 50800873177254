import { ApolloError } from 'apollo-boost';

export default (
  e: ApolloError
): { errorMessage: string; translationString: string } | undefined => {
  const res = e.graphQLErrors[0]?.message
    ? JSON.parse(e.graphQLErrors[0].message)
    : undefined;

  return res;
};
