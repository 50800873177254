import React from 'react';
import styled from 'styled-components';

import { Box } from '@chakra-ui/core';

import { IAttachment } from '../../../global';
import isImage from '../../utils/attachments/isImage';
import GeneralAttachment from './GeneralAttachment';
import ImageAttachment from './ImageAttachment';

const AttachmentWrapper = styled.div`
  margin-left: 4px;
  margin-right: 4px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

interface IProps {
  attachments: IAttachment[];
}

const ChatAttachment: React.FC<{ attachment: IAttachment }> = React.memo(
  ({ attachment }) => {
    const imageAttachment = isImage(attachment);

    if (imageAttachment) {
      return (
        <AttachmentWrapper>
          <ImageAttachment
            fileName={attachment.originalFileName}
            imageUrl={attachment.path}
          />
        </AttachmentWrapper>
      );
    }

    return (
      <GeneralAttachment
        fileName={attachment.originalFileName}
        path={attachment.path}
      />
    );
  }
);

const ChatAttachments: React.FC<IProps> = React.memo(({ attachments }) => {
  return (
    <Box d="flex" my="2" overflow="auto">
      {attachments.map((attachment) => (
        <ChatAttachment key={attachment.id} attachment={attachment} />
      ))}
    </Box>
  );
});

export default ChatAttachments;
