import './shared/i18n';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createGlobalStyle } from 'styled-components';

import { ApolloProvider } from '@apollo/react-hooks';
import {
  CSSReset,
  ThemeProvider as ChakraThemeProvider,
} from '@chakra-ui/core';

import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { AuthProvider } from './shared/context/AuthContext';
import client from './shared/graphql';
import appTheme, { styledAppTheme } from './shared/utils/theme';

const GlobalAppStyles = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: rgba(0, 0, 0, .05);
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, .1);
    border-radius: 40px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.2);
  }
`;

ReactDOM.render(
  <React.StrictMode>
    <GlobalAppStyles />
    <ThemeProvider theme={styledAppTheme}>
      <ChakraThemeProvider theme={appTheme}>
        <CSSReset />
        <ApolloProvider client={client}>
          <AuthProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </AuthProvider>
        </ApolloProvider>
      </ChakraThemeProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
