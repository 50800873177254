import { useFormik } from 'formik';
import i18n from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { object, string } from 'yup';

import { Box, Button, Checkbox, Input, Stack } from '@chakra-ui/core';

import getErrorMessage from '../../../../../shared/utils/getErrorMessage';
import requestLoginCode from '../../../../../shared/utils/login/requestLoginCode';
import { PHONE_REGEX } from '../../../../../shared/utils/regex';
import routes from '../../../../../shared/utils/routes';
import segment from '../../../../../shared/utils/segment';

const Wrapper = styled.span`
  a {
    color: ${(p) => p.theme.colors.teal['500']};

    &:hover {
      color: ${(p) => p.theme.colors.teal['700']};
      text-decoration: underline;
    }
  }
`;

const validationSchema = object().shape({
  phone: string()
    .required(i18n.t('errorsPhoneNumberRequired'))
    .matches(PHONE_REGEX, i18n.t('errorsValidPhoneNumber')),
});

interface IFormValues {
  phone: string;
}

const useLoginAcceptance = () => {
  const [data, setData] = React.useState(false);
  const [terms, setTerms] = React.useState(false);

  const handleDataUpdate = React.useCallback(() => {
    setData(!data);
  }, [setData, data]);

  const handleTermsUpdate = React.useCallback(() => {
    setTerms(!terms);
  }, [setTerms, terms]);

  const acceptAll = React.useCallback(() => {
    setTerms(true);
    setData(true);
  }, [setTerms, setData]);

  return {
    acceptAll,
    data,
    handleDataUpdate,
    handleTermsUpdate,
    terms,
  };
};

const LoginForm = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [loading, setLoading] = React.useState(false);
  const {
    acceptAll,
    data,
    handleDataUpdate,
    handleTermsUpdate,
    terms,
  } = useLoginAcceptance();

  const onSubmit = React.useCallback(
    (values: IFormValues) => {
      setError(undefined);
      setLoading(true);
      acceptAll();
      window.setTimeout(async () => {
        try {
          const res = await requestLoginCode(values.phone);
          window.localStorage.setItem('requestPhoneNumber', values.phone);
          segment.auth.loginWithPhone(values.phone);
          if (res) {
            push(routes.auth.TOKEN_VERIFICATION);
          }
        } catch (e) {
          setLoading(false);
          setError(getErrorMessage(e)?.errorMessage);
        }
      }, 1000);
    },
    [setLoading, push, acceptAll]
  );

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    isValid,
  } = useFormik<IFormValues>({
    initialValues: { phone: '' },
    onSubmit,
    validationSchema,
    validateOnMount: true,
  });

  return (
    <Box as="form" onSubmit={handleSubmit} my="10" mx="auto" maxW="480px">
      <Box alignItems="center" display={['block', 'flex']}>
        <Box flex="1 1 auto">
          <Input
            isDisabled={!!loading}
            isInvalid={!!touched.phone && !!errors.phone}
            isRequired
            name="phone"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="+4912345678"
            size="lg"
            type="phone"
            value={values.phone}
          />
        </Box>
        <Box flex="0 0 auto" ml={['0', '4']} mt={['4', '0']}>
          <Button
            variantColor="teal"
            size="lg"
            type="submit"
            isDisabled={!isValid || loading}
            isLoading={loading}
            isFullWidth
          >
            {t('generalSignIn')}
          </Button>
        </Box>
      </Box>
      {touched.phone && errors.phone && (
        <Box mt="2" color="red.500">
          {errors.phone}
        </Box>
      )}
      {error && (
        <Box mt="2" color="red.500">
          {error}
        </Box>
      )}
      <Box mt="6">
        <Stack spacing="4" justifyContent="center">
          <Checkbox
            isChecked={data}
            justifyContent="center"
            onChange={handleDataUpdate}
            isDisabled={!!loading}
            variantColor="teal"
            color="gray.500"
          >
            <Wrapper
              dangerouslySetInnerHTML={{
                __html: t('loginScreenDataProtection', {
                  url: 'https://zip.app/privacy',
                }),
              }}
            ></Wrapper>
          </Checkbox>
          <Checkbox
            isChecked={terms}
            justifyContent="center"
            onChange={handleTermsUpdate}
            isDisabled={!!loading}
            variantColor="teal"
            color="gray.500"
          >
            <Wrapper
              dangerouslySetInnerHTML={{
                __html: t('loginScreenTermsAndConditions', {
                  url: 'https://zip.app/terms',
                }),
              }}
            ></Wrapper>
          </Checkbox>
        </Stack>
      </Box>
    </Box>
  );
};

export default LoginForm;
