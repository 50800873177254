import React from 'react';
import styled from 'styled-components';

import { Box, Divider } from '@chakra-ui/core';

const ContentWrapper = styled.div<{ right?: boolean }>`
  flex: 0 0 auto;
  margin-left: ${(p) => (p.right ? '12px' : '0')};
  margin-right: ${(p) => (p.right ? '0' : '12px')};
`;

export interface IListItemProps {
  divider?: boolean;
  horizontalSpacing?: boolean;
  prefixContent?: JSX.Element;
  small?: boolean;
  suffixContent?: JSX.Element;
  verticalSpacing?: boolean;
}

const getVerticalSpacing = (small?: boolean) => (small ? '2' : '3');

const ListItem: React.FC<IListItemProps> = React.memo(
  ({
    children,
    divider,
    horizontalSpacing,
    prefixContent,
    suffixContent,
    verticalSpacing,
    small,
  }) => {
    return (
      <>
        <Box
          d="flex"
          alignItems="center"
          px={horizontalSpacing ? '16px' : undefined}
          py={verticalSpacing ? getVerticalSpacing(small) : undefined}
        >
          {!!prefixContent && <ContentWrapper>{prefixContent}</ContentWrapper>}
          <Box flex="1 1 auto" display="flex" alignItems="center">
            {children}
          </Box>
          {!!suffixContent && (
            <ContentWrapper right>{suffixContent}</ContentWrapper>
          )}
        </Box>
        {divider && <Divider m="0" color="gray.300" />}
      </>
    );
  }
);

export default ListItem;
