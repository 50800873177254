const openCreateChat = () => {
  window.analytics.track('ChatOpenCreate');
};

const createChat = () => {
  window.analytics.track('ChatCreate');
};

const createChatOpenParticipants = () => {
  window.analytics.track('ChatCreateOpenParticipants');
};

const createChatCloseParticipants = () => {
  window.analytics.track('ChatCreateCloseParticipants');
};

const openAttachments = (chatId: string) => {
  window.analytics.track('ChatOpenAttachments', { chatId });
};

const openDetails = (chatId: string) => {
  window.analytics.track('ChatOpenDetails', { chatId });
};

const closeDetails = (chatId: string) => {
  window.analytics.track('ChatCloseDetails', { chatId });
};

const openLocationModal = (chatId: string) => {
  window.analytics.track('ChatOpenLocationModal', { chatId });
};

const changeLocation = (chatId: string, location: string) => {
  window.analytics.track('ChatChangeLocation', { chatId, location });
};

const removeLocation = (chatId: string) => {
  window.analytics.track('ChatRemoveLocation', { chatId });
};

const leaveChat = (chatId: string) => {
  window.analytics.track('ChatLeave', { chatId });
};

const inviteUser = (chatId: string, userId: string) => {
  window.analytics.track('ChatInviteUser', { chatId, userId });
};

const removeUser = (chatId: string, userId: string) => {
  window.analytics.track('ChatRemoveUser', { chatId, userId });
};

const promoteUser = (chatId: string, userId: string) => {
  window.analytics.track('ChatPromoteUser', { chatId, userId });
};

const changeAvatar = (chatId: string) => {
  window.analytics.track('ChatChangeAvatar', { chatId });
};

const changeTitle = (chatId: string, newTitle: string) => {
  window.analytics.track('ChatChangeTitle', { chatId, newTitle });
};

export default {
  changeAvatar,
  changeLocation,
  changeTitle,
  closeDetails,
  createChat,
  createChatCloseParticipants,
  createChatOpenParticipants,
  inviteUser,
  leaveChat,
  openAttachments,
  openCreateChat,
  openDetails,
  openLocationModal,
  promoteUser,
  removeLocation,
  removeUser,
};
