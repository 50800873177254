import { gql } from 'apollo-boost';
import { useEffect } from 'react';

import { INotification } from '../../global';
import client from '../graphql';
import sendNotification from '../utils/user/sendNotification';

interface ISubscriptionResult {
  notifications: { notification: INotification };
}

const subscription = gql`
  subscription SUBSCRIBE_TO_NOTIFICATIONS {
    notifications {
      notification {
        createdAt
        type
        content {
          title
          body
          image
          data {
            key
            value
          }
        }
      }
    }
  }
`;

const useNotifications = (handler?: (...args: any) => any) => {
  useEffect(() => {
    const observableSubscription = client.subscribe<ISubscriptionResult>({
      query: subscription,
    });

    if (Notification.permission === 'default') {
      Notification.requestPermission();
    }

    const runningSubscription = observableSubscription.subscribe((res) => {
      if (res.data?.notifications.notification) {
        sendNotification(res.data?.notifications.notification, handler);
      }
    });

    return () => {
      runningSubscription.unsubscribe();
    };
  }, [handler]);
};

export default useNotifications;
