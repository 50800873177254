import React from 'react';
import styled from 'styled-components';

import { Checkbox } from '@chakra-ui/core';

import { IChat } from '../../../global';
import getChatName from '../../utils/chats/getChatName';
import ChatListItem from '../ChatListItem';

const Label = styled.label`
  align-items: center;
  border-bottom: 1px solid ${(p) => p.theme.colors.gray['300']};
  cursor: pointer;
  display: flex;
  padding: 12px 0;

  &:hover {
    background: rgba(0, 0, 0, 0.025);
  }

  &:active,
  &:focus {
    background: rgba(0, 0, 0, 0.035);
  }
`;

const CheckboxWrapper = styled.div<{ visible?: boolean }>`
  display: ${(p) => (p.visible ? 'block' : 'none')};
  flex: 0 0 auto;
  line-height: 1;
  margin-right: 12px;
`;

interface IProps {
  chat: IChat;
  checked?: boolean;
  onChange: (chat: IChat, checked: boolean) => void;
  multiple?: boolean;
}

const ChatPickerItem: React.FC<IProps> = ({
  chat,
  checked,
  onChange,
  multiple,
}) => {
  const handleChange = React.useCallback(() => {
    onChange(chat, !checked);
  }, [chat, checked, onChange]);

  const prefix = (
    <CheckboxWrapper visible={multiple}>
      <Checkbox onChange={handleChange} isChecked={checked} />
    </CheckboxWrapper>
  );

  const name = getChatName(chat);

  return (
    <Label>
      {prefix}
      <ChatListItem chatName={name} avatarUrl={chat.avatarUrl} />
    </Label>
  );
};

export default ChatPickerItem;
