import { useEffect } from 'react';

import { useQuery } from '@apollo/react-hooks';

import { IMessageUnion, SubscriptionAction } from '../../../../../../global';
import getMessagesQuery from '../graphql/getMessages.query';
import subscribeToMessagesSubscription from '../graphql/subscribeToMessages.subscription';

interface IQueryResult {
  messages: IMessageUnion[];
}

interface ISubscriptionResult {
  privateMessages: {
    action: SubscriptionAction;
    message: IMessageUnion;
  };
}

const resolver = (prev: IQueryResult, data: ISubscriptionResult) => {
  switch (data.privateMessages.action) {
    case 'created':
      return { messages: [data.privateMessages.message, ...prev.messages] };

    default:
      return prev;
  }
};

const useMessages = (chatId: string) => {
  const { data, loading, refetch, subscribeToMore } = useQuery<IQueryResult>(
    getMessagesQuery,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        chatId,
      },
    }
  );

  useEffect(() => {
    const endSubscription = subscribeToMore<ISubscriptionResult>({
      document: subscribeToMessagesSubscription,
      variables: { chatId },
      updateQuery: (prev, { subscriptionData }) => {
        return resolver(prev, subscriptionData.data);
      },
    });

    return () => {
      endSubscription();
    };
  }, [subscribeToMore, chatId]);

  return { data, loading, refetch };
};

export default useMessages;
