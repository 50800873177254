import React from 'react';

import { Box, Button, ButtonGroup, Icon } from '@chakra-ui/core';

import ChatCover from '../../../../../../shared/components/ChatCover';
import { useSidebar } from '../../../../../../shared/components/SidebarView';
import { ChatContext } from '../../../../../../shared/context/ChatContext';
import getParticipantsTitle from '../../../../../../shared/utils/chats/getParticipantsTitle';
import getUserName from '../../../../../../shared/utils/user/getUserName';
import GroupChatDropdownMenu from '../GroupChatDropdownMenu';

export const HeaderButton: React.FC<{ onClick: () => void }> = React.forwardRef(
  ({ children, onClick }, ref) => (
    <Button
      ref={ref}
      onClick={onClick}
      variant="ghost"
      color="white"
      _hover={{
        backgroundColor: 'rgba(255, 255, 255, .1)',
      }}
      _focus={{
        backgroundColor: 'rgba(255, 255, 255, .15)',
      }}
      _active={{
        backgroundColor: 'rgba(255, 255, 255, .15)',
      }}
    >
      {children}
    </Button>
  )
);

const ChatDetailsHeader: React.FC = () => {
  const { chat } = React.useContext(ChatContext);
  const sidebar = useSidebar();

  if (!chat) {
    return null;
  }

  const cover =
    chat.type === 'direct' ? (
      <ChatCover
        name={chat.withUser ? getUserName(chat.withUser) : ''}
        avatarUrl={chat.withUser?.avatarUrl}
        subTitle={chat.withUser?.phone || chat.withUser?.email}
      />
    ) : (
      <ChatCover
        name={chat.name || 'Untitled'}
        avatarUrl={chat.avatarUrl}
        subTitle={getParticipantsTitle(chat.participants?.length || 0)}
      />
    );

  return (
    <Box flex="0 0 auto" position="relative">
      <Box
        position="absolute"
        top="0"
        left="0"
        w="100%"
        height="56px"
        zIndex={4}
        d="flex"
        alignItems="center"
        justifyContent="flex-end"
        px="4"
      >
        <ButtonGroup>
          {chat.type !== 'direct' && <GroupChatDropdownMenu />}
          <HeaderButton onClick={sidebar.closeSidebar}>
            <Icon name="close" size="3" />
          </HeaderButton>
        </ButtonGroup>
      </Box>
      {cover}
    </Box>
  );
};

export default ChatDetailsHeader;
