/**
 * Returns the file size string, for example 1.05 KB or 4.09 MB
 * @param size The size in bytes
 */
const getFileSizeString = (size: number) => {
  let currentSize = size / 1024;

  if (currentSize < 1000) {
    return `${currentSize.toFixed(2)} KB`;
  }

  currentSize = currentSize / 1024;

  if (currentSize < 1000) {
    return `${currentSize.toFixed(2)} MB`;
  }

  currentSize = currentSize / 1024;
  return `${currentSize.toFixed(2)} GB`;
};

export default getFileSizeString;
