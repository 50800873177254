import auth from './auth';
import chat from './chat';
import contact from './contact';
import message from './message';
import settings from './settings';

export default {
  auth,
  chat,
  contact,
  message,
  settings,
};
