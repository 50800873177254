import { gql } from 'apollo-boost';

export interface IUserFragment {
  __typename: 'User';
  avatarProvider?: string;
  avatarUrl?: string;
  bio?: string;
  company?: string;
  email?: string;
  firstName: string;
  id: string;
  lang?: string;
  lastName?: string;
  phone: string;
  timezoneOffset?: string;
}

const USER_FRAGMENT = gql`
  fragment user on User {
    id
    firstName
    lastName
    phone
    email
    avatarUrl
    avatarProvider
    bio
    company
    lang
    timezoneOffset
  }
`;

export default USER_FRAGMENT;
