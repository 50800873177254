const authRoutes = {
  TOKEN_VERIFICATION: '/verification',
};

const userRoutes = {
  PROFILE_SETUP: '/setup',
  PROFILE_SETUP_AVATAR: '/setup/avatar',
  SETTINGS: '/settings',

  PRIVATE: '/me',
  PRIVATE_CHATS: '/me/chats',
  PRIVATE_CHAT: '/me/chats/:chatId',
  PRIVATE_CONTACTS: '/me/contacts',
  PRIVATE_CONTACT: '/me/contacts/:contactId',

  COMPANY: '/company/:companyId',
  COMPANY_CHAT: '/company/:companyId/:chatId',
  COMPANY_CHAT_PARTICIPANTS: '/company/:companyId/:chatId/details/participants',
};

export default {
  HOME: '/',
  auth: authRoutes,
  user: userRoutes,
};
