import { IChat } from '../../../global';

const getChatName = (chat: IChat) => {
  if (chat.name) {
    return chat.name;
  }

  if (chat.withUser?.firstName && chat.withUser?.lastName) {
    return `${chat.withUser?.firstName} ${chat.withUser?.lastName}`;
  }

  if (chat.withUser?.firstName) {
    return chat.withUser?.firstName;
  }

  return 'Untitled Chat';
};

export default getChatName;
