import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Box, IconButton, Tooltip } from '@chakra-ui/core';

interface IProps {
  name: string;
  label: string;
  onClick?: () => void;
}

const Name = styled.div`
  color: ${(p) => p.theme.colors.gray['900']};
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Label = styled.div`
  color: ${(p) => p.theme.colors.gray['500']};
  font-size: 16px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const UserMenuButton: React.FC<IProps> = React.memo((props) => {
  const { t } = useTranslation();
  const { label, name, onClick } = props;

  return (
    <Box d="flex" alignItems="center">
      <Box flex="1 1 auto">
        <Name>{name}</Name>
        <Label>{label}</Label>
      </Box>
      <Tooltip label={t('generalSettings')} aria-label={t('generalSettings')}>
        <Box flex="0 0 auto" ml="12px">
          <IconButton
            icon="settings"
            color="gray.500"
            variant="ghost"
            onClick={onClick}
            aria-label={t('generalSettings')}
          />
        </Box>
      </Tooltip>
    </Box>
  );
});

export default UserMenuButton;
