const openDropdown = (messageId: string) => {
  window.analytics.track('MessageOpenDropdown', { messageId });
};

const openReplyModal = (messageId: string) => {
  window.analytics.track('MessageOpenReplyModal', { messageId });
};

const reply = (messageId: string) => {
  window.analytics.track('MessageReply', { messageId });
};

const openForwardModal = (messageId: string) => {
  window.analytics.track('MessageOpenForwardModal', { messageId });
};

const forward = (messageId: string) => {
  window.analytics.track('MessageForward', { messageId });
};

const openEditMask = (messageId: string) => {
  window.analytics.track('MessageOpenEditMask', { messageId });
};

const edit = (messageId: string) => {
  window.analytics.track('MessageEdit', { messageId });
};

const openTranslateMenu = (messageId: string) => {
  window.analytics.track('MessageOpenTranslateMenu', { messageId });
};

const translate = (messageId: string, languageKey: string) => {
  window.analytics.track('MessageTranslate', { messageId, languageKey });
};

const clearTranslation = (messageId: string) => {
  window.analytics.track('MessageClearTranslation', { messageId });
};

const deleteMessage = (messageId: string) => {
  window.analytics.track('MessageDelete', { messageId });
};

const openDetails = (messageId: string) => {
  window.analytics.track('MessageOpenDetails', { messageId });
};

export default {
  clearTranslation,
  delete: deleteMessage,
  edit,
  forward,
  openDetails,
  openDropdown,
  openEditMask,
  openForwardModal,
  openReplyModal,
  openTranslateMenu,
  reply,
  translate,
};
