import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/core';

import TowioConnectionButton from '../../../shared/components/TowioConnectionButton';
import useCurrentUser from '../../../shared/hooks/useCurrentUser';
import getAccountConnections from '../../../shared/utils/getAccountConnections';

const EdooliConnectionModal: React.FC = () => {
  const user = useCurrentUser();
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = React.useState(false);

  const closeModal = React.useCallback(() => {
    localStorage.setItem('zip.edooliConnection', 'true');
    setModalOpen(false);
  }, [setModalOpen]);

  React.useEffect(() => {
    const val = localStorage.getItem('zip.edooliConnection');
    getAccountConnections().then((res) => {
      const edooliConnection = res.find(
        (connection) => connection.name === 'edooli'
      );
      if (!val && !edooliConnection?.active) {
        setModalOpen(true);
      }
    });
  }, [setModalOpen, user]);

  if (!modalOpen) {
    return null;
  }

  return (
    <Modal isOpen onClose={closeModal}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Towio Account</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text color="gray.500" fontSize="sm" lineHeight="1.4">
              {t(
                'Connect your Towio account to start collaborating with your teams on the Towio cloud platform'
              )}
            </Text>
          </ModalBody>
          <ModalFooter>
            <Box>
              <TowioConnectionButton />
            </Box>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default EdooliConnectionModal;
