import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import FullscreenLayout from '../../../../shared/components/Layouts/FullscreenLayout';
import routes from '../../../../shared/utils/routes';
import AvatarSetupView from './Views/AvatarSetup';
import ProfileSetupView from './Views/ProfileSetup';

const HomeRedirect = () => <Redirect to={routes.user.PROFILE_SETUP} />;

const ProfileSetupApplication: React.FC = () => {
  return (
    <FullscreenLayout>
      <Switch>
        <Route exact path={routes.HOME} component={HomeRedirect} />
        <Route
          exact
          path={routes.user.PROFILE_SETUP}
          component={ProfileSetupView}
        />
        <Route
          exact
          path={routes.user.PROFILE_SETUP_AVATAR}
          component={AvatarSetupView}
        />
        <Route path="*" component={HomeRedirect} />
      </Switch>
    </FullscreenLayout>
  );
};

export default ProfileSetupApplication;
