import { Resource } from 'i18next';

const de: Resource = {
  translation: {
    chatAddParticipants: 'Teilnehmer hinzufügen',
    chatAllAttachments: 'Alle Anhänge',
    chatArchive: 'Chat archivieren',
    chatChangeImage: 'Chatbild ändern',
    chatCreated: 'Chat erstellt',
    chatDeleteMessage: 'Nachricht löschen',
    chatEditMessage: 'Nachricht bearbeiten',
    chatEventsChangeAvatar: '{{name}} hat das Chatbild geändert',
    chatEventsChangeLocation: '{{name}} hat den Standort zu {{value}} geändert',
    chatEventsCreated: '{{name}} hat {{value}} erstellt',
    chatEventsPromoteUser: '{{name}} hat {{target}} zum Chat-Besitzer erklärt',
    chatEventsRemoveLocation: '{{name}} hat den Standort entfernt',
    chatEventsRenamed: '{{name}} hat den Chat umbenannt',
    chatEventsUserAdded: '{{nameOne}} hat {{nameTwo}} zu diesem Chat hinzugefügt',
    chatEventsUserLeft: '{{name}} hat den Chat verlassen',
    chatEventsUserRemoved: '{{nameOne}} hat {{nameTwo}} entfernt',
    chatForwardedFrom: 'Weitergeleitet von {{name}}',
    chatForwardMessage: 'Nachricht weiterleiten',
    chatLastSeen: 'Zuletzt {{date}} gesehen',
    chatLeaveChat: 'Chat verlassen',
    chatMessageDeleted: 'Nachricht gelöscht...',
    chatMessageDetails: 'Nachrichtendetails',
    chatMessageInformation: 'Nachrichtendetails',
    chatMessageOptions: 'Mehr',
    chatNewTitle: 'Chat-Titel',
    chatOpenChat: 'Chat öffnen',
    chatParticipantRemoved: 'Teilnehmer entfernt',
    chatParticipants: '{{count}} Teilnehmer',
    chatParticipantsAddedDescription: '{{count}} Teilnehmer wurden eingeladen',
    chatParticipantsAddedTitle: 'Teilnehmer eingeladen',
    chatPromoteToOwner: 'Zum Chat-Besitzer erklären',
    chatRemoveUser: 'Teilnehmer entfernen',
    chatRenameChat: 'Chat umbenennen',
    chatReplyMessage: 'Antworten',
    chatReplyTo: 'Antwort an',
    chatReplyToMessage: 'Antwort an {{name}}',
    chatUpdateLocation: 'Standort bearbeiten',
    connectionConnectTowioAccount: 'Konto zu Würth übertragen',
    connectionsVerifiedTowioUser: "Verifizierter Würth-Nutzer",
    dateFriday: 'Freitag',
    dateMonday: 'Montag',
    dateSaturday: 'Samstag',
    dateSunday: 'Sonntag',
    dateThursday: 'Donnerstag',
    dateToday: 'Heute',
    dateTuesday: 'Dienstag',
    dateWednesday: 'Mittwoch',
    dateYesterday: 'Gestern',
    errorsGeneral: 'Unbekannter Fehler',
    errorsPhoneNumberRequired: 'Bitte gib eine gültige Handynummer ein.',
    errorsValidPhoneNumber: 'Die Telefonnummer muss gültig sein',
    generalAddLocation: 'Standort hinzufügen',
    generalAttachments: 'Anhänge',
    generalCancel: 'Abbrechen',
    generalConfirm: 'Bestätigen',
    generalContacts: 'Kontakte',
    generalContinue: 'Weiter',
    generalCreateChat: 'Chat erstellen',
    generalDataSafety: 'Datenschutzbestimmungen',
    generalDate: 'Datum',
    generalDone: 'Fertig',
    generalEdited: 'Nachricht bearbeitet...',
    generalEmail: 'E-Mail-Adresse',
    generalFirstName: 'Vorname',
    generalImprint: 'Impressum',
    generalLastName: 'Nachname',
    generalLocation: 'Standort',
    generalMessagePlaceholder: 'Nachricht',
    generalNoAttachments: 'Keine Anhänge',
    generalOwner: 'Besitzer',
    generalParticipants: 'Teilnehmer',
    generalPhone: 'Telefon',
    generalPhoneNumber: 'Telefonnummer',
    generalPrivateChats: 'Privatnachrichten',
    generalReadAt: 'am {{date}}, {{time}}',
    generalReadBy: 'Gelesen von',
    generalRemove: 'Entfernen',
    generalRemoveFile: "Datei entfernen",
    generalSave: 'Speichern',
    generalSearch: 'Chats durchsuchen',
    generalSendAt: 'Gesendet am {{date}} um {{time}}',
    generalSettings: 'Einstellungen',
    generalSignIn: 'Anmelden',
    generalSignOut: 'Abmelden',
    generalTitle: 'Titel für neuen Gruppenchat',
    generalTerms: 'Nutzungsbedingungen',
    generalTranslate: 'Übersetzen',
    generalTranslated: 'übersetzt',
    generalUpdate: 'Ändern',
    langAlbanian: 'Albanisch',
    langClearTranslation: 'Übersetzung entfernen',
    langDutch: 'Niederländisch',
    langEnglish: 'Englisch',
    langFrench: 'Französisch',
    langGerman: 'Deutsch',
    langItalian: 'Italienisch',
    langPolish: 'Polnisch',
    langPortuguese: 'Portugiesisch',
    langRomanian: 'Rumänisch',
    langRussian: 'Russisch',
    langSpanish: 'Spanisch',
    langToAlbanian: 'In Albanisch übersetzen',
    langToDutch: 'In Niederländisch übersetzen',
    langToEnglish: 'In Englisch übersetzen',
    langToFrench: 'In Französisch übersetzen',
    langToGerman: 'In Deutsch übersetzen',
    langToItalian: 'In Italienisch übersetzen',
    langToPolish: 'In Polnisch übersetzen',
    langToPortuguese: 'In Portugiesisch übersetzen',
    langToRomanian: 'In Rumänisch übersetzen',
    langToRussian: 'In Russisch übersetzen',
    langToSpanish: 'In Spanisch übersetzen',
    loginScreenDataProtection: 'Ich akzeptiere die <a target="_blank" rel="nofollow noreferrer" href="{{url}}">Datenschutzerklärung</a>.',
    loginScreenSubtitle: 'Business-Kommunikation richtig gemacht',
    loginScreenTermsAndConditions: 'Ich akzeptiere die <a target="_blank" rel="nofollow noreferrer" href="{{url}}">Nutzungsbedingungen</a>',
    loginSignInWithTowio: "Mit Würth anmelden",
    registrationSubtitle: 'Lass deine Kollegen wissen, wer du bist',
    registrationTitle: 'Schön, dich hier zu haben',
    settingsConnections: 'Verbindungen',
    settingsModalTitle: 'Einstellungen',
    settingsNotification: 'Benachrichtigungen',
    settingsProfile: 'Profil',
    verificationScreenResendCode: 'Code erneut senden',
    verificationScreenSubtitle: 'Bitte gib den Bestätigungscode ein, den wir an deine Handynummer gesendet haben.',
    verificationScreenTitle: 'Wir haben dir einen Bestätigungscode zugeschickt',
  },
};

export default de;
