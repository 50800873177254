import { IMessageUnion } from '../../../global';
import isDateLabel from '../typeguards/isDateLabel';
import isEvent from '../typeguards/isEvent';
import isMessage from '../typeguards/isMessage';

const getMessageIdentifier = (message: IMessageUnion) => {
  if (isMessage(message)) {
    return message.id;
  }

  if (isEvent(message)) {
    return message.id;
  }

  if (isDateLabel(message)) {
    return message.value || undefined;
  }

  return undefined;
};

export default getMessageIdentifier;
