import { useCallback, useState } from 'react';

export default () => {
  const [isOpen, setOpen] = useState(false);

  const open = useCallback(() => {
    setOpen(true);
  }, []);

  const close = useCallback(() => {
    setOpen(false);
  }, []);

  return {
    close,
    isOpen,
    open,
  };
};
