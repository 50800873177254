import { gql } from 'apollo-boost';

import { IUser } from '../../../global';

export interface IContactFragment {
  user: IUser;
  accepted: boolean;
  blocked: boolean;
  isRequest: boolean;
  chat: {
    id: string;
  };
}

const getContactsQuery = gql`
  query GET_CONTACTS($type: FriendStatusType) {
    friends(type: $type) {
      user {
        id
        firstName
        lastName
        phone
        email
        avatarUrl
        avatarProvider
        bio
        company
        lang
        timezoneOffset
      }
      accepted
      blocked
      isRequest
      chat {
        id
      }
    }
  }
`;

export default getContactsQuery;
