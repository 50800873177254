import { IChatFragment } from '../../../components/MainApplication/Sidebar/PrivateChatList/chat.fragment';
import { IChat } from '../../../global';

export default (chatA: IChatFragment | IChat, chatB: IChatFragment | IChat) => {
  const createdAtA = chatA.lastMessage?.createdAt;
  const createdAtB = chatB.lastMessage?.createdAt;

  const dateA = createdAtA ? new Date(createdAtA) : undefined;
  const dateB = createdAtB ? new Date(createdAtB) : undefined;

  if (!dateA) {
    return 1;
  }

  if (!dateB) {
    return -1;
  }

  return dateB.getTime() - dateA.getTime();
};
