import { gql } from 'apollo-boost';

import client from '../../graphql';

const mutation = gql`
  mutation ADD_USER_TO_FRIEND($userId: String!) {
    addUserToFriends(userId: $userId) {
      success
      message
    }
  }
`;

export default async (userId: string) => {
  try {
    const res = await client.mutate<{ success: boolean; message: string }>({
      mutation,
      variables: {
        userId,
      },
    });
    return res.data;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};
