import {
  ApolloClient,
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-boost';
import { split } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { WebSocketLink } from 'apollo-link-ws';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from 'apollo-utilities';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import introspection from './introspection';

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');

  if (!headers) {
    headers = {};
  }

  if (token) {
    headers.Authorization = `bearer ${token}`;
  }

  return {
    headers: {
      ...headers,
    },
  };
});

const setAuthHeader = async (initialHeaders: Record<string, string>) => {
  const token = localStorage.getItem('token');

  if (!initialHeaders) {
    initialHeaders = {};
  }

  if (token) {
    initialHeaders.Authorization = `bearer ${token}`;
  }

  return {
    ...initialHeaders,
  };
};

const subscriptionClient = new SubscriptionClient(
  process.env.REACT_APP_GRAPHQL_WS || '',
  {
    lazy: true,
    reconnect: true,
    reconnectionAttempts: Infinity,
    timeout: 2000,
    connectionParams: async () => setAuthHeader({}),
  }
);

const httpLink = createUploadLink({
  credentials: 'include',
  uri: process.env.REACT_APP_GRAPHQL_URL || '',
});

const wsLink = new WebSocketLink(subscriptionClient);

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  authLink.concat(httpLink)
);

const cache = new InMemoryCache({
  // @ts-ignore
  fragmentMatcher: new IntrospectionFragmentMatcher({
    // @ts-ignore
    introspectionQueryResultData: introspection.data,
  }),
});

const client = new ApolloClient({
  cache,
  link,
});

export default client;
