import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Divider, Icon, Tooltip } from '@chakra-ui/core';

import useCurrentUser from '../../../shared/hooks/useCurrentUser';
import routes from '../../../shared/utils/routes';
import getUserName from '../../../shared/utils/user/getUserName';
import AppBarTeam from './AppBarTeam';
import { UserButton } from './UserButton';

const AppsBar: React.FC = () => {
  const currentUser = useCurrentUser();
  const { t } = useTranslation();

  if (!currentUser) return null;

  return (
    <>
      <UserButton
        avatarUrl={currentUser?.avatarUrl}
        name={getUserName(currentUser)}
      />
      <Divider m="0" color="gray.200" />
      <Tooltip
        label={t('generalPrivateChats')}
        aria-label={t('generalPrivateChats')}
      >
        <Box>
          <AppBarTeam to={routes.user.PRIVATE_CHATS}>
            <Icon name="chat" size="24px" />
          </AppBarTeam>
        </Box>
      </Tooltip>
      <Tooltip label={t('generalContacts')} aria-label={t('generalContacts')}>
        <Box>
          <AppBarTeam to={routes.user.PRIVATE_CONTACTS}>
            <Icon name="user" size="24px" />
          </AppBarTeam>
        </Box>
      </Tooltip>
    </>
  );
};

export default AppsBar;
