import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/core';

import { IAttachment } from '../../../../../../global';
import GridGallery, {
  GridGalleryItem,
  GridGalleryThumbnail,
} from '../../../../../../shared/components/GridGallery';
import { AttachmentsContext } from '.';

const AttachmentItem: React.FC<{ data: IAttachment }> = ({ data }) => {
  return (
    <a
      title={data.originalFileName}
      href={data.path}
      target="_blank"
      rel="noopener noreferrer"
    >
      <GridGalleryThumbnail attachment={data} />
    </a>
  );
};

const AttachmentsModal: React.FC = () => {
  const { attachments, setModalOpen } = React.useContext(AttachmentsContext);
  const { t } = useTranslation();

  const handleClose = React.useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  return (
    <Modal isOpen onClose={handleClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>{t('generalAttachments')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <GridGallery count={4}>
              {attachments.map((attachment) => (
                <GridGalleryItem key={attachment.id}>
                  <AttachmentItem data={attachment} />
                </GridGalleryItem>
              ))}
            </GridGallery>
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default AttachmentsModal;
