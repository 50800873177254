import React, { useContext } from 'react';

import { IChat } from '../../../global';
import useCurrentUser from '../../hooks/useCurrentUser';
import useModalState from '../../hooks/useModalState';
import userIsChatOwner from '../../utils/chats/userIsChatOwner';

const ChatDetailsContext = React.createContext<{
  isOwner: boolean;
  rename: {
    isOpen: boolean;
    open: () => void;
    close: () => void;
  };
}>({
  isOwner: false,
  rename: {
    close: () => null,
    isOpen: false,
    open: () => null,
  },
});

export default ChatDetailsContext;

export const ChatDetailsProvider: React.FC<{ chat: IChat }> = ({
  children,
  chat,
}) => {
  const currentUser = useCurrentUser();
  const changeNameModalState = useModalState();

  return (
    <ChatDetailsContext.Provider
      value={{
        isOwner: currentUser ? userIsChatOwner(chat, currentUser.id) : false,
        rename: changeNameModalState,
      }}
    >
      {children}
    </ChatDetailsContext.Provider>
  );
};

export const ChatDetailsConsumer = ChatDetailsContext.Consumer;
export const useChatDetails = () => useContext(ChatDetailsContext);
