import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Icon,
  InternalState,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/core';

import { ChatContext } from '../../../../../shared/context/ChatContext';
import { useChatDetails } from '../../../../../shared/context/ChatDetailsContext';
import changeChatAvatar from '../../../../../shared/utils/chats/changeChatAvatar';
import segment from '../../../../../shared/utils/segment';
import { HeaderButton } from './ChatDetailsHeader';

const Content: React.FC<{
  menu: InternalState;
  inputRef: React.MutableRefObject<HTMLInputElement | null>;
}> = ({ menu, inputRef }) => {
  const { t } = useTranslation();
  const { rename } = useChatDetails();

  const openRenameModal = React.useCallback(() => {
    if (menu?.onClose) {
      menu.onClose();
    }
    rename.open();
  }, [menu, rename]);

  const changeImage = React.useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, [inputRef]);

  return (
    <>
      <MenuItem onClick={openRenameModal}>{t('chatRenameChat')}</MenuItem>
      <MenuItem onClick={changeImage}>{t('chatChangeImage')}</MenuItem>
      <MenuItem isDisabled onClick={console.log}>
        {t('chatArchive')}
      </MenuItem>
    </>
  );
};

const GroupChatDropdownMenu: React.FC = () => {
  const { chat, refetchChat } = React.useContext(ChatContext);
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const handleAvatarUpload = React.useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.currentTarget?.files?.[0]) {
        if (chat) {
          await changeChatAvatar(chat.id, e.currentTarget.files[0]);
          segment.chat.changeAvatar(chat.id);
          if (refetchChat) {
            refetchChat({ chatId: chat.id });
          }
        }
      }
    },
    [chat, refetchChat]
  );

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        style={{ display: 'none' }}
        onChange={handleAvatarUpload}
        accept="image/png,image/jpg,image/jpeg"
        multiple={false}
        value=""
      />
      <Menu>
        {(menu) => (
          <>
            <MenuButton as={HeaderButton}>
              <Icon name="more" />
            </MenuButton>
            <MenuList>
              <Content menu={menu} inputRef={inputRef} />
            </MenuList>
          </>
        )}
      </Menu>
    </>
  );
};

export default GroupChatDropdownMenu;
