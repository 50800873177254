import React from 'react';
import { Redirect, useParams } from 'react-router-dom';

import { Box, Spinner } from '@chakra-ui/core';

import useContact from '../../../../shared/hooks/useContact';
import routes from '../../../../shared/utils/routes';
import UserProfile from './UserProfile';

const ContactInfoView: React.FC = () => {
  const { contactId } = useParams<{ contactId: string }>();
  const contact = useContact(contactId);

  if (contact.loading && !contact.data?.user) {
    return <Spinner size="sm" />;
  }

  if (!contact.data?.user) {
    return <Redirect to={routes.user.PRIVATE_CONTACTS} />;
  }

  return (
    <Box bg="gray.100" p="6" w="100%" h="100%">
      <Box bg="white" borderRadius="4px" overflow="hidden">
        <UserProfile user={contact.data.user} />
      </Box>
    </Box>
  );
};

export default ContactInfoView;
