import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/core';

import DetailsSection from '../../../../../shared/components/DetailsSection';
import { ChatContext } from '../../../../../shared/context/ChatContext';
import { useChatDetails } from '../../../../../shared/context/ChatDetailsContext';
import ChatDetailsHeader from './ChatDetailsHeader';
import ChatLocation from './ChatLocation';
import { MemorizedChatSidebarAttachments } from './ChatSidebarAttachments';
import GroupChatParticipants from './GroupChatParticipants';
import RenameChatForm from './RenameChatForm';

const MemorizedGroupChatParticipants = React.memo(GroupChatParticipants);

const GroupChatSidebar: React.FC = () => {
  const { t } = useTranslation();
  const { chat } = React.useContext(ChatContext);
  const { rename } = useChatDetails();

  if (!chat) return null;

  return (
    <>
      <ChatDetailsHeader />
      <Box overflow="auto" flex="1 1 auto">
        {chat.participants && (
          <Box mx="8" py="6">
            <DetailsSection title={t('generalParticipants')}>
              <MemorizedGroupChatParticipants data={chat.participants} />
            </DetailsSection>
          </Box>
        )}
        <Box mx="8" py="6">
          <DetailsSection title={t('generalLocation')}>
            <ChatLocation />
          </DetailsSection>
        </Box>
        <Box mx="8" py="6">
          <DetailsSection title={t('generalAttachments')}>
            <MemorizedChatSidebarAttachments />
          </DetailsSection>
        </Box>
      </Box>
      <Modal isOpen={rename.isOpen} onClose={rename.close}>
        <ModalOverlay>
          <ModalContent borderRadius="4px">
            <ModalHeader>{t('chatRenameChat')}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>{rename.isOpen && <RenameChatForm />}</ModalBody>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  );
};

export default GroupChatSidebar;
