import { gql } from 'apollo-boost';

import USER_FRAGMENT from '../../../../shared/graphql/fragments/USER.fragment';
import chatFragment from './chat.fragment';

export default gql`
  ${chatFragment}
  ${USER_FRAGMENT}

  subscription {
    privateChats {
      action
      chat {
        ...chat
        owner {
          ...user
        }
        participants {
          ...user
        }
        withUser {
          ...user
        }
        withUsers {
          ...user
        }
      }
    }
  }
`;
