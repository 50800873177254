import { gql } from 'apollo-boost';

export default gql`
  fragment message on Message {
    id
    edited
    text
    deleted
    fromUser {
      id
      firstName
      lastName
      avatarUrl
    }
    createdAt
    isAuthor
    isFirstInBatch
    isLastInBatch
    read
    sent
  }
`;
