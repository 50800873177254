import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Text } from '@chakra-ui/core';

import { IReadBy } from '../../../../../../../../global';
import ListItem from '../../../../../../../../shared/components/ListItem';
import UserListItem from '../../../../../../../../shared/components/UserListItem';
import { ChatContext } from '../../../../../../../../shared/context/ChatContext';
import formatDate from '../../../../../../../../shared/utils/formatDate';
import formatTime from '../../../../../../../../shared/utils/formatTime';
import { MessageContext } from '../..';

const sortReadBy = (a: IReadBy, b: IReadBy) => {
  if (!a.time || !b.time) {
    return 0;
  }

  if (a.time > b.time) {
    return -1;
  } else {
    return 1;
  }
};

const ReadBy: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const { message } = React.useContext(MessageContext);
  const { chat } = React.useContext(ChatContext);

  const renderItem = React.useCallback(
    (status: IReadBy) => {
      if (!message?.createdAt || !status.time) {
        return null;
      }

      const isRead = new Date(message.createdAt) <= new Date(status.time);

      return (
        <ListItem verticalSpacing divider small>
          {status.user && <UserListItem small user={status.user} />}
          {isRead && (
            <Box ml="auto" pl="4">
              <Text fontSize="xs" color="gray.500">
                {t('generalReadAt', {
                  date: formatDate(status.time || ''),
                  time: formatTime(status.time || ''),
                })}
              </Text>
            </Box>
          )}
        </ListItem>
      );
    },
    [t, message]
  );

  if (!chat) {
    return null;
  }

  if (!chat.readBy) {
    return null;
  }

  return <>{chat.readBy.sort(sortReadBy).map(renderItem)}</>;
});

export default ReadBy;
