import React from 'react';

import { PseudoBox } from '@chakra-ui/core';

interface IProps {
  disabled?: boolean;
  onClick?: (...args: any) => any;
}

const AppBarButton: React.FC<IProps> = ({ disabled, children, onClick }) => {
  return (
    <PseudoBox
      as="button"
      w="80px"
      h="80px"
      opacity={disabled ? 0.35 : 1}
      d="flex"
      alignItems="center"
      justifyContent="center"
      onClick={onClick}
      _hover={{
        backgroundColor: 'rgba(0, 0, 0, .025)',
      }}
      _focus={{
        border: 0,
        outline: 0,
      }}
    >
      {children}
    </PseudoBox>
  );
};

export default AppBarButton;
