import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useQuery } from '@apollo/react-hooks';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/core';

import { IChat } from '../../../../../../global';
import ChatPicker from '../../../../../../shared/components/ChatPicker';
import forwardMessage from '../../../../../../shared/utils/messages/forwardMessage';
import routes from '../../../../../../shared/utils/routes';
import segment from '../../../../../../shared/utils/segment';
import getPrivateChatsQuery from '../../../../Sidebar/PrivateChatList/getPrivateChats.query';
import { MessageContext } from '.';

const Content: React.FC = () => {
  const history = useHistory();

  const { message } = React.useContext(MessageContext);

  const handleForward = React.useCallback(
    async (chats: IChat[]) => {
      if (message) {
        await forwardMessage(chats[0].id, message.id);
        history.push(routes.user.PRIVATE_CHAT.replace(':chatId', chats[0].id));
      }
    },
    [history, message]
  );

  return <ChatPicker onChange={handleForward} value={[]} />;
};

const MessageForwardModal: React.FC = () => {
  const { t } = useTranslation();
  const { message } = React.useContext(MessageContext);
  const { loading, data } = useQuery<{ chats: IChat[] }>(getPrivateChatsQuery, {
    fetchPolicy: 'cache-and-network',
  });
  const { forwarding, setForwarding } = React.useContext(MessageContext);
  const messageId = message?.id;

  React.useEffect(() => {
    if (messageId) {
      segment.message.openForwardModal(messageId);
    }
  }, [messageId]);

  const handleClose = React.useCallback(() => {
    setForwarding(false);
  }, [setForwarding]);

  if (!forwarding) {
    return null;
  }

  return (
    <Modal isOpen={forwarding} onClose={handleClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>{t('chatForwardMessage')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {loading && <Spinner />}
            {!loading && data?.chats && forwarding && <Content />}
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default MessageForwardModal;
