import React from 'react';

import { ChatContext } from '../../../../../../shared/context/ChatContext';
import EmptyState from './EmptyState';
import LocationPreview from './LocationPreview';

const ChatLocation: React.FC = React.memo(() => {
  const { chat } = React.useContext(ChatContext);

  if (chat?.location) {
    return <LocationPreview chatId={chat.id} location={chat.location} />;
  }

  if (!chat) {
    return null;
  }

  return <EmptyState chatId={chat.id} />;
});

export default ChatLocation;
