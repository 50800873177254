const getMinuteDifference = (dateString: string) => {
  const date = new Date(dateString);
  const time = date.getTime();
  const currentTime = Date.now();

  const diff = currentTime - time;

  return Math.floor(diff / 1000 / 60);
};

export default getMinuteDifference;
