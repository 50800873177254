import React from 'react';
import styled from 'styled-components';

import { Icon } from '@chakra-ui/core';

import { IAttachment } from '../../../global';
import isImage from '../../utils/attachments/isImage';
import getResizedImageUrl from '../../utils/getResizedImageUrl';

const GridWrapper = styled.div<{ count: number }>`
  display: grid;
  grid-gap: 4px;
  grid-template-columns: ${(p) => `repeat(${p.count}, 1fr)`};
  overflow: hidden;
  width: 100%;
`;

export const GridGalleryItem = styled.div`
  height: 0;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
  width: 100%;
`;

const ThumbWrapper = styled.div`
  border-radius: 4px;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
`;

const ThumbImage = styled.img`
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

const PreviewContent = styled.div`
  align-items: center;
  background: ${(p) => p.theme.colors.teal['500']};
  color: ${(p) => p.theme.colors.white};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 8px;
  width: 100%;
`;

const PreviewFileName = styled.div`
  font-size: 10px;
  margin-top: 8px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

interface IProps {
  count?: number;
}

const GridGallery: React.FC<IProps> = React.memo(({ children, count = 5 }) => {
  return <GridWrapper count={count}>{children}</GridWrapper>;
});

export default GridGallery;

export const GridGalleryThumbnail: React.FC<{
  attachment: IAttachment;
}> = React.memo(({ attachment }) => {
  const attachmentIsImage = isImage(attachment);

  return (
    <ThumbWrapper>
      {attachmentIsImage && (
        <ThumbImage
          alt={attachment.originalFileName}
          src={getResizedImageUrl(attachment.path, 180)}
        />
      )}
      {!attachmentIsImage && <GridGalleryFilePreview attachment={attachment} />}
    </ThumbWrapper>
  );
});

export const GridGalleryFilePreview: React.FC<{
  attachment: IAttachment;
}> = React.memo(({ attachment }) => {
  return (
    <ThumbWrapper>
      <PreviewContent>
        <div>
          <Icon name="file" size="24px" />
        </div>
        <PreviewFileName>{attachment.originalFileName}</PreviewFileName>
      </PreviewContent>
    </ThumbWrapper>
  );
});
