import { gql } from 'apollo-boost';

import client from '../../graphql';

const deleteMutation = gql`
  mutation DELETE_MESSAGE($messageId: String!) {
    deleteMessage(id: $messageId)
  }
`;

export default async (id: string) => {
  return await client.mutate({
    mutation: deleteMutation,
    variables: {
      messageId: id,
    },
  });
};
