import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.button`
  align-items: center;
  appearance: none;
  background: transparent;
  border: 0;
  color: ${(p) => p.theme.colors.gray['500']};
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  display: flex;
  font-size: ${(p) => p.theme.fontSizes.sm};
  height: auto;
  line-height: 1;
  opacity: ${(p) => (p.disabled ? '.5' : '1')};
  outline: 0;
  overflow: hidden;
  padding: 8px 12px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  ${(p) =>
    !p.disabled &&
    css`
      &:hover {
        background: ${(p) => p.theme.colors.gray['50']};
      }

      &:focus,
      &:active,
      &:focus:active {
        background: ${(p) => p.theme.colors.gray['100']};
      }
    `}
`;

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 12px;
`;

interface IProps {
  icon?: JSX.Element | boolean;
  onClick: () => void;
  disabled?: boolean;
}

const MenuItem: React.FC<IProps> = React.memo(
  ({ icon, disabled, children, onClick }) => {
    return (
      <Wrapper onClick={onClick} disabled={disabled}>
        {!!icon && <IconWrapper>{icon}</IconWrapper>}
        <div>{children}</div>
      </Wrapper>
    );
  }
);

export default MenuItem;
