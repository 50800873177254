import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const ListLink = styled(NavLink)`
  color: inherit;
  display: block;
  text-decoration: none;

  &:not(:last-child) {
    border-bottom: 1px solid ${(p) => p.theme.colors.gray['200']};
  }

  &.active {
    background-color: ${(p) => p.theme.colors.gray['50']};
  }

  &:not(.active):hover {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;

export const ListLinkContent = styled.div`
  padding: ${(p) => p.theme.space[3]} ${(p) => p.theme.space[4]};
`;

export default ListLink;
