import React from 'react';
import styled from 'styled-components';

import { Avatar, Box } from '@chakra-ui/core';

import UserPopover from '../UserPopover';

const Name = styled.div`
  color: ${(p) => p.theme.colors.gray['900']};
  flex: 0 0 auto;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const MessageTime = styled.div`
  color: ${(p) => p.theme.colors.gray['500']};
  flex: 0 0 auto;
  font-size: 14px;
  line-height: 14px;
  margin-left: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

interface IProps {
  avatarUrl?: string;
  firstName: string;
  lastName?: string;
  messageTime?: string;
  userId: string;
}

const ChatMessageHeader: React.FC<IProps> = React.memo(
  ({ firstName, lastName, avatarUrl, messageTime, userId }) => {
    const name = [firstName, lastName].join(' ');

    return (
      <Box d="flex" alignItems="center">
        <Box flex="0 0 auto" width="32px" mr="4">
          <UserPopover userId={userId}>
            <Box>
              <Avatar size="sm" src={avatarUrl} name={name} />
            </Box>
          </UserPopover>
        </Box>
        <Box d="flex" flex="1 1 auto">
          <UserPopover userId={userId}>
            <Box>
              <Name>{name}</Name>
            </Box>
          </UserPopover>
          {messageTime && <MessageTime>{messageTime}</MessageTime>}
        </Box>
      </Box>
    );
  }
);

export default ChatMessageHeader;
