import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import i18nRes from './resources';

console.log(i18nRes);

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: i18nRes,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });
