import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@chakra-ui/core';

import formatDate from '../../../../../../../../shared/utils/formatDate';
import formatTime from '../../../../../../../../shared/utils/formatTime';

interface IProps {
  date: string;
}

const SendAt: React.FC<IProps> = ({ date }) => {
  const { t } = useTranslation();
  return (
    <Text fontSize="sm" color="gray.500">
      {t('generalSendAt', { date: formatDate(date), time: formatTime(date) })}
    </Text>
  );
};

export default SendAt;
