import React from 'react';
import styled from 'styled-components';

import { Box, Icon, Text } from '@chakra-ui/core';

const Wrapper = styled.a`
  align-items: center;
  background: ${(p) => p.theme.colors.teal['500']};
  border-radius: 4px;
  color: ${(p) => p.theme.colors.white};
  display: flex;
  min-width: 244px;
  padding: 12px 16px;
  text-decoration: none;

  &:hover {
    background: ${(p) => p.theme.colors.teal['600']};
  }
`;

interface IProps {
  fileName?: string;
  path?: string;
}

const GeneralAttachment: React.FC<IProps> = ({ fileName, path }) => {
  return (
    <Wrapper href={path} target="_blank" rel="nofollower noreferrer">
      <Box pr="3" flex="0 0 auto">
        <Icon name="file" size="20px" />
      </Box>
      <Box flex="1 1 auto">
        <Text fontSize="sm">{fileName}</Text>
      </Box>
      <Box pl="3" flex="0 0 auto">
        <Icon name="downloadCloud" size="20px" />
      </Box>
    </Wrapper>
  );
};

export default GeneralAttachment;
