import { gql } from 'apollo-boost';

import { useQuery } from '@apollo/react-hooks';

import { IUser } from '../../global';
import USER_FRAGMENT from '../graphql/fragments/USER.fragment';
import chatFragment from '../graphql/fragments/chat.fragment';

const query = gql`
  ${USER_FRAGMENT}
  ${chatFragment}

  query GET_CONTACT($id: String!) {
    user(id: $id) {
      ...user
      chat {
        ...chat
      }
      mutualChats {
        ...chat
      }
    }
  }
`;

export default (id: string) => {
  return useQuery<{ user: IUser }>(query, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
  });
};
