import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import AppLayout from '../../shared/components/Layouts/AppLayout';
import AuthContext from '../../shared/context/AuthContext';
import FriendsProvider from '../../shared/context/FriendsContext';
import useHeartBeat from '../../shared/hooks/useHeartBeat';
import useNotifications from '../../shared/hooks/useNotifications';
import routes from '../../shared/utils/routes';
import EdooliConnectionModal from '../Modals/EdooliConnectionModal';
import AppsBar from './AppsBar';
import Sidebar from './Sidebar';
import ContactInfoView from './Views/ContactInfo';
import PrivateChatView from './Views/PrivateChat';
import ProfileSetupApplication from './Views/ProfileSetupApplication';

const RedirectComponent = () => {
  return <Redirect to={routes.user.PRIVATE_CHATS} />;
};

const MainApplication = () => {
  useNotifications();

  const {
    state: { user },
  } = React.useContext(AuthContext);

  React.useEffect(() => {
    window.analytics.identify(user?.id || '', {
      ...user,
    });
  }, [user]);

  useHeartBeat();

  if (!user?.firstName || user.firstName === user.phone) {
    return <ProfileSetupApplication />;
  }

  return (
    <>
      <FriendsProvider>
        <AppLayout teams={<AppsBar />} sidebar={<Sidebar />}>
          <Route path={routes.user.PRIVATE_CHAT} component={PrivateChatView} />
          <Route
            path={routes.user.PRIVATE_CONTACT}
            component={ContactInfoView}
          />
          <Route path="/" exact component={RedirectComponent} />
        </AppLayout>
      </FriendsProvider>
      <EdooliConnectionModal />
    </>
  );
};

export default MainApplication;
