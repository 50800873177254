import i18n from 'i18next';

export default (participantsCount: number) => {
  if (participantsCount === 1) {
    return `${participantsCount} participant`;
  }

  if (participantsCount === 0) {
    return `No participants`;
  }

  return i18n.t('chatParticipants', { count: participantsCount });
};
