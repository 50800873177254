import { ApolloQueryResult } from 'apollo-boost';
import { createContext } from 'react';
import React from 'react';

import { useQuery } from '@apollo/react-hooks';

import { IUser } from '../../global';
import GET_CURRENT_USER_QUERY from '../graphql/queries/GET_CURRENT_USER.query';
import logoutUser from '../utils/user/logoutUser';

interface IAuthContextValue {
  state: {
    loading: boolean;
    loggedIn: boolean;
    user: IUser | undefined;
  };
  actions: {
    logout: () => void;
    refetchUser: (variables?: any) => Promise<ApolloQueryResult<any>>;
  };
}

const AuthContext = createContext<IAuthContextValue>({
  state: {
    loading: false,
    loggedIn: false,
    user: undefined,
  },
  actions: {
    logout: logoutUser,
    refetchUser: async () => {
      return {
        data: undefined,
        loading: false,
        networkStatus: 1,
        stale: false,
      };
    },
  },
});

export default AuthContext;

interface IAuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: IAuthProviderProps) => {
  const { loading, data, refetch } = useQuery(GET_CURRENT_USER_QUERY);
  const user = data?.user;

  const loggedIn = !!user;

  const value: IAuthContextValue = {
    state: { loading, loggedIn, user: data?.user },
    actions: { refetchUser: refetch, logout: logoutUser },
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
