import i18n from 'i18next';

import { formatChatDate } from '../chats/formatChatDate';

export default (date: string) => {
  const lastOnlineDate = formatChatDate(date);
  if (!lastOnlineDate) {
    return '';
  } else {
    return i18n.t('chatLastSeen', {
      date:
        lastOnlineDate?.type === 'time'
          ? lastOnlineDate?.time
          : i18n.t(lastOnlineDate?.string),
    });
  }
};
