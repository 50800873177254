import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/core';

import { ILocation } from '../../../../../../global';
import SearchField from '../../../../../../shared/components/SearchField';
import useDebouncedValue from '../../../../../../shared/hooks/useDebouncedValue';
import useSearch from '../../../../../../shared/hooks/useSearch';
import setLocation from '../../../../../../shared/utils/chats/setLocation';
import searchLocations, {
  ILocationResult,
} from '../../../../../../shared/utils/searchLocations';
import segment from '../../../../../../shared/utils/segment';

const Item = styled.button<{ active?: boolean }>`
  appearance: none;
  background: ${(p) => (p.active ? 'rgba(0, 0, 0, 0.035)' : 'inherit')};
  border-radius: 4px;
  border: 0;
  color: inherit;
  cursor: pointer;
  display: block;
  font-family: inherit;
  font-size: 16px;
  margin: 4px 0;
  opacity: ${(p) => (p.active ? '1' : '.65')};
  outline: 0;
  padding: 8px;
  text-align: inherit;
  width: 100%;

  &:hover {
    background: rgba(0, 0, 0, 0.035);
  }
`;

interface IProps {
  chatId: string;
  location?: ILocation;
  onClose: () => void;
  open?: boolean;
}

const UpdateLocationModal: React.FC<IProps> = React.memo(
  ({ chatId, location, open, onClose }) => {
    const [selection, setSelection] = React.useState<
      ILocationResult | undefined
    >(
      location
        ? { locationString: location.locationString, placeId: location.placeId }
        : undefined
    );
    const [loading, setLoading] = React.useState(false);
    const [results, setResults] = React.useState<ILocationResult[]>([]);
    const search = useSearch(location?.locationString);
    const { t } = useTranslation();
    const debouncedValue = useDebouncedValue(1000, search.q);

    React.useEffect(() => {
      if (location) {
        setSelection({
          locationString: location.locationString,
          placeId: location.placeId,
        });
      }
    }, [location]);

    React.useEffect(() => {
      setLoading(true);
      if (debouncedValue.value && debouncedValue.value.length > 0) {
        searchLocations(debouncedValue.value).then((res) => {
          setResults(res);
          setLoading(false);
        });
      } else {
        setResults([]);
        setLoading(false);
      }
    }, [debouncedValue.value, setLoading, setResults]);

    const renderItem = React.useCallback(
      (item: ILocationResult) => {
        const handleClick = () => {
          setSelection(item);
        };

        return (
          <Item
            active={item.placeId === selection?.placeId}
            onClick={handleClick}
          >
            {item.locationString}
          </Item>
        );
      },
      [selection, setSelection]
    );

    const handleSubmit = React.useCallback(() => {
      if (selection) {
        setLocation(chatId, selection.placeId);
        segment.chat.changeLocation(chatId, selection.locationString);
        onClose();
      }
    }, [selection, chatId, onClose]);

    return (
      <Modal isOpen={open} onClose={onClose}>
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>{t('chatUpdateLocation')}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <SearchField
                value={search.q}
                placeholder={t('generalSearch')}
                onChange={search.handleChange}
                onReset={search.handleClear}
              />
              {loading ||
                (debouncedValue.loading && (
                  <Box my="6">
                    <Spinner />
                  </Box>
                ))}
              {!loading && !debouncedValue.loading && (
                <Box my="24px">{results.map(renderItem)}</Box>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                size="sm"
                variantColor="teal"
                isDisabled={!selection}
                onClick={handleSubmit}
              >
                {t('generalSave')}
              </Button>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    );
  }
);

export default UpdateLocationModal;
