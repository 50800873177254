import React from 'react';
import { useTranslation } from 'react-i18next';

import { useLazyQuery } from '@apollo/react-hooks';
import { Box, Spinner } from '@chakra-ui/core';

import { IUser } from '../../../global';
import getContactsQuery, {
  IContactFragment,
} from '../../graphql/queries/GET_CONTACTS';
import searchUsers from '../../utils/user/searchUsers';
import SearchField from '../SearchField';
import ContactPickerItem from './ContactPickerItem';

interface IProps {
  multiple?: boolean;
  onChange: (newValue: IUser[]) => void;
  value: IUser[];
  filter?: (user: IUser) => boolean;
}

const ContactPicker: React.FC<IProps> = ({
  filter,
  onChange,
  multiple,
  value,
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = React.useState('');
  const [fetchContacts, { data, loading }] = useLazyQuery<{
    friends: IContactFragment[];
  }>(getContactsQuery, {
    variables: { type: 'accepted' },
    fetchPolicy: 'cache-and-network',
  });

  React.useEffect(() => {
    fetchContacts();
  }, [fetchContacts]);

  const updateSearch = React.useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setSearch(e.currentTarget.value);
    },
    [setSearch]
  );

  const resetSearch = React.useCallback(() => {
    setSearch('');
  }, [setSearch]);

  const handleUserUpdate = React.useCallback(
    (user: IUser, checked?: boolean) => {
      if (!multiple) {
        onChange([user]);
      } else {
        if (checked) {
          onChange([...value, user]);
        } else {
          onChange([...value].filter((u) => u.id !== user.id));
        }
      }
    },
    [value, multiple, onChange]
  );

  let filteredUsers = searchUsers(
    search,
    data?.friends.map((friend) => friend.user) || []
  );

  if (filter) {
    filteredUsers = filteredUsers.filter(filter);
  }

  return (
    <div>
      <div>
        <SearchField
          value={search}
          onChange={updateSearch}
          onReset={resetSearch}
          placeholder={t('generalSearch')}
        />
      </div>
      <Box py="4">
        <Box maxH="400px" overflow="auto">
          {loading && !data?.friends ? (
            <>
              <Spinner />
            </>
          ) : (
            <div>
              {filteredUsers?.map((user) => (
                <ContactPickerItem
                  onChange={handleUserUpdate}
                  user={user}
                  key={user.id}
                  checked={value.findIndex((u) => u.id === user.id) !== -1}
                />
              ))}
            </div>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default ContactPicker;
