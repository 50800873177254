import { Resource } from 'i18next';

const en: Resource = {
  translation: {
    chatAddParticipants: 'Add participants',
    chatAllAttachments: 'All attachments',
    chatArchive: 'Archive chat',
    chatChangeImage: 'Change image',
    chatCreated: 'Chat created',
    chatDeleteMessage: 'Delete message',
    chatEditMessage: 'Edit message',
    chatEventsChangeAvatar: '{{name}} changed the chat avatar',
    chatEventsChangeLocation: '{{name}} changed the location to {{value}}',
    chatEventsCreated: '{{name}} created {{value}}',
    chatEventsPromoteUser: '{{name}} promoted {{target}} to chat owner',
    chatEventsRemoveLocation: '{{name}} removed the location',
    chatEventsRenamed: '{{name}} has renamed the chat',
    chatEventsUserAdded: '{{nameOne}} added {{nameTwo}}',
    chatEventsUserLeft: '{{name}} has left the chat',
    chatEventsUserRemoved: '{{nameOne}} removed {{nameTwo}}',
    chatForwardedFrom: 'Forwarded from {{name}}',
    chatForwardMessage: 'Forward message',
    chatLastSeen: 'Last seen {{date}}',
    chatLeaveChat: 'Leave chat',
    chatMessageDeleted: 'Message deleted',
    chatMessageDetails: 'Message details',
    chatMessageInformation: 'Message details',
    chatMessageOptions: 'More options',
    chatNewTitle: 'New title',
    chatOpenChat: 'Open chat',
    chatParticipantRemoved: 'Participant removed',
    chatParticipants: '{{count}} participants',
    chatParticipantsAddedDescription: '{{count}} participants were invited',
    chatParticipantsAddedTitle: 'Participants added',
    chatPromoteToOwner: 'Promote to owner',
    chatRemoveUser: 'Remove user',
    chatRenameChat: 'Rename chat',
    chatReplyMessage: 'Reply',
    chatReplyTo: 'Reply to',
    chatReplyToMessage: 'Reply to {{name}}',
    chatUpdateLocation: 'Update location',
    connectionConnectTowioAccount: 'Migrate your Account to Würth',
    connectionsVerifiedTowioUser: "Verified Würth user",
    dateFriday: 'Friday',
    dateMonday: 'Monday',
    dateSaturday: 'Saturday',
    dateSunday: 'Sunday',
    dateThursday: 'Thursday',
    dateToday: 'Today',
    dateTuesday: 'Tuesday',
    dateWednesday: 'Wednesday',
    dateYesterday: 'Yesterday',
    ERROR_FILE_TO_LARGE: 'Files are too large',
    errorsGeneralError: 'Unknown error',
    errorsPhoneNumberRequired: 'Please enter your phone number',
    errorsValidPhoneNumber: 'Must be a valid phone number',
    generalAddLocation: 'Add location',
    generalAttachments: 'Attachments',
    generalCancel: 'Cancel',
    generalConfirm: 'Confirm',
    generalContacts: 'Contacts',
    generalContinue: 'Continue',
    generalCreateChat: 'Create chat',
    generalDataSafety: 'Data Privacy',
    generalDate: 'Date',
    generalDone: 'Done',
    generalEdited: 'edited',
    generalEmail: 'Email',
    generalFirstName: 'First name',
    generalImprint: 'Imprint',
    generalLastName: 'Last name',
    generalLocation: 'Location',
    generalMessagePlaceholder: 'Message',
    generalOwner: 'Owner',
    generalNoAttachments: 'No attachments',
    generalParticipants: 'Participants',
    generalPhone: 'Phone',
    generalPhoneNumber: 'Phone number',
    generalPrivateChats: 'Private chats',
    generalReadAt: 'at {{date}}, {{time}}',
    generalReadBy: 'Read by',
    generalRemove: 'Remove',
    generalRemoveFile: "Remove file",
    generalSave: 'Save',
    generalSearch: 'Search',
    generalSendAt: 'Send at {{date}} at {{time}}',
    generalSettings: 'Settings',
    generalSignIn: 'Sign in',
    generalSignOut: 'Sign out',
    generalTerms: 'Terms and conditions',
    generalTitle: 'Title',
    generalTranslate: 'Translate',
    generalTranslated: 'translated',
    generalUpdate: 'Update',
    langAlbanian: 'Albanian',
    langClearTranslation: 'Clear translation',
    langDutch: 'Dutch',
    langEnglish: 'English',
    langFrench: 'French',
    langGerman: 'German',
    langItalian: 'Italian',
    langPolish: 'Polish',
    langPortuguese: 'Portuguese',
    langRomanian: 'Romanian',
    langRussian: 'Russian',
    langSpanish: 'Spanish',
    langToAlbanian: 'Translate to albanian',
    langToDutch: 'Translate to dutch',
    langToEnglish: 'Translate to english',
    langToFrench: 'Translate to french',
    langToGerman: 'Translate to german',
    langToItalian: 'Translate to italian',
    langToPolish: 'Translate to polish',
    langToPortuguese: 'Translate to portuguese',
    langToRomanian: 'Translate to romanian',
    langToRussian: 'Translate to russian',
    langToSpanish: 'Translate to spanish',
    loginScreenDataProtection: 'I accept the <a target="_blank" rel="nofollow noreferrer" href="{{url}}">data protection</a>',
    loginScreenSubtitle: 'Business communication done right',
    loginScreenTermsAndConditions: 'I accept the <a target="_blank" rel="nofollow noreferrer" href="{{url}}">terms and conditions</a>',
    loginSignInWithTowio: "Sign in with Würth",
    registrationSubtitle: 'Let other people know who you are',
    registrationTitle: 'Nice to have you here',
    settingsConnections: 'Connections',
    settingsModalTitle: 'Settings',
    settingsNotification: 'Notifications',
    settingsProfile: 'Profile',
    verificationScreenResendCode: 'Resend code',
    verificationScreenSubtitle: 'Check your messages for our verification token to authenticate yourself',
    verificationScreenTitle: 'We sent you a verification token',
  },
};

export default en;
