import React from 'react';
import { RenderLeafProps } from 'slate-react';
import styled from 'styled-components';

const HashtagLeaf = styled.span`
  background: ${(p) => p.theme.colors.teal['50']};
  border-radius: 4px;
  color: ${(p) => p.theme.colors.teal['500']};
  font-size: 14px;
  font-weight: 600;
  padding: 8px 4px;
`;

const Leaf: React.FC<RenderLeafProps> = ({ attributes, children, leaf }) => {
  if (leaf.hashtag) {
    return <HashtagLeaf {...attributes}>{children}</HashtagLeaf>;
  }

  return (
    <span {...attributes} style={{ color: leaf.highlight ? 'red' : 'inherit' }}>
      {children}
    </span>
  );
};

export default Leaf;
