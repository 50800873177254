import { gql } from 'apollo-boost';

import USER_FRAGMENT from '../../../../../shared/graphql/fragments/USER.fragment';
import locationFragment from '../../../../../shared/graphql/fragments/location.fragment';

export interface IChatFragment {
  id: string;
  name?: string;
  type: 'direct' | 'group';
  avatarUrl?: string;
  muted?: boolean;
  withUser?: {
    id: string;
    firstName: string;
    lastName?: string;
    avatarUrl?: string;
  };
  participants?: Array<{
    id: string;
  }>;
}

export default gql`
  ${locationFragment}
  ${USER_FRAGMENT}

  fragment chat on Chat {
    id
    name
    type
    avatarUrl
    muted
    location {
      ...location
    }
    withUser {
      id
      firstName
      lastName
      avatarUrl
    }
    participants {
      id
    }
    readBy {
      time
      user {
        ...user
      }
    }
  }
`;
