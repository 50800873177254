import { gql } from 'apollo-boost';

import chatFragment from './chat.fragment';

export default gql`
  ${chatFragment}

  query {
    chats(nested: false, includeSubChats: true) {
      ...chat
    }
  }
`;
