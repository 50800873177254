import React from 'react';

import ChatSlateInputField from '../../../../../../shared/components/ChatSlateInputField';
import editMessage from '../../../../../../shared/utils/messages/editMessage';
import segment from '../../../../../../shared/utils/segment';
import { MessageContext } from '.';

interface IProps {
  text: string;
}

const MessageEditMask: React.FC<IProps> = ({ text }) => {
  const { message, setEditing } = React.useContext(MessageContext);
  const [value, setValue] = React.useState('');

  React.useEffect(() => {
    if (message) {
      segment.message.openEditMask(message.id);
    }
  }, [message]);

  React.useEffect(() => {
    setValue(text);
  }, [text]);

  React.useEffect(() => {
    const keyHandler = (e: KeyboardEvent) => {
      if (e.keyCode === 27) {
        setEditing(false);
      }
    };

    window.addEventListener('keydown', keyHandler);
    return () => {
      window.removeEventListener('keydown', keyHandler);
    };
  }, [setEditing]);

  const handleChange = React.useCallback(
    (newValue: string) => {
      setValue(newValue);
    },
    [setValue]
  );

  const handleSubmit = React.useCallback(
    (value: string) => {
      if (value.length > 0 && message) {
        editMessage(message.id, value);
        segment.message.edit(message.id);
        setEditing(false);
      }
    },
    [message, setEditing]
  );

  return (
    <ChatSlateInputField
      value={value}
      onChange={handleChange}
      onSubmit={handleSubmit}
      placeholder={text}
    />
  );
};

export default MessageEditMask;
