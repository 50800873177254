import { gql } from 'apollo-boost';
import { v1 as uuid } from 'uuid';

import client from '../../graphql';
import messageFragment from '../../graphql/fragments/message.fragment';

const forwardMutation = gql`
  ${messageFragment}

  mutation FORWARD_MESSAGE(
    $uuid: String!
    $chatId: String!
    $messageId: String!
  ) {
    sendMessage(
      clientGeneratedUUID: $uuid
      input: { text: "", to: $chatId }
      forwardMessageIds: [$messageId]
    ) {
      ...message
    }
  }
`;

export default async (chatId: string, messageId: string) => {
  const generatedId = uuid();
  return await client.mutate({
    mutation: forwardMutation,
    variables: {
      uuid: generatedId,
      chatId,
      messageId,
    },
  });
};
