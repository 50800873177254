import { gql } from 'apollo-boost';

export default gql`
  fragment event on Event {
    id
    values {
      executor {
        firstName
        lastName
        avatarUrl
      }
      target {
        firstName
        lastName
        avatarUrl
      }
      value
      before
      after
    }
    createdAt
    event
    chat {
      id
    }
  }
`;
