import { gql } from 'apollo-boost';
import { useEffect } from 'react';

import client from '../graphql';

const heartbeatMutation = gql`
  mutation HEARTBEAT {
    heartBeat
  }
`;

const useHeartBeat = () => {
  useEffect(() => {
    let focused = true;

    const sendHeartbeat = () => {
      client.mutate({ mutation: heartbeatMutation });
    };

    const focusHandler = () => {
      focused = true;
      sendHeartbeat();
    };

    const blurHandler = () => {
      focused = false;
    };

    const heartbeatHandler = () => {
      if (focused) {
        sendHeartbeat();
      }
    };

    const visibilityHandler = () => {
      if (document.hidden) {
        focused = false;
      } else {
        focused = true;
        sendHeartbeat();
      }
    };

    document.addEventListener('visibilitychange', visibilityHandler);
    window.addEventListener('focus', focusHandler);
    window.addEventListener('blur', blurHandler);
    heartbeatHandler();
    const interval = window.setInterval(heartbeatHandler, 10000);

    return () => {
      window.clearInterval(interval);
      document.removeEventListener('visibilitychange', visibilityHandler);
      window.removeEventListener('focus', focusHandler);
      window.removeEventListener('blur', blurHandler);
    };
  }, []);
};

export default useHeartBeat;
